import React from 'react';
import { useTheme } from 'styled-components';

import { SVGIconProps } from 'Components/Icon/IconMap';

import { SharedTheme } from '../../Theme';

export function Edit(props: SVGIconProps): React.ReactElement {
  const theme = useTheme() as SharedTheme;
  return (
    <svg viewBox='0 0 12 13' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M10.6444 0.162883C10.3766 0.0534297 10.0898 -0.00191264 9.8006 5.0453e-05C9.51135 0.00201351 9.22536 0.0612432 8.95912 0.174321C8.69357 0.287111 8.45292 0.451247 8.25099 0.657297L1.02564 7.88065C0.995028 7.90992 0.968975 7.94483 0.949215 7.98467C0.942786 7.9976 0.937099 8.01092 0.932189 8.02455C0.927698 8.03699 0.923907 8.04953 0.920798 8.06212L0.0122743 11.5265C-0.0215266 11.6554 0.0156057 11.7925 0.109816 11.8868C0.204027 11.981 0.341165 12.0181 0.470061 11.9844L3.94107 11.0749C4.00541 11.058 4.06411 11.0243 4.11115 10.9773L11.3422 3.74828C11.5484 3.54646 11.7126 3.30586 11.8255 3.04032C11.9387 2.77409 11.998 2.48806 11.9999 2.19878C12.0019 1.9095 11.9465 1.62269 11.837 1.35495C11.7274 1.08721 11.5659 0.843835 11.3617 0.638902L11.096 0.903582L11.3605 0.637761C11.1555 0.433735 10.9121 0.272337 10.6444 0.162883ZM1.53751 8.66707L0.900462 11.0963L3.33263 10.459L2.79457 9.20351L1.53751 8.66707ZM10.3606 0.857116C10.5364 0.928995 10.6963 1.03494 10.831 1.16885C10.9649 1.30344 11.0709 1.46322 11.1428 1.63898C11.2148 1.81498 11.2513 2.00352 11.25 2.19369C11.2487 2.38386 11.2097 2.57188 11.1353 2.7469C11.0711 2.89791 10.9816 3.03665 10.8709 3.15723L8.84178 1.1289C8.96245 1.01823 9.10126 0.928799 9.25232 0.864638C9.4274 0.790277 9.61547 0.751326 9.80569 0.750035C9.9959 0.748744 10.1845 0.785138 10.3606 0.857116ZM8.31051 1.6583L7.79652 2.17215L9.82734 4.20218L10.3413 3.68833L8.31051 1.6583Z'
        fill={props.color?.primary || theme.colors.icons.primary}
      />
    </svg>
  );
}
