import React from 'react';
import { useTheme } from 'styled-components';

import { SVGIconProps } from 'Components/Icon/IconMap';

import { SharedTheme } from '../../Theme';

export function CalculatorCoverageIcon(props: SVGIconProps): React.ReactElement {
  const theme = useTheme() as SharedTheme;
  const primaryColor = props.color?.primary || theme.colors.icons.primary;
  const secondaryColor = props.color?.secondary || theme.colors.icons.secondary;

  return (
    <svg viewBox='0 0 48 48' fill='none' xmlns='http://www.w3.org/2000/svg' aria-hidden='true'>
      <circle cx='24' cy='24' r='19.5' fill='white' stroke='#F6F6F6' />
      <path
        d='M27.5548 7.20154H9.00955C7.87153 7.20154 6.94897 8.12409 6.94897 9.26212V36.0496C6.94897 37.1877 7.87153 38.1102 9.00955 38.1102H27.5548C28.6928 38.1102 29.6153 37.1877 29.6153 36.0496V9.26212C29.6153 8.12409 28.6928 7.20154 27.5548 7.20154Z'
        fill='white'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M9.00953 8.00044C8.31274 8.00044 7.74788 8.5653 7.74788 9.26209V36.0496C7.74788 36.7464 8.31274 37.3113 9.00953 37.3113H27.5547C28.2515 37.3113 28.8164 36.7464 28.8164 36.0496V9.26209C28.8164 8.5653 28.2515 8.00044 27.5547 8.00044H9.00953ZM6.15002 9.26209C6.15002 7.68283 7.43027 6.40259 9.00953 6.40259H27.5547C29.134 6.40259 30.4142 7.68283 30.4142 9.26209V36.0496C30.4142 37.6289 29.134 38.9091 27.5547 38.9091H9.00953C7.43027 38.9091 6.15002 37.6289 6.15002 36.0496V9.26209Z'
        fill={primaryColor}
      />
      <path
        d='M24.464 11.3227H12.1005C11.5315 11.3227 11.0702 11.7839 11.0702 12.353V16.4741C11.0702 17.0431 11.5315 17.5044 12.1005 17.5044H24.464C25.033 17.5044 25.4942 17.0431 25.4942 16.4741V12.353C25.4942 11.7839 25.033 11.3227 24.464 11.3227Z'
        fill={secondaryColor}
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M12.1003 12.0746C11.9466 12.0746 11.822 12.1992 11.822 12.353V16.4741C11.822 16.6279 11.9466 16.7525 12.1003 16.7525H24.4638C24.6175 16.7525 24.7422 16.6279 24.7422 16.4741V12.353C24.7422 12.1992 24.6175 12.0746 24.4638 12.0746H12.1003ZM10.3181 12.353C10.3181 11.3687 11.116 10.5707 12.1003 10.5707H24.4638C25.4481 10.5707 26.246 11.3687 26.246 12.353V16.4741C26.246 17.4584 25.4481 18.2563 24.4638 18.2563H12.1003C11.116 18.2563 10.3181 17.4584 10.3181 16.4741V12.353Z'
        fill={primaryColor}
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M18.2279 30.6993C17.8539 30.6993 17.4953 30.8479 17.2309 31.1123C16.9665 31.3767 16.818 31.7353 16.818 32.1092C16.818 32.3881 16.9007 32.6606 17.0556 32.8925C17.2105 33.1244 17.4307 33.3051 17.6883 33.4118C17.946 33.5185 18.2294 33.5464 18.5029 33.492C18.7764 33.4376 19.0276 33.3033 19.2248 33.1061C19.422 32.909 19.5562 32.6577 19.6106 32.3843C19.665 32.1108 19.6371 31.8273 19.5304 31.5697C19.4237 31.3121 19.243 31.0919 19.0111 30.9369C18.7793 30.782 18.5067 30.6993 18.2279 30.6993Z'
        fill={primaryColor}
      />
      <path
        d='M12.5883 30.6993C12.8672 30.6993 13.1398 30.782 13.3716 30.9369C13.6035 31.0919 13.7842 31.3121 13.8909 31.5697C13.9976 31.8273 14.0255 32.1108 13.9711 32.3843C13.9167 32.6577 13.7824 32.909 13.5853 33.1061C13.3881 33.3033 13.1369 33.4376 12.8634 33.492C12.5899 33.5464 12.3064 33.5185 12.0488 33.4118C11.7912 33.3051 11.571 33.1243 11.4161 32.8925C11.2612 32.6606 11.1785 32.3881 11.1785 32.1092C11.1785 31.7353 11.327 31.3767 11.5914 31.1123C11.8558 30.8479 12.2144 30.6993 12.5883 30.6993Z'
        fill={primaryColor}
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M18.2279 25.9998C17.8539 25.9998 17.4953 26.1483 17.2309 26.4127C16.9665 26.6771 16.818 27.0357 16.818 27.4097C16.818 27.6885 16.9007 27.9611 17.0556 28.1929C17.2105 28.4248 17.4307 28.6055 17.6883 28.7122C17.946 28.8189 18.2294 28.8468 18.5029 28.7924C18.7764 28.738 19.0276 28.6038 19.2248 28.4066C19.422 28.2094 19.5562 27.9582 19.6106 27.6847C19.665 27.4112 19.6371 27.1277 19.5304 26.8701C19.4237 26.6125 19.243 26.3923 19.0111 26.2374C18.7793 26.0825 18.5067 25.9998 18.2279 25.9998ZM18.2279 27.4097L18.2279 27.4097L18.2279 27.4097H18.2279H18.2279Z'
        fill={primaryColor}
      />
      <path
        d='M12.5883 25.9998C12.8672 25.9998 13.1398 26.0825 13.3716 26.2374C13.6035 26.3923 13.7842 26.6125 13.8909 26.8701C13.9976 27.1277 14.0255 27.4112 13.9711 27.6847C13.9167 27.9582 13.7824 28.2094 13.5853 28.4066C13.3881 28.6038 13.1369 28.738 12.8634 28.7924C12.5899 28.8468 12.3064 28.8189 12.0488 28.7122C11.7912 28.6055 11.571 28.4248 11.4161 28.1929C11.2612 27.9611 11.1785 27.6885 11.1785 27.4097C11.1785 27.0357 11.327 26.6771 11.5914 26.4127C11.8558 26.1483 12.2144 25.9998 12.5883 25.9998Z'
        fill={primaryColor}
      />
      <path
        d='M23.8674 21.3002C24.1462 21.3002 24.4188 21.3829 24.6507 21.5378C24.8825 21.6928 25.0632 21.9129 25.1699 22.1706C25.2766 22.4282 25.3046 22.7117 25.2502 22.9852C25.1958 23.2586 25.0615 23.5099 24.8643 23.707C24.6671 23.9042 24.4159 24.0385 24.1424 24.0929C23.869 24.1473 23.5855 24.1194 23.3279 24.0127C23.0702 23.9059 22.85 23.7252 22.6951 23.4934C22.5402 23.2615 22.4575 22.9889 22.4575 22.7101C22.4575 22.3362 22.6061 21.9776 22.8705 21.7132C23.1349 21.4488 23.4935 21.3002 23.8674 21.3002Z'
        fill={primaryColor}
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M18.2279 21.3002C17.8539 21.3002 17.4953 21.4488 17.2309 21.7132C16.9665 21.9776 16.818 22.3362 16.818 22.7101C16.818 22.9889 16.9007 23.2615 17.0556 23.4934C17.2105 23.7252 17.4307 23.9059 17.6883 24.0127C17.946 24.1194 18.2294 24.1473 18.5029 24.0929C18.7764 24.0385 19.0276 23.9042 19.2248 23.707C19.422 23.5099 19.5562 23.2586 19.6106 22.9852C19.665 22.7117 19.6371 22.4282 19.5304 22.1706C19.4237 21.9129 19.243 21.6928 19.0111 21.5378C18.7793 21.3829 18.5067 21.3002 18.2279 21.3002Z'
        fill={primaryColor}
      />
      <path
        d='M12.5883 21.3002C12.8672 21.3002 13.1398 21.3829 13.3716 21.5378C13.6035 21.6928 13.7842 21.9129 13.8909 22.1706C13.9976 22.4282 14.0255 22.7117 13.9711 22.9852C13.9167 23.2586 13.7824 23.5099 13.5853 23.707C13.3881 23.9042 13.1369 24.0385 12.8634 24.0929C12.5899 24.1473 12.3064 24.1194 12.0488 24.0127C11.7912 23.9059 11.571 23.7252 11.4161 23.4934C11.2612 23.2615 11.1785 22.9889 11.1785 22.7101C11.1785 22.3362 11.327 21.9776 11.5914 21.7132C11.8558 21.4488 12.2144 21.3002 12.5883 21.3002Z'
        fill={primaryColor}
      />
      <path
        d='M29.9587 41.2011C34.5109 41.2011 38.2011 37.5109 38.2011 32.9588C38.2011 28.4067 34.5109 24.7165 29.9587 24.7165C25.4066 24.7165 21.7164 28.4067 21.7164 32.9588C21.7164 37.5109 25.4066 41.2011 29.9587 41.2011Z'
        fill={secondaryColor}
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M29.9587 25.5154C25.8479 25.5154 22.5153 28.8479 22.5153 32.9588C22.5153 37.0696 25.8479 40.4021 29.9587 40.4021C34.0696 40.4021 37.4021 37.0696 37.4021 32.9588C37.4021 28.8479 34.0696 25.5154 29.9587 25.5154ZM20.9175 32.9588C20.9175 27.9654 24.9654 23.9175 29.9587 23.9175C34.9521 23.9175 39 27.9654 39 32.9588C39 37.9521 34.9521 42 29.9587 42C24.9654 42 20.9175 37.9521 20.9175 32.9588Z'
        fill={primaryColor}
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M30.6989 27.7593C30.6989 27.34 30.359 27 29.9396 27C29.5203 27 29.1803 27.34 29.1803 27.7593V28.3782C28.5993 28.4844 28.0661 28.7115 27.632 29.037C27.0139 29.5005 26.5605 30.1988 26.5605 31.0346C26.5605 31.8699 27.0132 32.5686 27.6312 33.0325C28.2489 33.4962 29.0675 33.7606 29.9395 33.7606C30.5151 33.7606 31.0069 33.9361 31.3375 34.1837C31.6678 34.4311 31.8012 34.715 31.8012 34.9654C31.8012 35.216 31.6676 35.5007 31.337 35.7487C31.0062 35.9969 30.5146 36.1728 29.9395 36.1728C29.9296 36.1728 29.9197 36.173 29.9098 36.1734C29.5778 36.1864 29.2475 36.1192 28.947 35.9775C28.6464 35.8358 28.3845 35.6238 28.1833 35.3594C27.9294 35.0257 27.453 34.961 27.1192 35.2149C26.7855 35.4688 26.7208 35.9452 26.9748 36.2789C27.3237 36.7376 27.7781 37.1054 28.2994 37.3511C28.5806 37.4837 28.8767 37.5787 29.1803 37.6345V38.2407C29.1803 38.66 29.5203 39 29.9396 39C30.359 39 30.6989 38.66 30.6989 38.2407V37.6231C31.2805 37.5167 31.814 37.2893 32.2484 36.9635C32.8665 36.4997 33.3198 35.801 33.3198 34.9654C33.3198 34.1295 32.8663 33.4314 32.2478 32.9682C31.6298 32.5054 30.8111 32.242 29.9395 32.242C29.3646 32.242 28.8733 32.0661 28.5429 31.818C28.2128 31.5702 28.0791 31.2855 28.0791 31.0346C28.0791 30.7841 28.2127 30.4997 28.543 30.252C28.8737 30.0041 29.365 29.8285 29.9395 29.8285C29.9498 29.8285 29.9601 29.8283 29.9703 29.8279C30.302 29.8144 30.6321 29.8813 30.9325 30.0227C31.2329 30.1642 31.4947 30.3761 31.6956 30.6404C31.9494 30.9742 32.4258 31.0391 32.7596 30.7853C33.0934 30.5315 33.1583 30.0551 32.9045 29.7213C32.5556 29.2624 32.1011 28.8945 31.5795 28.6488C31.2984 28.5165 31.0024 28.4217 30.6989 28.3661V27.7593Z'
        fill={primaryColor}
      />
    </svg>
  );
}
