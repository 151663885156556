import { DragHandle as MuiDragHandle } from '@material-ui/icons';
import React from 'react';
import { useTheme } from 'styled-components';

import { SVGIconProps } from 'Components/Icon/IconMap';

import { SharedTheme } from '../../Theme';

export function DragHandle(props: SVGIconProps & { size?: string }): React.ReactElement {
  const theme = useTheme() as SharedTheme;
  return (
    <MuiDragHandle
      style={{ color: props.color?.primary || theme.colors.icons.primary, fontSize: props.size || '16px' }}
    />
  );
}
