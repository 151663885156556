import React from 'react';
import { useTheme } from 'styled-components';

import { SVGIconProps } from 'Components/Icon/IconMap';

import { SharedTheme } from '../../Theme';

export function Day(props: SVGIconProps): React.ReactElement {
  const theme = useTheme() as SharedTheme;
  return (
    <svg fill='none' height='21' viewBox='0 0 21 21' width='21' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='m10.0312 15.688c3.1242 0 5.6568-2.5326 5.6568-5.6567 0-3.12416-2.5326-5.65676-5.6568-5.65676-3.12409 0-5.65669 2.5326-5.65669 5.65676 0 3.1241 2.5326 5.6567 5.65669 5.6567z'
        fill='#f6f6f6'
      />
      <g clipRule='evenodd' fill={props.color?.primary || theme.colors.icons.primary} fillRule='evenodd'>
        <path d='m10.0313 4.97792c-2.79084 0-5.05329 2.26245-5.05329 5.05338 0 2.7908 2.26245 5.0533 5.05329 5.0533 2.7909 0 5.0534-2.2625 5.0534-5.0533 0-2.79093-2.2625-5.05338-5.0534-5.05338zm-6.26006 5.05338c0-3.45741 2.80274-6.26015 6.26006-6.26015 3.4574 0 6.2601 2.80274 6.2601 6.26015 0 3.4573-2.8027 6.2601-6.2601 6.2601-3.45732 0-6.26006-2.8028-6.26006-6.2601z' />
        <path d='m10.0314 0c.3332 0 .6033.270144.6033.603383v1.257047c0 .33324-.2701.60339-.6033.60339-.33328 0-.60342-.27015-.60342-.60339v-1.257047c0-.333239.27014-.603383.60342-.603383z' />
        <path d='m17.1245 2.93806c.2357.23564.2356.61768 0 .85332l-.8891.88915c-.2357.23564-.6177.23564-.8534 0-.2356-.23564-.2356-.61768 0-.85331l.8892-.88916c.2356-.23563.6177-.23563.8533 0z' />
        <path d='m17.5986 10.0313c0-.33328.2702-.60343.6034-.60343h1.2571c.3332 0 .6034.27015.6034.60343 0 .3332-.2702.6033-.6034.6033h-1.2571c-.3332 0-.6034-.2701-.6034-.6033z' />
        <path d='m15.382 15.382c.2357-.2357.6177-.2357.8534 0l.8891.8891c.2356.2357.2356.6177 0 .8533-.2356.2357-.6177.2357-.8533 0l-.8892-.8891c-.2356-.2357-.2356-.6177 0-.8533z' />
        <path d='m10.0314 17.5987c.3332 0 .6033.2701.6033.6034v1.257c0 .3333-.2701.6034-.6033.6034-.33328 0-.60342-.2701-.60342-.6034v-1.257c0-.3333.27014-.6034.60342-.6034z' />
        <path d='m4.68042 15.382c.23564.2356.23564.6176 0 .8533l-.88915.8891c-.23563.2357-.61768.2357-.85331 0-.23564-.2356-.23564-.6176 0-.8533l.88915-.8891c.23563-.2357.61768-.2357.85331 0z' />
        <path d='m0 10.0313c0-.33328.270144-.60343.603383-.60343h1.257047c.33324 0 .60339.27015.60339.60343 0 .3332-.27015.6033-.60339.6033h-1.257047c-.333239 0-.603383-.2701-.603383-.6033z' />
        <path d='m2.93796 2.93806c.23563-.23563.61767-.23563.85331 0l.88915.88916c.23564.23563.23564.61767 0 .85331-.23563.23564-.61768.23564-.85331 0l-.88915-.88915c-.23564-.23564-.23564-.61768 0-.85332z' />
      </g>
    </svg>
  );
}
