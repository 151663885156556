import ReactHtmlParser from 'html-react-parser';
import React from 'react';

import { InfoVariants } from '@breathelife/questionnaire-engine';
import { Language } from '@breathelife/types';

import {
  BorderStyle,
  ConfirmationIcon,
  Container,
  ErrorIcon,
  IconContainer,
  IconVerticalAlignment,
  InformationIcon,
  WarningIcon,
  TextContainer,
} from './Styles';

type InfoVariantIconMap = Omit<Record<keyof typeof InfoVariants, React.ComponentType>, 'paragraph'>;

export type InformationProps = {
  title?: string;
  text: string;
  locale?: Language;
  iconMap?: InfoVariantIconMap;
  variant?: InfoVariants;
  borderStyle?: BorderStyle;
  iconVerticalAlignment?: IconVerticalAlignment;
  className?: string;
};

const IconMap: InfoVariantIconMap = {
  [InfoVariants.error]: ErrorIcon,
  [InfoVariants.info]: InformationIcon,
  [InfoVariants.success]: ConfirmationIcon,
  [InfoVariants.warning]: WarningIcon,
};

export function Information(props: InformationProps): React.ReactElement {
  let icon: React.ComponentType | undefined;
  if (props.variant) {
    if (props.variant === 'paragraph') {
      icon = undefined;
    } else {
      icon = props.iconMap?.[props.variant] ?? IconMap[props.variant];
    }
  }

  return (
    <Container className={props.className} variant={props.variant} borderStyle={props.borderStyle ?? 'straight'}>
      {icon ? (
        <IconContainer verticalAlignment={props.iconVerticalAlignment ?? 'top'}>
          {React.createElement(icon)}
        </IconContainer>
      ) : null}
      <div>
        {props.title && (
          <TextContainer>
            <strong>{ReactHtmlParser(props.title)}</strong>
          </TextContainer>
        )}
        <TextContainer>{ReactHtmlParser(props.text)}</TextContainer>
      </div>
    </Container>
  );
}
