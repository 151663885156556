import React from 'react';
import { Popup } from 'semantic-ui-react';

import { Button, Dot, Content, Link } from './Styles';

export type LegalLink = { text: string; href?: string };

export function LegalMenu(props: { links: LegalLink[] }): React.ReactElement {
  return (
    <Popup
      trigger={
        <Button aria-label='menu'>
          <Dot />
          <Dot />
          <Dot />
        </Button>
      }
      flowing
      hoverable
      on='click'
      position='top right'
      offset='-24px, -27px'
      basic
    >
      <Content>
        {props.links.map((link, index) => (
          <Link key={`${link.text}-${index}`} href={link.href} rel='noopener noreferrer' target='_blank'>
            {link.text}
          </Link>
        ))}
      </Content>
    </Popup>
  );
}
