import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import styled from 'styled-components';

import { HeaderButton } from '../Button/HeaderButton';

export const StyledHeaderButton = styled(HeaderButton)`
  &&& {
    width: 36px;
    height: 36px;
    border-radius: 50%;
  }
`;

export const StyledMenu = styled(Menu)`
  .MuiList-padding {
    padding: 0;
  }

  .MuiList-root {
    border: 1px solid ${(props) => props.theme.colors.grey.light};
    box-sizing: border-box;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    border-radius: 6px;
  }
`;

export const StyledMenuItem = styled(MenuItem)`
  height: 40px;
  min-width: 180px;
  font-size: 16px;
  line-height: 22px;
  box-shadow: inset 0px -1px 0px #f6f6f6, inset 0px 1px 0px #fbfbfb;
  background: ${(props) => props.theme.colors.grey[10]} !important;
  color: ${(props) => props.theme.colors.grey[80]} !important;

  &:hover {
    background: ${(props) => props.theme.colors.grey[20]} !important;
    color: ${(props) => props.theme.colors.grey[90]} !important;
  }
`;

export const Link = styled.a`
  text-decoration: none;
`;
