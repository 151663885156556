import { TextFieldProps } from '@material-ui/core';
import React, { useEffect, useRef } from 'react';
import styled from 'styled-components';

import { IsoCountryCode } from '@breathelife/types';

import { AddressAutocomplete, AddressProperties, Autocomplete } from '../GoogleMapsAutocomplete/Autocomplete';
import { FieldProps, InputVariant } from './Helpers/FieldHelper';
import { Input as TextInput } from './TextInput';

type Props = TextFieldProps &
  FieldProps & {
    countryCode: IsoCountryCode;
    useAutocompleteGeolocalization?: boolean;
    onAutocompleteAnswerChange: (answerValue: unknown, nodeId: string) => void;
    nodeIdsToUpdate: {
      autocomplete?: string;
      streetAddress: string;
      city: string;
      provinceOrState: string;
      postalOrZipCode: string;
    };
    inputVariant?: InputVariant;
    placeholder?: string;
  };

const AutoCompleteInputContainer = styled.div`
  position: relative;
  div.pac-container {
    top: 65px !important;
    left: 0px !important;
  }
`;

export function AddressAutocompleteInput(props: Props): React.ReactElement {
  const {
    countryCode,
    useAutocompleteGeolocalization,
    onAutocompleteAnswerChange,
    nodeIdsToUpdate,
    placeholder,
    onAnswerChange,
    ...textInputProps
  } = props;
  const inputRef = useRef<HTMLInputElement>();
  const addressInputRef = useRef<HTMLDivElement>(null);

  function formatStreetAddress(number: string, street: string): string {
    const streetNumber = number ? `${number} ` : '';
    return `${streetNumber}${street ?? ''}`;
  }

  function handleAutocompleteSelectionChange(addressProperties: AddressProperties): void {
    const { number, street, city, stateOrProvince, postalCode } = addressProperties;
    const addressPropertiesString = [number, street, city, stateOrProvince, postalCode].join(' ');

    if ((number || street) && nodeIdsToUpdate.streetAddress)
      onAutocompleteAnswerChange(formatStreetAddress(number, street), nodeIdsToUpdate.streetAddress);
    if (city && nodeIdsToUpdate.city) onAutocompleteAnswerChange(city, nodeIdsToUpdate.city);
    if (stateOrProvince && nodeIdsToUpdate.provinceOrState)
      onAutocompleteAnswerChange(stateOrProvince, nodeIdsToUpdate.provinceOrState);
    if (postalCode && nodeIdsToUpdate.postalOrZipCode)
      onAutocompleteAnswerChange(postalCode, nodeIdsToUpdate.postalOrZipCode);

    if (nodeIdsToUpdate.autocomplete) {
      onAutocompleteAnswerChange(addressPropertiesString, nodeIdsToUpdate.autocomplete);
    }
  }

  useEffect(() => {
    const autocomplete: Autocomplete = new AddressAutocomplete(
      countryCode,
      handleAutocompleteSelectionChange,
      useAutocompleteGeolocalization ?? true
    );
    inputRef.current && autocomplete.initialize(inputRef.current);

    if (addressInputRef.current) {
      setTimeout(() => {
        const child = document.querySelector<HTMLElement>('.pac-container');
        if (child) {
          addressInputRef?.current?.appendChild(child);
        }
      }, 1000);
    }
    return () => {
      autocomplete.terminate();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [window.google?.maps]);

  return (
    <AutoCompleteInputContainer ref={addressInputRef}>
      <TextInput
        {...textInputProps}
        placeholder={placeholder ?? ''}
        onChange={(e) => {
          onAnswerChange(e.target.value);
        }}
        inputRef={inputRef}
      />
    </AutoCompleteInputContainer>
  );
}
