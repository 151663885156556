import React from 'react';

export function QuestionIcon(): React.ReactElement {
  return (
    <svg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M7.27081 13.7761C7.48665 13.92 7.74041 13.9968 8 13.9968C8.3481 13.9968 8.68194 13.8589 8.92808 13.6133C9.17422 13.3677 9.3125 13.0346 9.3125 12.6874C9.3125 12.4284 9.23552 12.1752 9.0913 11.9599C8.94708 11.7445 8.7421 11.5767 8.50227 11.4776C8.26244 11.3785 7.99854 11.3525 7.74394 11.4031C7.48934 11.4536 7.25548 11.5783 7.07192 11.7614C6.88837 11.9446 6.76336 12.1779 6.71272 12.4319C6.66208 12.6859 6.68807 12.9492 6.78741 13.1885C6.88675 13.4277 7.05497 13.6323 7.27081 13.7761Z'
        fill='#757575'
      />
      <path
        d='M9.01948 9.00869C9.11266 8.86768 9.24484 8.75668 9.4 8.68912C9.9782 8.43736 10.4774 8.03436 10.8449 7.52275C11.2124 7.01114 11.4344 6.40995 11.4876 5.78278C11.5407 5.1556 11.4229 4.52577 11.1467 3.95989C10.8705 3.39401 10.4461 2.91314 9.91847 2.56814C9.39082 2.22313 8.77951 2.02683 8.14921 2C7.51892 1.97317 6.89307 2.1168 6.33788 2.4157C5.78269 2.7146 5.31879 3.15766 4.99527 3.698C4.67175 4.23834 4.50062 4.85589 4.5 5.48529C4.5 5.71682 4.59219 5.93887 4.75628 6.10258C4.92038 6.2663 5.14294 6.35827 5.375 6.35827C5.60706 6.35827 5.82962 6.2663 5.99372 6.10258C6.15781 5.93887 6.25 5.71682 6.25 5.48529C6.25 5.02224 6.43437 4.57815 6.76256 4.25072C7.09075 3.92329 7.53587 3.73934 8 3.73934C8.46413 3.73934 8.90925 3.92329 9.23744 4.25072C9.56563 4.57815 9.75 5.02224 9.75 5.48529C9.74948 5.82457 9.6499 6.15634 9.46343 6.44005C9.27696 6.72377 9.01167 6.94713 8.7 7.08284C8.23181 7.2867 7.83342 7.62243 7.55366 8.04885C7.2739 8.47528 7.12492 8.97388 7.125 9.48353C7.125 9.71506 7.21719 9.9371 7.38128 10.1008C7.54538 10.2645 7.76794 10.3565 8 10.3565C8.23206 10.3565 8.45462 10.2645 8.61872 10.1008C8.78281 9.9371 8.875 9.71506 8.875 9.48353C8.8761 9.31464 8.92629 9.14969 9.01948 9.00869Z'
        fill='#757575'
      />
    </svg>
  );
}
