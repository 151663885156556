import { List as MuiList, ListItem as MuiListItem } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import React from 'react';
import styled from 'styled-components';

import { Bullet as BulletIcon } from '../../Images/icons/Bullet';
import { Checkmark as CheckmarkIcon } from '../../Images/icons/Checkmark';

type IconType = 'checkmark' | 'bullet';

function getListStyleIcon(iconType: IconType): React.ReactElement | undefined {
  switch (iconType) {
    case 'checkmark':
      return <CheckmarkIcon />;

    case 'bullet':
      return <BulletIcon />;

    default:
      return;
  }
}

export const StyledList = styled(MuiList)<{ $noSpacing?: boolean }>`
  && {
    margin: 0;
    padding: 0;
    color: ${(props) => props.theme.colors.grey[90]};
    line-height: 1.5;

    li {
      align-items: initial;
      margin: 0;
      padding: 0;
      margin-bottom: ${({ $noSpacing }) => ($noSpacing ? '0' : '10px')};

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
`;

type ListItemProps = {
  children: React.ReactNode;
  iconType: IconType;
};

export function ListItem(props: ListItemProps): React.ReactElement {
  const { children, iconType } = props;
  const icon = getListStyleIcon(iconType);
  return (
    <MuiListItem>
      <Box minWidth='12px' textAlign='center'>
        {icon}
      </Box>
      <Box ml={1}>{children}</Box>
    </MuiListItem>
  );
}

type ListProps = {
  children: React.ReactNode;
  noSpacing?: boolean;
};

export function List(props: ListProps): React.ReactElement {
  const { children, noSpacing } = props;
  return <StyledList $noSpacing={noSpacing}>{children}</StyledList>;
}
