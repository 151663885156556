import React from 'react';
import styled from 'styled-components';

import { Loader, LoaderProps } from './Loader';

export const RelativeContainer = styled.div`
  position: relative;
`;

export const LoaderContainer = styled.div`
  position: absolute;
  z-index: 1;
  height: 100%;
  width: 100%;
  justify-content: center;
  align-items: center;
  display: flex;
`;

export const OverLay = styled.div`
  position: absolute;
  height: 100%;
  width: 100%;
  background-color: white;
  opacity: 0.7;
`;

type Props = {
  isLoading: boolean;
  children?: React.ReactNode;
} & LoaderProps;

export function LoaderOverlay(props: Props): React.ReactElement {
  const { isLoading, children, ...loaderProps } = props;
  return (
    <RelativeContainer>
      {isLoading && (
        <LoaderContainer>
          <OverLay />
          <Loader {...loaderProps} />
        </LoaderContainer>
      )}
      {children}
    </RelativeContainer>
  );
}
