import { Checkbox as SemanticCheckbox } from 'semantic-ui-react';
import styled, { css } from 'styled-components';

import { fontSize } from '../../Theme/Theme';

const checkSvg =
  "<svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'> <path fill='%23fff' fillRule='nonzero' d='m16.76 8.01c-.27.04-.53.18-.7.39-.34.43-2.04 2.55-5.09 6.37-1.95-1.43-3.02-2.22-3.24-2.38-.47-.33-1.13-.26-1.49.16-.37.43-.3 1.04.15 1.38.41.31 3.7 2.73 4.11 3.04.23.16.52.24.81.2.29-.03.55-.17.72-.39.58-.72 5.19-6.48 5.76-7.2.17-.21.24-.48.2-.74-.05-.26-.2-.5-.43-.65s-.52-.22-.8-.18z'/></svg>";

export const BaseCheckboxStyle = css`
  label {
    text-align: left;
  }
  label:before {
    flex-basis: 24px;
    flex-shrink: 0;
    height: 24px;
    margin-right: 12px;
    position: relative;
    border: 1px solid ${(props) => props.theme.colors.selection.border.default};
  }
  label:after {
    display: none;
  }

  &&:active label:before,
  &&:focus label:before,
  input:active + label:before,
  input:focus + label:before {
    border-color: ${(props) => props.theme.colors.selection.border.default} !important;
  }
`;

export const InputBoxContainer = styled.div`
  &&&&& {
    label {
      display: flex;
      align-items: center;
      cursor: pointer;
      font-size: ${fontSize.label.medium};
      color: ${(props) => props.theme.colors.selection.text.default};
    }
  }
`;

export const StyledCheckbox = styled(SemanticCheckbox)`
  sup {
    color: ${(props) => props.theme.colors.base.primary};
  }

  &&& {
    width: 100%;

    ${BaseCheckboxStyle}

    label:before {
      border-radius: 4px;
    }
    input:checked + label:before {
      content: ${`url("data:image/svg+xml;utf8,${checkSvg}")`};
      background-color: ${(props) => props.theme.colors.selection.border.selected};
      border-color: ${(props) => props.theme.colors.selection.border.selected};
    }
  }
`;

export const StyledSimpleCheckbox = styled(StyledCheckbox)`
  &&&&& {
    &&:hover label:before,
    input:hover + label:before {
      border-color: ${(props) =>
        props.disabled
          ? props.theme.colors.selection.border.default
          : props.checked
          ? props.theme.colors.selection.border.selected
          : props.theme.colors.selection.border.hover};
    }

    &&:active label:before,
    input:active + label:before {
      border-color: ${(props) =>
        props.disabled
          ? props.theme.colors.selection.border.default
          : props.checked
          ? props.theme.colors.selection.border.selected
          : props.theme.colors.selection.border.active};
    }

    &&:focus label:before,
    input:focus + label:before {
      border-color: ${(props) =>
        props.disabled
          ? props.theme.colors.selection.border.default
          : props.theme.colors.selection.border.focus} !important;
      border-width: ${(props) => (props.disabled ? props.theme.metrics.borders.default : '1px')};
    }
  }
`;
