import React from 'react';

import { HeaderContainer, LeftSectionContainer, MiddleSectionContainer, RightSectionContainer } from './Styles';

type Props = {
  leftSectionContent?: React.ReactNode;
  middleSectionContent?: React.ReactNode;
  rightSectionContent?: React.ReactNode;
};

export function HeaderLayout(props: Props): React.ReactElement {
  return (
    <HeaderContainer>
      <LeftSectionContainer>{props.leftSectionContent}</LeftSectionContainer>
      <MiddleSectionContainer>{props.middleSectionContent}</MiddleSectionContainer>
      <RightSectionContainer>{props.rightSectionContent}</RightSectionContainer>
    </HeaderContainer>
  );
}
