import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import styled from 'styled-components';

export const InfoCardContainer = styled.div`
  position: relative;
  text-align: left;

  @media (min-width: ${(props) => props.theme.breakpoints.tablet.max}) {
    max-width: ${(props) => props.theme.metrics.input.maxWidth};
  }
`;

export const StyledCard = styled(Card)`
  background-color: ${(props) => props.theme.components.InfoCard.backgroundColor} !important;
  border-radius: ${(props) => props.theme.components.InfoCard.borderRadius} !important;
`;

export const StyledCardContent = styled(CardContent)`
  padding: 24px !important;
`;
