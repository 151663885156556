import _ from 'lodash';
import React from 'react';

import { Container, ProgressProps, StyledProgress } from './Styles';

export function ProgressBar(props: ProgressProps & { className?: string }): React.ReactElement | null {
  if (_.isNil(props.progress)) return null;

  return (
    <Container className={props.className}>
      <StyledProgress {...props} />
    </Container>
  );
}
