import React from 'react';
import styled, { css, FlattenSimpleInterpolation, StyledComponent } from 'styled-components';

export enum TypographyComponents {
  h1 = 'h1',
  h2 = 'h2',
  h3 = 'h3',
  h4 = 'h4',
  p = 'p',
  span = 'span',
  a = 'a',
}
export enum TypographyVariants {
  h1 = 'h1',
  h2 = 'h2',
  h3 = 'h3',
  h4 = 'h4',
  body1 = 'body1',
  body2 = 'body2',
  body3 = 'body3',
  body4 = 'body4',
  body5 = 'body5',
  button = 'button',
  small1 = 'small1',
  small2 = 'small2',
  link = 'link',
}

const baseTypographyMixin = css`
  margin: 0;
`;
const h1Mixin = css`
  white-space: pre-wrap;
  font-size: 1.3125rem;
  font-weight: 500;
`;
const h2Mixin = css`
  white-space: pre-wrap;
  font-size: 1.125rem;
  font-weight: 600;
`;
const h3Mixin = css`
  font-size: 1rem;
  font-weight: 600;
`;
const h4Mixin = css`
  font-weight: bold;
  font-size: 0.9375rem;
  font-weight: 600;
`;
const body1Mixin = css`
  font-size: 0.9375rem;
  font-weight: 400;
`;
const body2Mixin = css`
  font-size: 0.875rem;
  font-weight: 600;
`;
const body3Mixin = css`
  font-size: 0.875rem;
  font-weight: 400;
`;
const body4Mixin = css`
  font-size: 0.8125rem;
  font-weight: 600;
`;
const body5Mixin = css`
  font-size: 0.8125rem;
  font-weight: 400;
`;
const buttonMixin = css`
  font-size: 0.875rem;
  font-weight: 500;
`;

const small1Mixin = css`
  font-size: 0.75rem;
  font-weight: 400;
`;

const small2Mixin = css`
  font-size: 0.6875rem;
  font-weight: 500;
`;

const linkMixin = css`
  font-size: 0.875rem;
  font-weight: 400;
`;

const typographyVariantToMixinMap: Record<TypographyVariants, FlattenSimpleInterpolation> = {
  [TypographyVariants.h1]: h1Mixin,
  [TypographyVariants.h2]: h2Mixin,
  [TypographyVariants.h3]: h3Mixin,
  [TypographyVariants.h4]: h4Mixin,
  [TypographyVariants.body1]: body1Mixin,
  [TypographyVariants.body2]: body2Mixin,
  [TypographyVariants.body3]: body3Mixin,
  [TypographyVariants.body4]: body4Mixin,
  [TypographyVariants.body5]: body5Mixin,
  [TypographyVariants.link]: linkMixin,
  [TypographyVariants.small1]: small1Mixin,
  [TypographyVariants.small2]: small2Mixin,
  [TypographyVariants.button]: buttonMixin,
};

const getVariantMixin = (variant: TypographyVariants): FlattenSimpleInterpolation => {
  return typographyVariantToMixinMap[variant];
};

const H1 = styled.h1<{ variant?: TypographyVariants; color?: string }>`
  ${baseTypographyMixin}
  color: ${({ theme, color }) => color || theme.colors.grey[90]};
  ${(props) => (props.variant ? getVariantMixin(props.variant) : h1Mixin)};
`;

const H2 = styled.h2<{ variant?: TypographyVariants; color?: string }>`
  ${baseTypographyMixin}
  color: ${({ theme, color }) => color || theme.colors.grey[90]};
  ${(props) => (props.variant ? getVariantMixin(props.variant) : h2Mixin)};
`;

const H3 = styled.h3<{ variant?: TypographyVariants; color?: string }>`
  ${baseTypographyMixin}
  color: ${({ theme, color }) => color || theme.colors.grey[90]};
  ${(props) => (props.variant ? getVariantMixin(props.variant) : h3Mixin)};
`;

const H4 = styled.h4<{ variant?: TypographyVariants; color?: string }>`
  ${baseTypographyMixin}
  color: ${({ theme, color }) => color || theme.colors.grey[90]};
  ${(props) => (props.variant ? getVariantMixin(props.variant) : h4Mixin)};
`;

const P = styled.p<{ variant?: TypographyVariants; color?: string }>`
  ${baseTypographyMixin}
  color: ${({ theme, color }) => color || theme.colors.grey[90]};
  ${(props) => (props.variant ? getVariantMixin(props.variant) : body1Mixin)};
`;

const SPAN = styled.span<{ variant?: TypographyVariants; color?: string }>`
  ${baseTypographyMixin}
  color: ${({ theme, color }) => color || theme.colors.grey[90]};
  ${(props) => (props.variant ? getVariantMixin(props.variant) : body1Mixin)};
`;

const A = styled.a<{ variant?: TypographyVariants; color?: string }>`
  ${baseTypographyMixin}
  color: ${({ theme }) => theme.colors.primary.default};
  ${(props) => (props.variant ? getVariantMixin(props.variant) : body1Mixin)};
`;

const typographyComponentToComponentMap: Record<
  TypographyComponents,
  StyledComponent<'h1' | 'h2' | 'h3' | 'h4' | 'p' | 'span' | 'a', any, Record<string, unknown>, never>
> = {
  [TypographyComponents.h1]: H1,
  [TypographyComponents.h2]: H2,
  [TypographyComponents.h3]: H3,
  [TypographyComponents.h4]: H4,
  [TypographyComponents.p]: P,
  [TypographyComponents.a]: A,
  [TypographyComponents.span]: SPAN,
};

const variantToComponentMap: Record<
  TypographyVariants,
  StyledComponent<'h1' | 'h2' | 'h3' | 'h4' | 'p' | 'span' | 'a', any, Record<string, unknown>, never>
> = {
  [TypographyVariants.h1]: H1,
  [TypographyVariants.h2]: H2,
  [TypographyVariants.h3]: H3,
  [TypographyVariants.h4]: H4,
  [TypographyVariants.body1]: P,
  [TypographyVariants.body2]: P,
  [TypographyVariants.body3]: P,
  [TypographyVariants.body4]: P,
  [TypographyVariants.body5]: P,
  [TypographyVariants.link]: A,
  [TypographyVariants.small1]: P,
  [TypographyVariants.small2]: P,
  [TypographyVariants.button]: SPAN,
};

type ProducerTypographyProps = {
  component?: TypographyComponents;
  variant: TypographyVariants;
  children: React.ReactNode | string;
  color?: string;
};

export function ProducerTypography({
  component,
  variant,
  children,
  color,
}: ProducerTypographyProps): React.ReactElement {
  const Component = component ? typographyComponentToComponentMap[component] : variantToComponentMap[variant];

  return (
    <Component variant={variant} color={color}>
      {children}
    </Component>
  );
}
