import InputAdornment from '@material-ui/core/InputAdornment';
import { TextFieldProps } from '@material-ui/core/TextField';
import React, { useEffect, useState } from 'react';
import ReactNumberFormat, { NumberFormatValues } from 'react-number-format';

import { NumericalDataType } from '@breathelife/questionnaire-engine';
import { Language } from '@breathelife/types';

import { FieldProps, getFieldLabel, InputVariant } from './Helpers/FieldHelper';
import { Input } from './TextInput';

type Props = TextFieldProps &
  Omit<FieldProps, 'onAnswerChange'> & {
    value?: number;
    onAnswerChange?: (answer: string) => void;
    onAnswerComplete?: (fieldId: string, answer: string, previousAnswer: string) => void;
    inputVariant?: InputVariant;
    locale?: Language;
    isCurrency?: boolean;
    numericalDataType?: NumericalDataType;
    allowTextWrap?: boolean;
  };

function stringifyValue(value?: any): string {
  if (value == null) return '';
  if (typeof value === 'number') return JSON.stringify(value);
  return value;
}

function getNumericalDataType(isCurrency: boolean, numericalDataType?: NumericalDataType): NumericalDataType {
  if (numericalDataType) return numericalDataType;
  if (isCurrency) return 'float';
  return 'integer';
}

export function NumberInput(props: Props): React.ReactElement {
  const { value: defaultValue, name, required, onAnswerChange, isCurrency, label, title } = props;
  const [value, setValue] = useState<string>(stringifyValue(defaultValue));
  const [valueOnPreviousBlur, setValueOnPreviousBlur] = useState<string>(stringifyValue(defaultValue));

  const isLocaleFrench = props.locale === Language.fr;

  const numericalDataType = getNumericalDataType(!!isCurrency, props.numericalDataType);

  const InputProps: Record<string, any> = {
    inputProps: { inputMode: 'decimal' },
  };

  if (isCurrency) {
    if (isLocaleFrench) {
      InputProps.endAdornment = <InputAdornment position='end'>$</InputAdornment>;
    } else {
      InputProps.startAdornment = <InputAdornment position='start'>$</InputAdornment>;
    }
  }

  useEffect(() => {
    setValue(stringifyValue(props.value));
  }, [props.value]);

  const onBlur = (): void => {
    if (props.onAnswerChange && typeof value !== 'undefined') {
      props.onAnswerChange(value);
    }
    if (props.onAnswerComplete && typeof value !== 'undefined') {
      props.onAnswerComplete(props.name, value, valueOnPreviousBlur);
    }
    setValueOnPreviousBlur(value);
  };

  const onValueChange = (newValue: NumberFormatValues): void => {
    if (newValue.value === value) return;
    setValue(newValue.formattedValue);
    onAnswerChange && onAnswerChange(String(newValue.floatValue));
  };

  const thousandSeparator = isCurrency && (isLocaleFrench ? ' ' : ',');
  const decimalSeparator = isLocaleFrench ? ',' : '.';
  const formattedValue = isLocaleFrench ? value.replace(/\./, ',') : value;

  const labelValue = getFieldLabel({ label, title });
  const requiredSuffix = required ? ' *' : '';
  const labelText = labelValue ? `${labelValue}${requiredSuffix}` : null;

  return (
    <ReactNumberFormat
      id={props.id}
      data-testid={props['data-testid']}
      name={name}
      error={!!props.validationError}
      validationError={props.validationError}
      label={labelText}
      allowEmptyFormatting={required}
      placeholder={props.placeholder}
      customInput={Input}
      thousandSeparator={thousandSeparator}
      decimalSeparator={decimalSeparator}
      allowNegative={false}
      allowedDecimalSeparators={isLocaleFrench ? [',', '.'] : ['.']}
      decimalScale={numericalDataType === 'integer' ? 0 : 2}
      displayType='input'
      value={formattedValue}
      onValueChange={onValueChange}
      onBlur={onBlur}
      // TextField props
      inputVariant={props.inputVariant}
      InputProps={InputProps}
      subtitle={labelText ? props.subtitle : `${props.subtitle}${requiredSuffix}`}
      allowTextWrap={props.allowTextWrap}
      disabled={props.disabled}
    />
  );
}
