import React from 'react';

import { StyledFooter } from './Styles';

type Props = {
  children?: React.ReactNode;
};

export function PlanFinderFooter(props: Props): React.ReactElement {
  return <StyledFooter>{props.children}</StyledFooter>;
}
