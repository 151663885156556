import React from 'react';
import { useTheme } from 'styled-components';

import { SVGIconProps } from 'Components/Icon/IconMap';

import { SharedTheme } from '../../Theme';

export function Mail(props: SVGIconProps): React.ReactElement {
  const theme = useTheme() as SharedTheme;
  return (
    <svg fill='none' height='12' viewBox='0 0 14 12' width='14' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='m14 1.2v1.02277l-7 3.11129-7-3.11108v-1.02298c0-.18565.0737498-.363699.205025-.494975.131276-.131275.309323-.205025.494975-.205025h12.6c.1857 0 .3637.07375.495.205025.1313.131276.205.309325.205.494975zm-7 5.6c-.09797.00002-.19486-.02059-.28434-.06048l-6.71566-2.98452v7.245c0 .1857.0737498.3637.205025.495.131276.1313.309323.205.494975.205h12.6c.1857 0 .3637-.0737.495-.205s.205-.3093.205-.495v-7.24542l-6.71559 2.98494c-.08951.03989-.18641.06049-.28441.06048z'
        fill={props.color?.primary || theme.colors.icons.primary}
      />
    </svg>
  );
}
