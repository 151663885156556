import I18n, { ToCurrencyOptions } from 'i18n-js';
import _ from 'lodash';

import { Language } from '@breathelife/types';

export function toCurrency(locale: Language, value: number | null, options: ToCurrencyOptions = {}): string {
  if (value == null) {
    return 'N/A';
  }

  let currencyFormat = '%u%n';
  let delimiter = ',';
  if (locale === Language.fr) {
    currencyFormat = '%n %u';
    delimiter = ' ';
  }

  return I18n.toCurrency(value, _.merge(options, { delimiter, format: currencyFormat }));
}
