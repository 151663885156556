import React from 'react';
import { useTheme } from 'styled-components';

import { SVGIconProps } from 'Components/Icon/IconMap';

import { SharedTheme } from '../../Theme';

export function EducationIcon(props: SVGIconProps): React.ReactElement {
  const theme = useTheme() as SharedTheme;
  const primaryColor = props.color?.primary || theme.colors.icons.primary;
  const secondaryColor = props.color?.secondary || theme.colors.icons.secondary;
  return (
    <svg viewBox='0 0 48 48' fill='none' xmlns='http://www.w3.org/2000/svg' aria-hidden='true'>
      <path
        d='M44 24C44 35.0457 35.0457 44 24 44C12.9543 44 4 35.0457 4 24C4 12.9543 12.9543 4 24 4C35.0457 4 44 12.9543 44 24Z'
        fill='white'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M24 43C34.4934 43 43 34.4934 43 24C43 13.5066 34.4934 5 24 5C13.5066 5 5 13.5066 5 24C5 34.4934 13.5066 43 24 43ZM24 44C35.0457 44 44 35.0457 44 24C44 12.9543 35.0457 4 24 4C12.9543 4 4 12.9543 4 24C4 35.0457 12.9543 44 24 44Z'
        fill='#F6F6F6'
      />
      <rect x='16' y='8' width='16' height='7' fill={secondaryColor} />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M16.1012 8.81013C16.1012 8.36271 16.4639 8 16.9113 8H31.0885C31.536 8 31.8987 8.36271 31.8987 8.81013V15.8987C31.8987 17.9936 31.0665 20.0027 29.5852 21.484C28.1039 22.9653 26.0948 23.7975 23.9999 23.7975C21.9051 23.7975 19.896 22.9653 18.4147 21.484C16.9334 20.0027 16.1012 17.9936 16.1012 15.8987V8.81013ZM17.7214 9.62025V15.8987C17.7214 17.5639 18.3829 19.1608 19.5604 20.3383C20.7378 21.5157 22.3348 22.1772 23.9999 22.1772C25.6651 22.1772 27.262 21.5157 28.4395 20.3383C29.6169 19.1608 30.2784 17.5639 30.2784 15.8987V9.62025H17.7214Z'
        fill={primaryColor}
      />
      <path
        d='M24.0365 33.0544L17.3649 27.8124L21.1772 26.3827H26.8958L30.7081 27.8124L24.0365 33.0544Z'
        fill={secondaryColor}
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M21.0909 25.9062H26.9822L31.6688 27.6636L24.0365 33.6604L16.4043 27.6636L21.0909 25.9062ZM21.2637 26.8593L18.3255 27.9611L24.0365 32.4483L29.7476 27.9611L26.8094 26.8593H21.2637Z'
        fill={secondaryColor}
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M8 8.81013C8 8.36271 8.36271 8 8.81013 8H39.1899C39.6373 8 40 8.36271 40 8.81013C40 9.25755 39.6373 9.62025 39.1899 9.62025H8.81013C8.36271 9.62025 8 9.25755 8 8.81013Z'
        fill={primaryColor}
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M16.1012 14.8861C16.1012 14.4387 16.4639 14.076 16.9113 14.076H31.0885C31.536 14.076 31.8987 14.4387 31.8987 14.8861C31.8987 15.3335 31.536 15.6962 31.0885 15.6962H16.9113C16.4639 15.6962 16.1012 15.3335 16.1012 14.8861Z'
        fill={primaryColor}
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M10.8354 8C11.2828 8 11.6455 8.36271 11.6455 8.81013V18.9367C11.6455 19.3841 11.2828 19.7468 10.8354 19.7468C10.388 19.7468 10.0253 19.3841 10.0253 18.9367V8.81013C10.0253 8.36271 10.388 8 10.8354 8Z'
        fill={primaryColor}
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M16.5109 27.6486C16.7856 27.2954 17.2946 27.2318 17.6478 27.5065L23.9999 32.4475L30.3508 27.5065C30.7039 27.2318 31.2129 27.2954 31.4877 27.6485C31.7624 28.0016 31.6989 28.5106 31.3457 28.7854L24.4975 34.1133C24.2049 34.3409 23.7952 34.3409 23.5026 34.1133L16.653 28.7854C16.2998 28.5107 16.2362 28.0017 16.5109 27.6486Z'
        fill={primaryColor}
      />
      <path
        d='M38.1408 39.1899C37.8839 35.6149 36.2825 32.27 33.6589 29.8281C31.0353 27.3862 27.5842 26.0287 24 26.0287C20.4159 26.0287 16.9647 27.3862 14.3411 29.8281C11.7175 32.27 10.1161 35.6149 9.85925 39.1899'
        stroke={primaryColor}
        strokeWidth='1.7'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M16.9829 16.9114C16.9829 16.9114 16.9114 21.9747 12.8607 24'
        stroke={primaryColor}
        strokeWidth='1.7'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M31.0157 16.9114C31.0157 16.9114 31.0887 21.9747 35.1393 24'
        stroke={primaryColor}
        strokeWidth='1.7'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
}
