import { Grid } from '@material-ui/core';
import {
  PaymentMethodCreateParams,
  StripeCardCvcElementChangeEvent,
  StripeCardExpiryElementChangeEvent,
  StripeCardNumberElementChangeEvent,
} from '@stripe/stripe-js';
import _ from 'lodash';
import React, { ChangeEvent, useCallback, useState } from 'react';

import { Language } from '@breathelife/types';

import { Text } from '../Typography';
import localizedStrings from './Localization';
import { StripeTextField, StyledCardCvcElement, StyledCardExpiryElement, StyledCardNumberElement } from './Styles';

export type StripeElementsState = {
  cardNumber: {
    isEmpty: boolean;
  };
  cardExpiry: {
    isEmpty: boolean;
  };
  cardCvc: {
    isEmpty: boolean;
  };
};

export type StripeCardDetailsFormValidationErrors = PaymentMethodCreateParams.BillingDetails & {
  card_number?: string;
  card_expiry?: string;
  card_cvc?: string;
};

type StripeCardDetailsFormProps = {
  language: Language;
  disabled?: boolean;
  onStripeElementsChanged?: (state: StripeElementsState) => void;
  onBillingDetailsChanged?: (billingDetails: PaymentMethodCreateParams.BillingDetails) => void;
  validationErrors?: StripeCardDetailsFormValidationErrors;
};

export const defaultStripeElementsState = {
  cardNumber: {
    isEmpty: true,
  },
  cardExpiry: {
    isEmpty: true,
  },
  cardCvc: {
    isEmpty: true,
  },
};

/**
 * Validate billing details
 *
 * @param stripeElementsState State of the Stripe Elements in the form (isEmpty)
 * @param billingDetails BillingDetails object to be submitted to Stripe
 * @return validationErrors Validation errors with the property names as keys
 */
export function validateCreditCardDetailsForm(
  stripeElementsState: StripeElementsState,
  billingDetails: PaymentMethodCreateParams.BillingDetails
): StripeCardDetailsFormValidationErrors {
  const validationErrors: StripeCardDetailsFormValidationErrors = {};
  const addressValidationErrors: PaymentMethodCreateParams.BillingDetails.Address = {};

  if (stripeElementsState.cardNumber.isEmpty) {
    validationErrors.card_number = 'Missing card number';
  }

  if (stripeElementsState.cardExpiry.isEmpty) {
    validationErrors.card_expiry = 'Missing expiry date';
  }

  if (stripeElementsState.cardCvc.isEmpty) {
    validationErrors.card_cvc = 'Missing CVC number';
  }

  if (!billingDetails.address?.postal_code || billingDetails.address.postal_code.trim().length < 1) {
    addressValidationErrors.postal_code = 'Missing postal code';
  }

  if (!_.isEmpty(addressValidationErrors)) {
    validationErrors.address = addressValidationErrors;
  }

  return validationErrors;
}

export function StripeCardDetailsForm(props: StripeCardDetailsFormProps): React.ReactElement {
  const { disabled, language, onStripeElementsChanged, onBillingDetailsChanged, validationErrors } = props;

  const textStrings = localizedStrings[language];

  const [, setStripeElementsState] = useState<StripeElementsState>(defaultStripeElementsState);
  const [, setBillingDetails] = useState<PaymentMethodCreateParams.BillingDetails>({});

  const onCardNumberChanged = useCallback(
    (event: StripeCardNumberElementChangeEvent) => {
      setStripeElementsState((stripeElementsState) => {
        const updatedState = {
          ...stripeElementsState,
          cardNumber: {
            isEmpty: event.empty,
          },
        };

        onStripeElementsChanged?.(updatedState);

        return updatedState;
      });
    },
    [onStripeElementsChanged]
  );

  const onCardExpiryChanged = useCallback(
    (event: StripeCardExpiryElementChangeEvent) => {
      setStripeElementsState((stripeElementsState) => {
        const updatedState = {
          ...stripeElementsState,
          cardExpiry: {
            isEmpty: event.empty,
          },
        };

        onStripeElementsChanged?.(updatedState);

        return updatedState;
      });
    },
    [onStripeElementsChanged]
  );

  const onCardCvcChanged = useCallback(
    (event: StripeCardCvcElementChangeEvent) => {
      setStripeElementsState((stripeElementsState) => {
        const updatedState = {
          ...stripeElementsState,
          cardCvc: {
            isEmpty: event.empty,
          },
        };

        onStripeElementsChanged?.(updatedState);

        return updatedState;
      });
    },
    [onStripeElementsChanged]
  );

  const onPostalCodeChanged = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const newValue = event.target.value;

      setBillingDetails((billingDetails) => {
        const updatedBillingDetails = {
          ...billingDetails,
          address: {
            ...billingDetails.address,
            postal_code: newValue,
          },
        };

        onBillingDetailsChanged?.(updatedBillingDetails);

        return updatedBillingDetails;
      });
    },
    [onBillingDetailsChanged]
  );

  return (
    <Grid container spacing={2}>
      <Grid item container direction='column' spacing={1}>
        <Grid item>
          <Text variant='small-copy'>{textStrings.cardNumber}</Text>
        </Grid>
        <Grid item>
          <StyledCardNumberElement
            $error={!!validationErrors?.card_number}
            options={{ disabled, showIcon: true }}
            onChange={onCardNumberChanged}
          />
        </Grid>
      </Grid>
      <Grid item container spacing={2}>
        <Grid item container direction='column' xs spacing={1}>
          <Grid item>
            <Text variant='small-copy'>{textStrings.expiry}</Text>
          </Grid>
          <Grid item>
            <StyledCardExpiryElement
              $error={!!validationErrors?.card_expiry}
              options={{ disabled }}
              onChange={onCardExpiryChanged}
            />
          </Grid>
        </Grid>
        <Grid item container direction='column' xs spacing={1}>
          <Grid item>
            <Text variant='small-copy'>{textStrings.cvc}</Text>
          </Grid>
          <Grid item>
            <StyledCardCvcElement
              $error={!!validationErrors?.card_cvc}
              options={{ disabled, placeholder: '123' }}
              onChange={onCardCvcChanged}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item container direction='column' spacing={1}>
        <Grid item>
          <Text variant='small-copy'>{textStrings.postalCode}</Text>
        </Grid>
        <Grid item>
          <StripeTextField
            fullWidth
            variant='outlined'
            autoComplete='billing postal-code'
            onChange={onPostalCodeChanged}
            disabled={disabled}
            error={!!validationErrors?.address?.postal_code}
          />
        </Grid>
      </Grid>
    </Grid>
  );
}
