import React from 'react';
import styled, { css } from 'styled-components';

import { ButtonProps } from '../Button/Button';
import { LinkButton } from '../LinkButton/LinkButton';
import { RunningText } from '../Typography';

const Container = styled(LinkButton)<{ fullSize?: boolean }>`
  ${(props) =>
    props.fullSize &&
    css`
      width: 100%;
    `}
  padding: 0;
  text-decoration: none;
`;

const PhoneText = styled(RunningText)`
  margin-left: 10px;
  color: white;
  font-weight: 500;
`;

const renderPhoneIcon = () => (
  <svg fill='none' height='16' viewBox='0 0 12 12' width='16' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='m2.92536 5.17839c1.23257 2.10043 2.0543 2.94066 4.10861 4.20089l1.64345-1.68035c.83927.21923 1.89878.52015 2.74918.5166.2834-.00231.5658.28752.5734.56438l-.0191 2.62149c-.0012.2899-.2858.5809-.5746.5986-7.08123.0127-11.17481-4.17287-11.40493769-11.4271-.02350391-.280298.26112469-.57132264.54458069-.57252264h2.808067c.31005-.01224616.59237.27640764.65965.45271264-.07188.98314.33583 2.18237.55515 3.04549z'
      fill='#fff'
    />
  </svg>
);

type PhoneButtonProps = {
  fullSize?: boolean;
  alt: string;
  phoneNumber?: string;
  img?: string;
} & ButtonProps;

export function PhoneButton({ phoneNumber, ...props }: PhoneButtonProps): React.ReactElement {
  return (
    <Container href={`tel:${phoneNumber}`} {...props}>
      {renderPhoneIcon()}
      <PhoneText>{phoneNumber}</PhoneText>
    </Container>
  );
}
