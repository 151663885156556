import styled from 'styled-components';

import { FormControlBox } from '../Box/FormControlBox';

export const SelectableCardContainerStyled = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;

  @media (min-width: ${(props) => props.theme.breakpoints.tablet.min}) {
    flex-direction: row;
    width: auto;
  }
`;

export const SelectableCardLabel = styled.label`
  cursor: pointer;
  position: relative;
  display: flex;
  flex: 1;
  margin: 0 0 20px;

  @media (min-width: ${(props) => props.theme.breakpoints.tablet.min}) {
    margin: 0 20px 0 0;
    max-width: 350px;

    &:last-child {
      margin-right: 0;
    }
  }
`;

// @TODO Should we really be using FormControlBox here? Or redefine from scratch? Or a base Card component?
export const SelectableCardStyled = styled(FormControlBox)<CheckedProps>`
  position: relative;
  width: 100%;
  padding: 50px 20px 30px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  text-align: center;
  background-color: ${(props) => props.theme.components.SelectableCard.backgroundColor} !important;
  border-color: ${(props) => props.theme.components.SelectableCard.borderColor} !important;

  &:hover {
    background-color: ${(props) => props.theme.components.SelectableCard.hover.backgroundColor} !important;
  }

  &.selectable-card--checked {
    background-color: ${(props) => props.theme.components.SelectableCard.selected.backgroundColor} !important;
    border-color: ${(props) => props.theme.components.SelectableCard.selected.borderColor} !important;
    color: inherit !important;
  }

  &.selectable-card--focused {
    border-color: ${(props) => props.theme.components.SelectableCard.focused.borderColor} !important;
  }
`;

type CheckedProps = {
  checked: boolean;
};

export const RadioButton = styled.div<CheckedProps>`
  position: relative;
  height: 24px;
  width: 24px;
  background-color: ${(props) => (props.checked ? props.theme.colors.base.primary : props.theme.colors.grey.border)};
  border: 1px solid ${(props) => (props.checked ? 'transparent' : props.theme.components.SelectableCard.borderColor)};
  border-radius: 50%;
  margin-top: 20px;
  display: inline-flex;
  align-items: stretch;
  justify-content: stretch;
  padding: 1px;

  &:after {
    content: '';
    width: 100%;
    height: 100%;
    transform: scale(${(props) => (props.checked ? 0.435 : 1)});
    transition: all ${(props) => props.theme.animation.speed.medium} ${(props) => props.theme.animation.transition};
    background-color: ${(props) => props.theme.colors.white};
    border-radius: 50%;
  }
`;

/*
The hidden input covers the whole card is to deal with the fact that 
browsers will try to put the input element in view when it gets selected. 
If it is only at the bottom of the card, weird scrolling issues can happen 
depending on where the element was clicked from in the viewport
*/
export const HiddenInput = styled.input`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  opacity: 0;
`;
