import React from 'react';
import { useTheme } from 'styled-components';

import { SVGIconProps } from 'Components/Icon/IconMap';

import { SharedTheme } from '../../Theme';

export function WarningOutlined(props: SVGIconProps): React.ReactElement {
  const theme = useTheme() as SharedTheme;
  const color = props.color?.primary || theme.colors.icons.primary;
  return (
    <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M0.75 12C0.75 14.9837 1.93526 17.8452 4.04505 19.955C6.15483 22.0647 9.01631 23.25 12 23.25C14.9837 23.25 17.8452 22.0647 19.955 19.955C22.0647 17.8452 23.25 14.9837 23.25 12C23.25 9.01631 22.0647 6.15483 19.955 4.04505C17.8452 1.93526 14.9837 0.75 12 0.75C9.01631 0.75 6.15483 1.93526 4.04505 4.04505C1.93526 6.15483 0.75 9.01631 0.75 12Z'
        fill='white'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M3.51472 3.51472C5.76516 1.26428 8.8174 0 12 0C15.1826 0 18.2348 1.26428 20.4853 3.51472C22.7357 5.76516 24 8.8174 24 12C24 15.1826 22.7357 18.2348 20.4853 20.4853C18.2348 22.7357 15.1826 24 12 24C8.8174 24 5.76516 22.7357 3.51472 20.4853C1.26428 18.2348 0 15.1826 0 12C0 8.8174 1.26428 5.76516 3.51472 3.51472ZM12 1.5C9.21523 1.5 6.54451 2.60625 4.57538 4.57538C2.60625 6.54451 1.5 9.21523 1.5 12C1.5 14.7848 2.60625 17.4555 4.57538 19.4246C6.54451 21.3938 9.21523 22.5 12 22.5C14.7848 22.5 17.4555 21.3938 19.4246 19.4246C21.3938 17.4555 22.5 14.7848 22.5 12C22.5 9.21523 21.3938 6.54451 19.4246 4.57538C17.4555 2.60625 14.7848 1.5 12 1.5Z'
        fill={color}
      />
      <path
        d='M12 16.5C11.9258 16.5 11.8533 16.522 11.7917 16.5632C11.73 16.6044 11.6819 16.663 11.6535 16.7315C11.6252 16.8 11.6177 16.8754 11.6322 16.9482C11.6467 17.0209 11.6824 17.0877 11.7348 17.1402C11.7873 17.1926 11.8541 17.2283 11.9268 17.2428C11.9996 17.2573 12.075 17.2498 12.1435 17.2215C12.212 17.1931 12.2706 17.145 12.3118 17.0833C12.353 17.0217 12.375 16.9492 12.375 16.875C12.375 16.7755 12.3355 16.6802 12.2652 16.6098C12.1948 16.5395 12.0995 16.5 12 16.5Z'
        fill={color}
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M11.375 15.9396C11.56 15.816 11.7775 15.75 12 15.75C12.2984 15.75 12.5845 15.8685 12.7955 16.0795C13.0065 16.2905 13.125 16.5766 13.125 16.875C13.125 17.0975 13.059 17.315 12.9354 17.5C12.8118 17.685 12.6361 17.8292 12.4305 17.9144C12.225 17.9995 11.9988 18.0218 11.7805 17.9784C11.5623 17.935 11.3618 17.8278 11.2045 17.6705C11.0472 17.5132 10.94 17.3127 10.8966 17.0945C10.8532 16.8762 10.8755 16.65 10.9606 16.4445C11.0458 16.2389 11.19 16.0632 11.375 15.9396ZM12.1435 17.2215C12.1661 17.2121 12.1878 17.2005 12.2083 17.1868C12.2495 17.1593 12.2846 17.1241 12.3118 17.0833C12.3254 17.063 12.337 17.0413 12.3465 17.0185C12.3654 16.9728 12.375 16.924 12.375 16.875C12.375 16.8506 12.3726 16.826 12.3678 16.8018C12.3533 16.7291 12.3176 16.6623 12.2652 16.6098C12.2127 16.5574 12.1459 16.5217 12.0732 16.5072C12.0489 16.5024 12.0244 16.5 12 16.5C11.951 16.5 11.9022 16.5096 11.8565 16.5285C11.8337 16.538 11.812 16.5496 11.7917 16.5632C11.7509 16.5904 11.7157 16.6255 11.6882 16.6667C11.6745 16.6872 11.6629 16.7089 11.6535 16.7315C11.6348 16.7768 11.625 16.8255 11.625 16.875C11.625 16.8997 11.6274 16.9242 11.6322 16.9482C11.6466 17.0204 11.682 17.0873 11.7348 17.1402C11.7877 17.193 11.8546 17.2284 11.9268 17.2428C11.9508 17.2476 11.9753 17.25 12 17.25C12.0495 17.25 12.0982 17.2402 12.1435 17.2215Z'
        fill={color}
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M12 4.5C12.4142 4.5 12.75 4.83579 12.75 5.25V13.5C12.75 13.9142 12.4142 14.25 12 14.25C11.5858 14.25 11.25 13.9142 11.25 13.5V5.25C11.25 4.83579 11.5858 4.5 12 4.5Z'
        fill={color}
      />
    </svg>
  );
}
