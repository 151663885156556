import React from 'react';
import { useTheme } from 'styled-components';

import { SVGIconProps } from 'Components/Icon/IconMap';

import { SharedTheme } from '../../Theme';

export function HealthExcellent(props: SVGIconProps): React.ReactElement {
  const theme = useTheme() as SharedTheme;
  const primaryColor = props.color?.primary || theme.colors.icons.primary;
  const secondaryColor = props.color?.secondary || theme.colors.icons.secondary;
  return (
    <svg fill='none' height='48' viewBox='0 0 48 48' width='48' xmlns='http://www.w3.org/2000/svg'>
      <path d='m44 24c0 11.0457-8.9543 20-20 20s-20-8.9543-20-20 8.9543-20 20-20 20 8.9543 20 20z' fill='#fff' />
      <g clipRule='evenodd' fillRule='evenodd'>
        <path
          d='m24 43c10.4934 0 19-8.5066 19-19s-8.5066-19-19-19-19 8.5066-19 19 8.5066 19 19 19zm0 1c11.0457 0 20-8.9543 20-20s-8.9543-20-20-20-20 8.9543-20 20 8.9543 20 20 20z'
          fill='#f6f6f6'
        />
        <path
          d='m23.75 16.8411c-1.6491 0-2.9859 1.3368-2.9859 2.9859 0 1.649 1.3368 2.9859 2.9859 2.9859s2.9859-1.3369 2.9859-2.9859c0-1.6491-1.3368-2.9859-2.9859-2.9859zm-4.8601 2.9859c0-2.6842 2.1759-4.8601 4.8601-4.8601s4.8601 2.1759 4.8601 4.8601c0 2.6841-2.1759 4.8601-4.8601 4.8601s-4.8601-2.176-4.8601-4.8601z'
          fill={primaryColor}
        />
        <path
          d='m11.4061 18.3302c.5172-.0204.9529.3824.9733.8995.13 3.3028 1.3491 6.4696 3.4668 9.0069 1.0479 1.1339 2.3353 2.0201 3.7688 2.5941.3556.1424.5887.4869.5887.87v8.8622c0 .5176-.4196.9371-.9371.9371-.5176 0-.9371-.4195-.9371-.9371v-8.2461c-1.464-.6771-2.7821-1.6371-3.8772-2.827-.0099-.0108-.0196-.0218-.029-.0331-2.3926-2.8581-3.77-6.4288-3.9167-10.1532-.0204-.5172.3824-.9529.8995-.9733z'
          fill={primaryColor}
        />
        <path
          d='m13.8267 14.6005c.2313-.463.7941-.6509 1.2571-.4196.5671.2832 1.0366.7295 1.3482 1.2816.3115.5521.4509 1.1848.4002 1.8167l-.0005.0061h-.0001v.0006c-.0021.0316-.086 1.3069.1706 2.9414.2619 1.6681.8592 3.5842 2.1346 4.9719.3671.3791.7828.7073 1.2361.9762h6.7558c.4604-.2732.8813-.6085 1.2508-.9967 1.2659-1.3815 1.8582-3.3019 2.1182-4.9729.2278-1.4649.1859-2.6194.1731-2.9208-.0521-.6314.0856-1.2641.3957-1.8167.3109-.5542.7807-1.0024 1.3488-1.287.4628-.2317 1.0258-.0445 1.2576.4183.2317.4627.0445 1.0258-.4183 1.2575-.2331.1168-.426.3008-.5535.5282-.1276.2274-.1841.4879-.1623.7477.0022.0262.0033.0524.0033.0786 0-.0226.094 1.4402-.1925 3.2823-.2841 1.8264-.9608 4.1794-2.5951 5.9584l-.0104.0113v-.0001c-.5593.5891-1.2079 1.0862-1.9222 1.4731-.137.0742-.2904.1131-.4463.1131h-7.2497c-.156 0-.3095-.039-.4466-.1133-.703-.3812-1.3434-.868-1.8986-1.4435l-.0145-.0153c-1.6443-1.783-2.3283-4.1304-2.6155-5.9596-.2847-1.8134-.1984-3.2418-.1868-3.3888.0209-.2594-.0363-.519-.1642-.7456s-.3205-.4098-.5533-.526c-.463-.2312-.6509-.794-.4197-1.2571z'
          fill={primaryColor}
        />
        <path
          d='m36.0936 18.3302c.5172.0202.9201.4557.8999.9729-.1453 3.7266-1.5228 7.2998-3.9167 10.1596-.0096.0115-.0195.0228-.0297.0338-1.0955 1.1876-2.4133 2.1455-3.8765 2.8208v8.2456c0 .5176-.4195.9371-.9371.9371s-.9371-.4195-.9371-.9371v-8.8622c0-.3834.2335-.728.5894-.8703 1.4328-.5724 2.72-1.4565 3.7684-2.5882 2.1187-2.5388 3.3377-5.7075 3.4665-9.0121.0202-.5172.4557-.9201.9729-.8999z'
          fill={primaryColor}
        />
        <path
          d='m11.252 10.5065c8.2633-1.5242 16.7356-1.5242 24.9989 0 .509.0938.8455.5825.7516 1.0915s-.5826.8455-1.0916.7516c-8.0385-1.4827-16.2804-1.4827-24.3189 0-.509.0939-.9977-.2426-1.0915-.7516-.0939-.509.2426-.9977.7515-1.0915z'
          fill={primaryColor}
        />
      </g>
      <path
        d='m40.5629 13.6623c0 .5945-.2362 1.1647-.6566 1.5851s-.9906.6566-1.5851.6566c-.5946 0-1.1647-.2362-1.5851-.6566-.4205-.4204-.6566-.9906-.6566-1.5851v-4.48345c0-.59454.2361-1.16473.6566-1.58513.4204-.42041.9905-.65659 1.5851-.65659.5945 0 1.1647.23618 1.5851.65659.4204.4204.6566.99059.6566 1.58513z'
        fill={secondaryColor}
      />
      <path
        clipRule='evenodd'
        d='m38.3212 7.87422c-.346 0-.6779.13745-.9225.38211-.2447.24467-.3821.5765-.3821.9225v4.48347c0 .346.1374.6778.3821.9225.2446.2446.5765.3821.9225.3821s.6778-.1375.9225-.3821c.2446-.2447.3821-.5765.3821-.9225v-4.48347c0-.346-.1375-.67783-.3821-.9225-.2447-.24466-.5765-.38211-.9225-.38211zm-2.2478-.94316c.5961-.59615 1.4047-.93106 2.2478-.93106.843 0 1.6516.33491 2.2477.93106.5962.59614.9311 1.40469.9311 2.24777v4.48347c0 .843-.3349 1.6516-.9311 2.2477-.5961.5962-1.4047.9311-2.2477.9311-.8431 0-1.6517-.3349-2.2478-.9311-.5962-.5961-.9311-1.4047-.9311-2.2477v-4.48347c0-.84308.3349-1.65163.9311-2.24777z'
        fill={primaryColor}
        fillRule='evenodd'
      />
      <path
        d='m11.4206 13.6623c0 .5945-.2362 1.1647-.6566 1.5851s-.99061.6566-1.58515.6566-1.16473-.2362-1.58513-.6566c-.42041-.4204-.65659-.9906-.65659-1.5851v-4.48345c0-.59454.23618-1.16473.65659-1.58513.4204-.42041.99059-.65659 1.58513-.65659s1.16475.23618 1.58515.65659c.4204.4204.6566.99059.6566 1.58513z'
        fill={secondaryColor}
      />
      <path
        clipRule='evenodd'
        d='m9.17883 7.87422c-.346 0-.67783.13745-.9225.38211-.24466.24467-.38211.5765-.38211.9225v4.48347c0 .346.13745.6778.38211.9225.24467.2446.5765.3821.9225.3821s.67784-.1375.92247-.3821c.2447-.2447.3821-.5765.3821-.9225v-4.48347c0-.346-.1374-.67783-.3821-.9225-.24463-.24466-.57647-.38211-.92247-.38211zm-2.24777-.94316c.59614-.59615 1.40469-.93106 2.24777-.93106.84307 0 1.65167.33491 2.24777.93106.5961.59614.9311 1.40469.9311 2.24777v4.48347c0 .843-.3349 1.6516-.9311 2.2477-.5961.5962-1.4047.9311-2.24777.9311-.84308 0-1.65163-.3349-2.24777-.9311-.59615-.5961-.93106-1.4047-.93106-2.2477v-4.48347c0-.84308.33491-1.65163.93106-2.24777z'
        fill={primaryColor}
        fillRule='evenodd'
      />
    </svg>
  );
}
