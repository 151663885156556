import React from 'react';
import { ButtonProps as SemanticButtonProps } from 'semantic-ui-react';

import { Loader } from '../Loader/Loader';
import { StyledButton } from './Styles';

export type ButtonProps = SemanticButtonProps & {
  'data-testid'?: string;
  onClick?: () => void;
  type?: 'button' | 'reset' | 'submit';
};

export function Button({ loading, ...props }: ButtonProps): React.ReactElement {
  return (
    <StyledButton {...props} disabled={props.disabled || props.loading} data-testid={props['data-testid'] || props.id}>
      {loading ? <Loader color='white' size='30px' /> : props.children}
    </StyledButton>
  );
}

Button.defaultProps = { loading: false };
