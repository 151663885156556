import Box from '@material-ui/core/Box';
import IconButton from '@material-ui/core/IconButton';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import { withStyles } from '@material-ui/core/styles';
import React from 'react';

import { IconName } from '@breathelife/types';

import { coreGreys } from '../../Theme/CoreColors';
import { Icon } from '../Icon/Icon';
import { Text } from '../Typography';
import { styles } from './Styles';

const variantIcon = {
  success: <Icon name={IconName.success} size='20px' color={{ primary: '#1D8046' }} />,
  warning: <Icon name={IconName.warning} size='20px' color={{ primary: '#D9A810' }} />,
  error: <Icon name={IconName.error} size='20px' color={{ primary: '#D31919' }} />,
  info: <Icon name={IconName.information} size='20px' color={{ primary: '#115BC0' }} />,
};

export enum MessageType {
  success = 'success',
  warning = 'warning',
  error = 'error',
  info = 'info',
}

interface ContentProps {
  classes: { [key: string]: string };
  title?: React.ReactNode;
  message?: React.ReactNode;
  onClose: () => void;
  type: MessageType;
}

function NotificationContent(props: ContentProps): React.ReactElement {
  const { classes, title, message, onClose, type, ...other } = props;
  const TypeIcon = variantIcon[type];

  return (
    <SnackbarContent
      classes={{
        root: classes.root,
        action: classes.action,
      }}
      aria-describedby='client-snackbar'
      message={
        <Box id='client-snackbar' display='flex' alignItems={title ? 'start' : 'center'}>
          {TypeIcon}
          <Box display='flex' flexDirection='column' justifyContent='center' pl={2}>
            {title ? (
              <React.Fragment>
                <Box mb={1} pr={1}>
                  <Text component='span' variant='title' grey={90}>
                    {title}
                  </Text>
                </Box>
                <Box>
                  <Text component='span' variant='small-copy' grey={70}>
                    {message}
                  </Text>
                </Box>
              </React.Fragment>
            ) : (
              // If no title is given, the message automatically becomes the title
              <Box pr={1}>
                <Text component='span' variant='title' grey={90}>
                  {message}
                </Text>
              </Box>
            )}
          </Box>
        </Box>
      }
      action={[
        <IconButton key='close' aria-label='Close' color='inherit' size='small' onClick={onClose}>
          <Icon color={{ primary: coreGreys[70] }} size='20px' name={IconName.close2} />
        </IconButton>,
      ]}
      {...other}
    />
  );
}

export default withStyles(styles)(NotificationContent);
