import React from 'react';
import { useTheme } from 'styled-components';

import { SVGIconProps } from 'Components/Icon/IconMap';

import { SharedTheme } from '../../Theme';

export function DeathIcon(props: SVGIconProps): React.ReactElement {
  const theme = useTheme() as SharedTheme;
  const primaryColor = props.color?.primary || theme.colors.icons.primary;
  const secondaryColor = props.color?.secondary || theme.colors.icons.secondary;
  return (
    <svg viewBox='0 0 48 48' fill='none' xmlns='http://www.w3.org/2000/svg' aria-hidden='true'>
      <path
        d='M44 24C44 35.0457 35.0457 44 24 44C12.9543 44 4 35.0457 4 24C4 12.9543 12.9543 4 24 4C35.0457 4 44 12.9543 44 24Z'
        fill='white'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M24 43C34.4934 43 43 34.4934 43 24C43 13.5066 34.4934 5 24 5C13.5066 5 5 13.5066 5 24C5 34.4934 13.5066 43 24 43ZM24 44C35.0457 44 44 35.0457 44 24C44 12.9543 35.0457 4 24 4C12.9543 4 4 12.9543 4 24C4 35.0457 12.9543 44 24 44Z'
        fill='#F6F6F6'
      />
      <path
        d='M35.4066 37.4832V19.0801C35.4776 16.4334 34.5609 13.8647 32.8578 11.9381C31.1547 10.0115 28.8043 8.88436 26.3227 8.80417H21.7807C19.299 8.88436 16.9487 10.0115 15.2455 11.9381C13.5424 13.8647 12.6257 16.4334 12.6967 19.0801V37.4832'
        fill={secondaryColor}
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M21.7547 8.00042C21.7633 8.00014 21.772 8 21.7806 8H26.3226C26.3313 8 26.34 8.00014 26.3486 8.00042C29.0603 8.08805 31.6164 9.31965 33.4603 11.4055C35.3003 13.4869 36.2842 16.2509 36.2108 19.0907V37.4832C36.2108 37.9273 35.8507 38.2873 35.4066 38.2873C34.9625 38.2873 34.6024 37.9273 34.6024 37.4832V19.0801C34.6024 19.0729 34.6025 19.0657 34.6027 19.0586C34.6684 16.6087 33.8192 14.2398 32.2553 12.4707C30.6958 10.7067 28.5563 9.68453 26.3093 9.60835H21.7939C19.547 9.68453 17.4075 10.7067 15.848 12.4707C14.2841 14.2398 13.4349 16.6087 13.5006 19.0586C13.5008 19.0657 13.5009 19.0729 13.5009 19.0801V37.4832C13.5009 37.9273 13.1408 38.2873 12.6967 38.2873C12.2525 38.2873 11.8925 37.9273 11.8925 37.4832V19.0907C11.8191 16.2509 12.803 13.4869 14.643 11.4055C16.4869 9.31965 19.043 8.08805 21.7547 8.00042Z'
        fill={primaryColor}
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M24.0517 18.0915C24.4959 18.0915 24.8559 18.4516 24.8559 18.8957V28.9873C24.8559 29.4314 24.4959 29.7915 24.0517 29.7915C23.6076 29.7915 23.2476 29.4314 23.2476 28.9873V18.8957C23.2476 18.4516 23.6076 18.0915 24.0517 18.0915Z'
        fill={primaryColor}
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M16.4818 18.0915C16.9259 18.0915 17.286 18.4516 17.286 18.8957V28.9873C17.286 29.4314 16.9259 29.7915 16.4818 29.7915C16.0377 29.7915 15.6776 29.4314 15.6776 28.9873V18.8957C15.6776 18.4516 16.0377 18.0915 16.4818 18.0915Z'
        fill={primaryColor}
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M15.6776 18.8957C15.6776 18.4516 16.0377 18.0915 16.4818 18.0915H17.9012C18.7592 18.0915 19.5707 18.4555 20.1605 19.0846C20.7488 19.7119 21.0709 20.5523 21.0709 21.4186C21.0709 22.2849 20.7488 23.1253 20.1605 23.7526C19.5707 24.3817 18.7592 24.7457 17.9012 24.7457H16.4818C16.0377 24.7457 15.6776 24.3856 15.6776 23.9415C15.6776 23.4974 16.0377 23.1373 16.4818 23.1373H17.9012C18.298 23.1373 18.6899 22.9697 18.9873 22.6525C19.2863 22.3336 19.4626 21.8905 19.4626 21.4186C19.4626 20.9467 19.2863 20.5036 18.9873 20.1847C18.6899 19.8675 18.298 19.6999 17.9012 19.6999H16.4818C16.0377 19.6999 15.6776 19.3398 15.6776 18.8957Z'
        fill={primaryColor}
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M17.0337 23.2407C17.4207 23.0229 17.9111 23.1602 18.1288 23.5473L20.9676 28.5931C21.1854 28.9802 21.0481 29.4705 20.661 29.6883C20.2739 29.906 19.7836 29.7688 19.5658 29.3817L16.7271 24.3359C16.5093 23.9488 16.6466 23.4585 17.0337 23.2407Z'
        fill={primaryColor}
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M27.8366 18.0915C28.2808 18.0915 28.6408 18.4516 28.6408 18.8957V28.9873C28.6408 29.4314 28.2808 29.7915 27.8366 29.7915C27.3925 29.7915 27.0325 29.4314 27.0325 28.9873V18.8957C27.0325 18.4516 27.3925 18.0915 27.8366 18.0915Z'
        fill={primaryColor}
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M27.0324 18.8957C27.0324 18.4516 27.3925 18.0915 27.8366 18.0915H28.7803C29.7638 18.0915 30.6957 18.5087 31.3743 19.2324C32.0512 19.9543 32.4232 20.9231 32.4232 21.9232C32.4232 22.9233 32.0512 23.892 31.3743 24.614C30.6957 25.3377 29.7638 25.7548 28.7803 25.7548H27.8341C27.3899 25.7548 27.0299 25.3948 27.0299 24.9507C27.0299 24.5065 27.3899 24.1465 27.8341 24.1465H28.7803C29.3026 24.1465 29.8149 23.9257 30.201 23.5139C30.5888 23.1003 30.8149 22.5289 30.8149 21.9232C30.8149 21.3174 30.5888 20.7461 30.201 20.3325C29.8149 19.9207 29.3026 19.6999 28.7803 19.6999H27.8366C27.3925 19.6999 27.0324 19.3398 27.0324 18.8957Z'
        fill={primaryColor}
      />
      <path
        d='M37.2966 39.0763C36.9092 38.5224 36.4185 38.06 35.8554 37.7181C35.2923 37.3762 34.6689 37.1622 34.0245 37.0897C33.3801 37.0172 32.7286 37.0876 32.111 37.2966C31.4934 37.5055 30.923 37.8485 30.4357 38.304C30.0715 36.7932 29.2466 35.4544 28.0907 34.4985C26.9349 33.5425 25.5137 33.0237 24.051 33.0237C22.5883 33.0237 21.1672 33.5425 20.0113 34.4985C18.8555 35.4544 18.0305 36.7932 17.6664 38.304C17.179 37.8483 16.6083 37.5051 15.9905 37.2961C15.3726 37.0871 14.7208 37.0168 14.0762 37.0896C13.4316 37.1624 12.8081 37.3767 12.245 37.7191C11.6819 38.0615 11.1913 38.5245 10.8042 39.079'
        fill='white'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M19.4989 33.8788C20.7949 32.8069 22.3962 32.2195 24.0511 32.2195C25.706 32.2195 27.3072 32.8069 28.6033 33.8788C29.5997 34.7028 30.3717 35.7764 30.8584 36.9899C31.1737 36.8048 31.5068 36.652 31.8533 36.5348C32.5821 36.2882 33.3522 36.2047 34.1145 36.2905C34.8767 36.3764 35.6116 36.6292 36.2728 37.0307C36.9338 37.432 37.5059 37.9725 37.9556 38.6154C38.2102 38.9794 38.1215 39.4808 37.7575 39.7353C37.3936 39.9899 36.8922 39.9012 36.6376 39.5372C36.3125 39.0723 35.9033 38.6879 35.4381 38.4054C34.973 38.1231 34.4612 37.9481 33.9346 37.8888C33.4081 37.8295 32.8753 37.8869 32.3688 38.0583C31.8621 38.2297 31.3905 38.5123 30.9848 38.8915C30.7758 39.0868 30.4787 39.1564 30.2047 39.0743C29.9307 38.9921 29.7209 38.7705 29.6539 38.4924C29.329 37.1441 28.595 35.959 27.5783 35.1182C26.5626 34.2782 25.3215 33.8279 24.0511 33.8279C22.7806 33.8279 21.5396 34.2782 20.5239 35.1182C19.5071 35.959 18.7732 37.1441 18.4482 38.4924C18.3812 38.7705 18.1714 38.9921 17.8973 39.0743C17.6233 39.1564 17.3262 39.0868 17.1172 38.8914C16.7115 38.512 16.2397 38.2293 15.7328 38.0579C15.2261 37.8865 14.6931 37.8292 14.1665 37.8887C13.6398 37.9482 13.1278 38.1235 12.6628 38.4062C12.1976 38.6891 11.7885 39.0739 11.4636 39.5393C11.2094 39.9035 10.7081 39.9926 10.3439 39.7384C9.97974 39.4842 9.89062 38.9828 10.1449 38.6187C10.5942 37.9751 11.1662 37.4339 11.8273 37.032C12.4885 36.6299 13.2235 36.3766 13.986 36.2905C14.7486 36.2044 15.5191 36.2877 16.2482 36.5343C16.595 36.6516 16.9283 36.8045 17.2438 36.9898C17.7305 35.7763 18.5025 34.7028 19.4989 33.8788Z'
        fill={primaryColor}
      />
    </svg>
  );
}
