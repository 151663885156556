import styled, { css } from 'styled-components';

export type HeaderColors = {
  backgroundColor: string;
  customerNameColor: string;
  referenceNumberColor: string;
  closeIconColor: string;
};

export const CustomerNameContainer = styled.div`
  font-weight: 400;
  font-size: 14px;
  line-height: 1.5em;
`;

export const RefNo = styled.div`
  width: 100%;
  font-size: 12px;
  line-height: 1.15em;
`;

export const Container = styled.div<HeaderColors>`
  padding: 13px 0;
  display: flex;
  align-items: center;
  ${(props) => {
    const { backgroundColor, customerNameColor, referenceNumberColor } = props;
    return css`
      background-color: ${backgroundColor};

      ${CustomerNameContainer} {
        color: ${customerNameColor};
      }

      ${RefNo} {
        color: ${referenceNumberColor};
      }
    `;
  }}
`;

export const CustomerContent = styled.div`
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  padding-left: 20px;
`;

export const CustomerName = styled.span`
  font-weight: 600;
`;

export const CloseButton = styled.button`
  margin-right: 18px;
  padding: 5px;
  background-color: transparent;
  border: none;
`;
