import { Select as SemanticSelect, SelectProps } from 'semantic-ui-react';
import styled, { css, StyledComponent } from 'styled-components';

import { colors, animation } from '../../Theme/Theme';

const selectStyle = css`
  min-width: 0;
  transition: all ${animation.speed.fast} ${animation.transition};

  border-style: solid;
  border-width: ${(props) => props.theme.metrics.borders.default};
  border-color: ${(props) => props.theme.colors.input.border.default};
  border-radius: ${(props) => props.theme.metrics.input.borderRadius};

  &:not(:disabled) {
    :focus {
      border-width: ${(props) => props.theme.metrics.borders.focus} !important;
      border-color: ${(props) => props.theme.colors.input.border.focus} !important;
    }
    :hover {
      border-width: ${(props) => props.theme.metrics.borders.default};
      border-color: ${(props) => props.theme.colors.input.border.hover};
    }
  }
`;

const selectDisabledStyle = css`
  opacity: 0.3;
  background-color: ${colors.background.disabled};
`;

export const Container = styled.div`
  text-align: left;
`;

export const SelectLabel = styled.label`
  color: ${colors.select.label};
`;

export const LabelSpacer = styled.div`
  margin-bottom: 0.5em;
`;

const baseSemanticSelectStyle = `
  .default.text {
    color: ${colors.select.label};
  }

  ${selectStyle}

  color: ${colors.select.default};
  display: flex;
  align-items: center;
  padding: 0;
  height: 50px;
  & > i {
    position: initial;
    padding: 0;
    margin: 0;
    margin-right: 16px;
  }
`;

export const StyledSelect: StyledComponent<typeof SemanticSelect, object, SelectProps> = styled(SemanticSelect)`
  &&&&& {
    ${baseSemanticSelectStyle}
    & > div[role='alert'] {
      flex: 1;
      margin-left: 15px;
    }
    & > input {
      padding: 1em;
    }
    &:disabled {
      ${selectDisabledStyle}
    }
  }
`;

export const SelectWrapper = styled.div`
  position: relative;
  width: 100%;

  &:after {
    font-family: Dropdown;
    font-size: 12px;
    content: '\\F0D7';
    position: absolute;
    right: 18px;
    top: 50%;
    transform: translateY(-50%);
    transition: all ${animation.speed.fast} ${animation.transition};
  }

  &:disabled {
    &:after {
      opacity: 0.3;
    }
  }

  &&&&& {
    display: flex;
  }
`;

// The `&&&` is needed to override semantic-ui form styling
// Also, the duplicated color with text-shadow rule is to remove the outline from selected Select in Firefox: https://stackoverflow.com/questions/3773430/remove-outline-from-select-box-in-ff#answer-11603104
export const StyledMobileSelect = styled.select`
  &&& {
    width: 100%;
    padding: 0 15px 0 10px;
    appearance: none;
    height: 50px;
    position: relative;
    opacity: 1;
    outline: none;

    color: ${colors.select.default};
    color: rgba(51, 51, 51, 0);
    text-shadow: 0 0 0 ${colors.select.default};

    ${selectStyle}

    &:disabled {
      ${selectDisabledStyle}
    }
  }
`;
