import { InfoSupplement, ValidationError } from '@breathelife/questionnaire-engine';

export type FieldProps = MuiFieldProps & CustomFieldProps & OptionInfo;

// Props that should be passed down to the Material UI field component
export type MuiFieldProps = {
  name: string;
  label?: string;
  disabled?: boolean;
};

// Props that should *not* be passed down to the Material UI field component

export type ValidationProps = {
  validationError?: ValidationError;
};

export type CustomFieldProps = ValidationProps & {
  id?: string;
  validationError?: ValidationError;
  value: unknown;
  onAnswerChange: (answer: unknown) => void;
  'data-testid'?: string;
  title?: string;
  subtitle?: string;
  isReadOnly?: boolean;
};

export type InputVariant = 'standard' | 'outlined';

export type OptionInfo = {
  info?: InfoSupplement;
};

export function getFieldLabel(fieldProps: Pick<FieldProps, 'title' | 'label'>): string {
  return fieldProps.title || fieldProps.label || '';
}
