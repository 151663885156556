import React from 'react';
import { useTheme } from 'styled-components';

import { SVGIconProps } from 'Components/Icon/IconMap';

import { SharedTheme } from '../../Theme';

export function Advisor(props: SVGIconProps): React.ReactElement {
  const theme = useTheme() as SharedTheme;

  const primaryColor = props.color?.primary || theme.colors.icons.primary;
  const secondaryColor = props.color?.secondary || theme.colors.icons.secondary;
  return (
    <svg fill='none' height='48' viewBox='0 0 48 48' width='48' xmlns='http://www.w3.org/2000/svg'>
      <path d='m44 24c0 11.0457-8.9543 20-20 20s-20-8.9543-20-20 8.9543-20 20-20 20 8.9543 20 20z' fill='#fff' />
      <path
        clipRule='evenodd'
        d='m24 43c10.4934 0 19-8.5066 19-19s-8.5066-19-19-19-19 8.5066-19 19 8.5066 19 19 19zm0 1c11.0457 0 20-8.9543 20-20s-8.9543-20-20-20-20 8.9543-20 20 8.9543 20 20 20z'
        fill='#f6f6f6'
        fillRule='evenodd'
      />
      <path
        d='m9 40.625c-.00014-3.2642 1.0339-6.4444 2.9538-9.0842 1.9198-2.6399 4.6267-4.6035 7.7321-5.6092l4.75 4.0059 4.75-4.0059c3.106 1.0051 5.8136 2.9685 7.734 5.6084 1.9205 2.6399 2.955 5.8204 2.9551 9.085'
        fill='#fff'
      />
      <path
        d='m9 40.625c-.00014-3.2642 1.0339-6.4444 2.9538-9.0842 1.9198-2.6399 4.6267-4.6035 7.7321-5.6092l4.75 4.0059 4.75-4.0059c3.106 1.0051 5.8136 2.9685 7.734 5.6084 1.9205 2.6399 2.955 5.8204 2.9551 9.085'
        stroke={primaryColor}
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeWidth='1.8'
      />
      <path d='m19.5 6.5 6-1.5 3.5 1 3 3.5.5 3-5 1-6-1.5-4-2.5z' fill={secondaryColor} />
      <g stroke={primaryColor} strokeLinecap='round' strokeLinejoin='round' strokeWidth='1.8'>
        <path
          d='m33.3944 28.047c-1.2845-.9164-2.7024-1.6298-4.2037-2.1154l-4.75 4.0059 5.3833 6.4521z'
          fill={secondaryColor}
        />
        <path
          d='m24.4375 29.9375-4.75-4.0059c-1.5006.4862-2.9178 1.1996-4.2021 2.1154l3.5751 8.3426z'
          fill={secondaryColor}
        />
        <path d='m28 40.6251 1.8145-4.2355' />
        <path d='m19.059 36.3896 1.816 4.2355' />
        <path d='m24.4375 21.625c4.5909 0 8.3125-3.7216 8.3125-8.3125 0-4.59087-3.7216-8.3125-8.3125-8.3125s-8.3125 3.72163-8.3125 8.3125c0 4.5909 3.7216 8.3125 8.3125 8.3125z' />
        <path d='m17.2745 9.09131c1.287 1.33529 2.83 2.39739 4.5369 3.12269 1.7068.7253 3.5424 1.0989 5.397 1.0985 1.8794.0009 3.7392-.3816 5.4656-1.1242' />
      </g>
    </svg>
  );
}
