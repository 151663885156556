import Box from '@material-ui/core/Box';
import _ from 'lodash';
import React from 'react';

import { TextButton } from '../Button/TextButton';
import { BaseModal } from '../Modal/BaseModal';
import { Title } from '../Typography';

type Props = {
  onClose: () => void;
  title?: string;
  isMobile?: boolean;
  steps: string[];
  onStepSelect: (stepId: string) => void;
};

export function DebugToolbarModal(props: Props): React.ReactElement {
  return (
    <BaseModal isMobile={Boolean(props.isMobile)} width='800px' onClose={props.onClose}>
      <Box p={2}>
        <Box mb={3}>{props.title && <Title level={3}>{props.title}</Title>}</Box>
        <ul>
          {props.steps.map((step) => (
            <li key={step}>
              <TextButton onClick={() => props.onStepSelect(step)}>{_.startCase(step)}</TextButton>
            </li>
          ))}
        </ul>
      </Box>
    </BaseModal>
  );
}
