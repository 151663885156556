import { Radio as SemanticRadio } from 'semantic-ui-react';
import styled from 'styled-components';

import { BaseCheckboxStyle } from '../CheckBox/Styles';

export const StyledRadio = styled(SemanticRadio)`
  sup {
    color: ${(props) => props.theme.colors.base.primary};
  }

  &&&&& {
    width: 100%;

    ${BaseCheckboxStyle}

    input:checked + label:before {
      border: 7px solid ${(props) => props.theme.colors.base.primary} !important;
    }
  }
`;
