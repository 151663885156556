import { TextFieldProps } from '@material-ui/core/TextField';
import React from 'react';
import NumberInput, { NumberFormatValues } from 'react-number-format';

import { FieldProps, getFieldLabel, InputVariant } from './Helpers/FieldHelper';
import { TextFieldMUI } from './TextInput';

type Props = TextFieldProps &
  Omit<FieldProps, 'onAnswerChange'> & {
    value?: string;
    onAnswerChange?: (answer: string) => void;
    onAnswerComplete?: (fieldId: string, answer: string | number, previousAnswer?: string | number | undefined) => void;
    inputVariant?: InputVariant;
    allowTextWrap?: boolean;
  };

export function PhoneInput(props: Props): React.ReactElement {
  const {
    validationError,
    name,
    required,
    value,
    label,
    title,
    subtitle,
    onAnswerChange,
    onAnswerComplete,
    onBlur,
    inputVariant,
    disabled,
    allowTextWrap,
  } = props;
  const labelText = getFieldLabel({ label, title });

  return (
    <NumberInput
      id={name}
      data-testid={props['data-testid']}
      name={name}
      type='tel'
      format='1-###-###-####'
      mask='_'
      customInput={TextFieldMUI}
      getInputRef={null}
      displayType='input'
      placeholder={props.placeholder || '###-###-####'}
      value={value as string}
      onValueChange={(value: NumberFormatValues) => onAnswerChange && onAnswerChange(value.formattedValue)}
      onBlur={onBlur}
      // TextField props
      label={required ? `${labelText} *` : labelText}
      disabled={disabled}
      validationError={validationError}
      onAnswerComplete={onAnswerComplete}
      inputVariant={inputVariant}
      subtitle={subtitle}
      allowTextWrap={allowTextWrap}
    />
  );
}
