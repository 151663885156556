import React from 'react';
import { useTheme } from 'styled-components';

import { SVGIconProps } from 'Components/Icon/IconMap';

import { SharedTheme } from '../../Theme';

export function GoodFinancialHealth(props: SVGIconProps): React.ReactElement {
  const theme = useTheme() as SharedTheme;
  return (
    <svg width='37' height='44' fill='none' viewBox='0 0 37 44' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M23 1.375A4.812 4.812 0 1 1 23 11a4.812 4.812 0 1 1 0-9.625zM32.625 16.5h-4.89a8.248 8.248 0 0 1-2.885-.52l-5.44-2.032a19.614 19.614 0 0 0-17.075 1.57 2.75 2.75 0 0 0 2.83 4.715 14.169 14.169 0 0 1 9.9-1.782L8.953 30.25H3.75a2.751 2.751 0 0 0 0 5.5h6.875a2.75 2.75 0 0 0 2.442-1.485l1.973-3.812 5.712 2.956.897 6.824a2.75 2.75 0 0 0 3.09 2.359 2.75 2.75 0 0 0 2.37-3.084l-.9-6.809a5.499 5.499 0 0 0-2.934-4.178l-5.709-2.951 2.796-5.403 2.567.957c1.536.577 3.164.874 4.806.876h4.89a2.751 2.751 0 0 0 0-5.5z'
        stroke={props.color?.primary || theme.colors.icons.primary}
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
}
