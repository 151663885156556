import React from 'react';
import { useTheme } from 'styled-components';

import { SVGIconProps } from 'Components/Icon/IconMap';

import { SharedTheme } from '../../Theme';

export function SmokingIcon(props: SVGIconProps): React.ReactElement {
  const theme = useTheme() as SharedTheme;
  const primaryColor = props.color?.primary || theme.colors.icons.primary;
  return (
    <svg viewBox='0 0 46 48' fill='none' xmlns='http://www.w3.org/2000/svg' aria-hidden='true'>
      <circle cx='23' cy='24' r='19.5' fill='white' stroke='#F6F6F6' />
      <rect x='28.6817' y='27.8116' width='10.4164' height='7.5756' fill={theme.colors.icons.secondary} />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M10.3289 27.8117C10.3289 27.3883 10.6721 27.0451 11.0955 27.0451H37.069C37.8157 27.0451 38.5491 27.3038 39.1043 27.7895C39.6627 28.2781 40 28.9644 40 29.7056V33.4934C40 34.2345 39.6627 34.9209 39.1043 35.4095C38.5491 35.8952 37.8157 36.1539 37.069 36.1539H11.0955C10.6721 36.1539 10.3289 35.8107 10.3289 35.3873V27.8117ZM11.8621 28.5783V34.6207H37.069C37.4703 34.6207 37.838 34.4803 38.0947 34.2557C38.3481 34.0339 38.4669 33.7568 38.4669 33.4934V29.7056C38.4669 29.4421 38.3481 29.1651 38.0947 28.9433C37.838 28.7187 37.4703 28.5783 37.069 28.5783H11.8621Z'
        fill={primaryColor}
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M28.4112 27.0451C28.8345 27.0451 29.1777 27.3883 29.1777 27.8117V35.3873C29.1777 35.8107 28.8345 36.1539 28.4112 36.1539C27.9878 36.1539 27.6446 35.8107 27.6446 35.3873V27.8117C27.6446 27.3883 27.9878 27.0451 28.4112 27.0451Z'
        fill={primaryColor}
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M6.76654 27.1353C7.1401 27.1353 7.44293 27.4381 7.44293 27.8116V35.3872C7.44293 35.7608 7.1401 36.0636 6.76654 36.0636C6.39298 36.0636 6.09015 35.7608 6.09015 35.3872V27.8116C6.09015 27.4381 6.39298 27.1353 6.76654 27.1353Z'
        fill={primaryColor}
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M17.5889 10C18.0122 10 18.3554 10.3432 18.3554 10.7666C18.3554 12.5123 17.562 14.1621 16.1918 15.3611C14.8248 16.5571 12.9903 17.2149 11.0955 17.2149C10.1201 17.2149 9.2019 17.5548 8.53928 18.1346C7.87993 18.7115 7.53316 19.4703 7.53316 20.2361C7.53316 20.6594 7.18995 21.0027 6.76658 21.0027C6.34321 21.0027 6 20.6594 6 20.2361C6 18.9926 6.56539 17.8246 7.52969 16.9808C8.49073 16.1399 9.77469 15.6817 11.0955 15.6817C12.6449 15.6817 14.1137 15.1422 15.1822 14.2072C16.2475 13.2751 16.8223 12.0346 16.8223 10.7666C16.8223 10.3432 17.1655 10 17.5889 10Z'
        fill={primaryColor}
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M24.0822 10C24.5056 10 24.8488 10.3432 24.8488 10.7666C24.8488 14.0192 23.3713 17.1142 20.7833 19.3786C18.1986 21.6402 14.7125 22.8966 11.0955 22.8966C10.6721 22.8966 10.3289 22.5533 10.3289 22.13C10.3289 21.7066 10.6721 21.3634 11.0955 21.3634C14.3671 21.3634 17.4874 20.2253 19.7737 18.2248C22.0567 16.2272 23.3157 13.5415 23.3157 10.7666C23.3157 10.3432 23.6589 10 24.0822 10Z'
        fill={primaryColor}
      />
    </svg>
  );
}
