import React from 'react';
import { useTheme } from 'styled-components';

import { SVGIconProps } from 'Components/Icon/IconMap';

import { SharedTheme } from '../../Theme';

export function PoorFinancialHealth(props: SVGIconProps): React.ReactElement {
  const theme = useTheme() as SharedTheme;
  return (
    <svg fill='none' height='40' width='40' viewBox='0 0 40 40' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M11.264 33.69a2.502 2.502 0 0 1 2.14-4.523l10.166 4.818a2.5 2.5 0 1 1-2.141 4.518zm8.75-32.44a5 5 0 1 1 .001 10 5 5 0 0 1 0-10zm-2.514 20v1.25A2.5 2.5 0 0 1 15 25h-1.25a5.108 5.108 0 0 1-5-5 5.108 5.108 0 0 1 5-5h12.5a5.108 5.108 0 0 1 5 5 5.108 5.108 0 0 1-5 5H25a2.5 2.5 0 0 1-2.5-2.5v-1.25m5.218 12.375a2.502 2.502 0 0 0 1.635-3.135 2.502 2.502 0 0 0-3.135-1.635l-.06.02-1.167.427-1.3.53'
        stroke={props.color?.primary || theme.colors.icons.primary}
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeWidth='2'
      />
    </svg>
  );
}
