import React from 'react';
import styled from 'styled-components';

import { colors, fontSize } from '../../Theme/Theme';
import { ButtonProps } from '../Button/Button';
import { buttonStyle } from '../Button/Styles';

export const Link = styled.a`
  ${buttonStyle}
  display: inline-flex;

  font-size: ${fontSize.label.medium};
  font-family: inherit;

  &:hover,
  &:active,
  &:focus {
    color: ${colors.anchor.focus};
  }
`;

type Props = ButtonProps & {
  href: string;
};

export function LinkButton(props: Props): React.ReactElement {
  const handleClick = (e: React.MouseEvent): void => {
    if (props.disabled) {
      return e.preventDefault();
    }
    props.onClick && props.onClick();
  };

  return (
    <Link {...props} href={props.href} onClick={handleClick}>
      {props.children}
    </Link>
  );
}
