import Box from '@material-ui/core/Box';
import styled from 'styled-components';

export const DesktopContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: ${(props) => props.theme.colors.grey[10]};
`;

export const LeftDesktopContent = styled(Box)`
  flex-basis: 50%;
  height: 100%;
`;

export const RightDesktopContent = styled.div`
  flex-basis: 50%;
  height: 100%;
  background-color: ${(props) => props.theme.colors.grey[0]};
  overflow-y: auto;
  height: 710px;
`;

export const ConfirmationIconContainer = styled.div`
  svg {
    padding: 8px;
    border-radius: 50%;
    border: 1px solid ${(props) => props.theme.colors.grey[10]};

    path {
      fill: ${(props) => props.theme.colors.primary[10]};
    }

    g {
      path {
        fill: ${(props) => props.theme.colors.primary.default};
      }
    }
  }
`;

export const ModalContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  overflow-y: auto;
  overflow-x: hidden;
  -webkit-overflow-scrolling: touch;

  @media (min-width: ${(props) => props.theme.breakpoints.tablet.min}) {
    flex-direction: row;
    overflow-y: unset;
  }
`;

export const SecurityDisclosure = styled.span`
  white-space: pre-wrap;
  font-size: 11px;
  color: ${(props) => props.theme.colors.grey[60]};
  @media (min-width: ${(props) => props.theme.breakpoints.tablet.min}) {
    font-size: 12px;
  }
`;
