import { TableCell, TableRow } from '@material-ui/core';
import styled from 'styled-components';

export const JsonTableRow = styled(TableRow)`
  &&&& {
    white-space: break-spaces;
    box-shadow: none;
    border-left: none;
  }
`;

export const TableIndexCell = styled(TableCell)`
  &&&& {
    border-bottom: none;
    background-color: #f6f6f6;
    width: 12px;
    padding: 6px 12px;
  }
`;

export const TableJsonCell = styled(TableCell)`
  &&&& {
    border-bottom: none;
    background-color: #fbfbfb;
  }
`;
