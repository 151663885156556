import React from 'react';

export function FolderIcon(): React.ReactElement {
  return (
    <svg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M8 4.50006C7.89506 4.50001 7.79221 4.47412 7.70296 4.42528C7.61372 4.37645 7.5416 4.3066 7.4947 4.22356L6.92408 2.77656C6.87717 2.69352 6.80505 2.62367 6.71581 2.57484C6.62656 2.526 6.52371 2.50011 6.41877 2.50006H2.06522C1.91531 2.50006 1.77155 2.55274 1.66555 2.64651C1.55955 2.74028 1.5 2.86745 1.5 3.00006V13.0001C1.5 13.1327 1.55955 13.2598 1.66555 13.3536C1.77155 13.4474 1.91531 13.5001 2.06522 13.5001H13.9348C14.0847 13.5001 14.2285 13.4474 14.3345 13.3536C14.4405 13.2598 14.5 13.1327 14.5 13.0001V5.00006C14.5 4.86745 14.4405 4.74028 14.3345 4.64651C14.2285 4.55274 14.0847 4.50006 13.9348 4.50006H8Z'
        fill='#EEEEEE'
      />
      <path
        d='M8.11878 4.49988C8.00641 4.49982 7.89627 4.47134 7.80071 4.41762C7.70515 4.36391 7.62793 4.28707 7.5777 4.19573L6.99254 2.80403C6.94231 2.71268 6.86509 2.63585 6.76953 2.58213C6.67396 2.52841 6.56383 2.49993 6.45146 2.49988H2.10524C1.94472 2.49988 1.79077 2.55782 1.67727 2.66097C1.56377 2.76411 1.5 2.90401 1.5 3.04988V12.9499C1.5 13.0957 1.56377 13.2356 1.67727 13.3388C1.79077 13.4419 1.94472 13.4999 2.10524 13.4999H3.211L13 4.49988H8.11878Z'
        fill='white'
      />
      <path
        d='M8.05433 4.49706C7.94603 4.49701 7.83988 4.4668 7.74778 4.40983C7.65567 4.35285 7.58125 4.27136 7.53283 4.17448L6.97983 2.82643C6.93142 2.72955 6.857 2.64806 6.76489 2.59108C6.67278 2.53411 6.56664 2.5039 6.45833 2.50385H2.08333C1.92862 2.50385 1.78025 2.5653 1.67085 2.6747C1.56146 2.7841 1.5 2.93247 1.5 3.08718V12.9171C1.5 13.0718 1.56146 13.2202 1.67085 13.3296C1.78025 13.439 1.92862 13.5004 2.08333 13.5004H13.9196C14.0743 13.5004 14.2226 13.439 14.332 13.3296C14.4414 13.2202 14.5029 13.0718 14.5029 12.9171V5.0804C14.5029 4.92569 14.4414 4.77731 14.332 4.66792C14.2226 4.55852 14.0743 4.49706 13.9196 4.49706L8.05433 4.49706Z'
        stroke='#757575'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
}
