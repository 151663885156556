import React from 'react';
import { useTheme } from 'styled-components';

import { SVGIconProps } from 'Components/Icon/IconMap';

import { SharedTheme } from '../../Theme';

export function Update(props: SVGIconProps): React.ReactElement {
  const theme = useTheme() as SharedTheme;
  return (
    <svg fill='none' height='48' viewBox='0 0 48 48' width='48' xmlns='http://www.w3.org/2000/svg'>
      <circle cx='24' cy='24' fill='#fff' r='19.5' stroke='#f2f2f2' />
      <path
        d='m28.3335 10.103c-2.6633-.79589-5.4999-.80407-8.1677-.0236-2.6679.7805-5.0528 2.3163-6.8672 4.4221-1.8143 2.1059-2.9806 4.6917-3.35794 7.4456-.37738 2.7539.0502 5.5582 1.23114 8.0745l-4.6718 9.4817 10.6904-2.9199c.943.5403 1.9447.9709 2.9855 1.2834 3.6604 1.0121 7.5721.5444 10.8907-1.3021 3.3186-1.8466 5.7781-4.924 6.8477-8.568 1.0696-3.6441.6635-7.5626-1.1306-10.9098-1.7942-3.3473-4.8325-5.8549-8.4593-6.9817z'
        fill={props.color?.secondary || theme.colors.icons.secondary}
      />
      <g fill={props.color?.primary || theme.colors.icons.primary}>
        <path
          clipRule='evenodd'
          d='m19.913 9.21563c2.8346-.82926 5.8485-.82057 8.6782.02502.0345.01031.068.02256.1004.0366 3.8081 1.21165 6.9961 3.85985 8.8853 7.38445 1.9057 3.5555 2.3371 7.7177 1.201 11.5884-1.1362 3.8708-3.7487 7.1396-7.2737 9.101-3.5251 1.9615-7.68 2.4582-11.5681 1.3831l-.019-.0054c-.9858-.296-1.9385-.6916-2.8435-1.1805l-10.33648 2.8232c-.34154.0933-.70605-.0224-.93137-.2955-.22531-.2731-.26957-.6529-.11309-.9705l4.48404-9.1006c-1.11725-2.5692-1.50885-5.3988-1.12772-8.18.40097-2.926 1.64012-5.6734 3.56782-7.9108s4.4617-3.8692 7.2962-4.69847zm8.0695 1.72217c-2.4697-.7187-5.0941-.7172-7.564.0054-2.5011.7317-4.7371 2.1716-6.4381 4.1458-1.7009 1.9742-2.7943 4.3985-3.1481 6.9803s.0471 5.2108 1.1542 7.5699c.1163.2477.1135.5347-.0074.7801l-3.7886 7.6893 8.7628-2.3934c.2307-.063.477-.0316.6845.0873.8805.5045 1.8157.9069 2.7875 1.1994 3.4299.9458 7.0944.5064 10.2037-1.2237 3.1122-1.7317 5.4187-4.6176 6.4217-8.035 1.0031-3.4174.6223-7.0921-1.0603-10.2311-1.6825-3.1391-4.5318-5.4907-7.933-6.5474-.0255-.0079-.0505-.0169-.0749-.0269z'
          fillRule='evenodd'
        />
        <path d='m24.5783 29.3176c-.1679 0-.332.0498-.4716.1431-.1396.0932-.2484.2258-.3126.3809s-.081.3258-.0483.4904c.0328.1647.1136.3159.2323.4346s.2699.1996.4346.2323c.1647.0328.3353.016.4904-.0483.1551-.0642.2877-.173.381-.3126.0932-.1396.143-.3037.143-.4716 0-.2251-.0894-.441-.2486-.6002-.1592-.1591-.3751-.2486-.6002-.2486z' />
        <path
          clipRule='evenodd'
          d='m23.4956 28.5461c.3205-.2142.6973-.3285 1.0827-.3285.5169 0 1.0126.2053 1.378.5708.3655.3655.5708.8612.5708 1.378 0 .3855-.1143.7622-.3284 1.0827-.2142.3205-.5185.5703-.8746.7178s-.7479.1861-1.126.1109c-.378-.0752-.7252-.2608-.9978-.5334-.2725-.2725-.4581-.6198-.5333-.9978s-.0366-.7699.1109-1.126c.1475-.356.3972-.6604.7177-.8745zm1.1788 1.8524c.0152-.0063.0297-.014.0435-.0232.0275-.0184.051-.042.0693-.0693.0091-.0136.0169-.0282.0232-.0434.0127-.0307.0191-.0633.0191-.0962 0-.0164-.0016-.0328-.0048-.049-.0097-.0487-.0336-.0935-.0688-.1286-.0351-.0351-.0799-.0591-.1286-.0687-.0162-.0033-.0326-.0049-.049-.0049-.0328 0-.0655.0065-.0961.0191-.0153.0064-.0298.0142-.0434.0233-.0273.0182-.0509.0417-.0693.0693-.0092.0137-.017.0283-.0233.0434-.0125.0303-.0191.063-.0191.0961 0 .0166.0017.033.0049.049.0096.0484.0333.0933.0687.1286.0354.0354.0802.0592.1286.0688.016.0032.0324.0048.049.0048.0332 0 .0658-.0065.0961-.0191z'
          fillRule='evenodd'
        />
        <path
          clipRule='evenodd'
          d='m24.5154 14.7866c.6903 0 1.25.5597 1.25 1.25v9.4118c0 .6903-.5597 1.25-1.25 1.25-.6904 0-1.25-.5597-1.25-1.25v-9.4118c0-.6903.5596-1.25 1.25-1.25z'
          fillRule='evenodd'
        />
      </g>
    </svg>
  );
}
