import React from 'react';
import { Sticky } from 'react-sticky';

import { LegalLink, LegalMenu } from '../LegalMenu/LegalMenu';
import { ProgressBar } from '../ProgressBar/ProgressBar';
import {
  ChildrenContainer,
  Content,
  InfoViewContainer,
  LogoContainer,
  MinimalMobileHeaderContainer,
  MobileHeaderContainer,
  ProgressBarContainer,
  SectionTitle,
  TitleContainer,
} from './TransactionFlowStyles';

type Props = {
  infoView?: React.ReactNode;
  logo: React.ReactNode;
  collapseHeader?: boolean;
  children: React.ReactNode;
  progress?: number;
  legalLinks: LegalLink[];
  title?: string;
  showMinimalHeader?: boolean;
};

const renderProgressBar = (progress: number): React.ReactElement => (
  <ProgressBarContainer>
    <ProgressBar progress={progress} />
  </ProgressBarContainer>
);

export function TransactionFlowMobileHeader({
  collapseHeader = false,
  showMinimalHeader = false,
  ...props
}: Props): React.ReactElement {
  const height = !props.title ? { height: '80px' } : {};

  const baseHeaderContent = (
    <Content>
      <LogoContainer>{props.logo}</LogoContainer>
      <ChildrenContainer>{props.children}</ChildrenContainer>
      <LegalMenu links={props.legalLinks} />
    </Content>
  );

  if (showMinimalHeader) {
    return (
      <Sticky>
        {({ style }) => (
          <MinimalMobileHeaderContainer style={{ ...style, ...height }}>
            {baseHeaderContent}
          </MinimalMobileHeaderContainer>
        )}
      </Sticky>
    );
  }
  return (
    <React.Fragment>
      <Sticky>
        {({ style }) => (
          <MobileHeaderContainer style={{ ...style, ...height }}>
            {baseHeaderContent}
            {props.title && (
              <TitleContainer>
                <SectionTitle>{props.title}</SectionTitle>
              </TitleContainer>
            )}
            {(!props.title || collapseHeader) && props.progress && renderProgressBar(props.progress)}
          </MobileHeaderContainer>
        )}
      </Sticky>
      {props.title && (
        <InfoViewContainer>
          {props.infoView}
          {!collapseHeader && props.progress && renderProgressBar(props.progress)}
        </InfoViewContainer>
      )}
    </React.Fragment>
  );
}
