import Autocomplete, { RenderOptionState } from '@material-ui/lab/Autocomplete';
import React, { useCallback } from 'react';
import * as yup from 'yup';

import { IconName } from '@breathelife/types';

import { Icon } from '../Icon/Icon';
import { StyledAutocompleteTextField } from './Styles';
import { AutocompleteOption } from './options';

type Props<T extends AutocompleteOption> = {
  options: T[];
  option: T | null;
  id?: string;
  onChange: (value: T | null) => void;
  label?: string;
  validationError?: yup.ValidationError;
  disableClearable?: boolean;
  onClear?: () => void;
  groupBy?: (option: T) => string;
  disabled?: boolean;
  required?: boolean;
  startIconName?: IconName;
  renderOption?: (option: T, state: RenderOptionState) => React.ReactNode;
};

export function AutoComplete<T extends AutocompleteOption>({
  options,
  option,
  id,
  onChange,
  label,
  validationError,
  onClear,
  disableClearable = true,
  groupBy,
  required,
  disabled = false,
  renderOption,
  startIconName,
}: Props<T>): React.ReactElement {
  const handleChange = useCallback(
    (_: React.ChangeEvent<any>, selectedOption: T | null) => {
      if (disableClearable && selectedOption === null) return;
      onChange(selectedOption);
    },
    [onChange]
  );

  return (
    <Autocomplete
      id={id ?? 'autocomplete-input'}
      options={options}
      value={option}
      onChange={handleChange}
      getOptionLabel={(option) => option.label}
      getOptionSelected={(option, selected) => option.value === selected.value}
      disableClearable={disableClearable}
      groupBy={groupBy}
      disabled={disabled}
      onInputChange={(_event, _newValue, reason) => {
        if (reason === 'reset') {
          onClear && onClear();
        }
      }}
      renderOption={renderOption}
      renderInput={(inputProps) => (
        <StyledAutocompleteTextField
          {...{
            ...inputProps,
            InputProps: {
              ...inputProps.InputProps,
              startAdornment: startIconName && <Icon name={startIconName} size='20px' />,
            },
          }}
          label={label}
          inputVariant='outlined'
          error={!!validationError}
          required={required}
          helperText={validationError?.message}
        />
      )}
    />
  );
}
