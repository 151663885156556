import React from 'react';
import { Helmet } from 'react-helmet';

import { Language } from '@breathelife/types';

type Props = {
  title: string;
  text?: string;
  children?: React.ReactNode;
  defaultCarrierLanguage?: Language;
};

function getLanguageAttribute(defaultCarrierLanguage?: Language): Language {
  const currentLanguage = localStorage.getItem('locale') as Language | undefined;
  const fallbackLanguage = defaultCarrierLanguage ?? Language.en;

  if (!currentLanguage || !Object.values(Language).includes(currentLanguage)) return fallbackLanguage;

  return currentLanguage;
}

export function TitleHelmet(props: Props): React.ReactElement {
  return (
    <Helmet htmlAttributes={{ lang: getLanguageAttribute(props.defaultCarrierLanguage) }}>
      <title>
        {props.text ? `${props.text} - ` : ''}
        {props.title}
      </title>
      {props.children}
    </Helmet>
  );
}
