import React from 'react';
import { useTheme } from 'styled-components';

import { SVGIconProps } from 'Components/Icon/IconMap';

import { SharedTheme } from '../../Theme';

export function CalculatorEducationIcon(props: SVGIconProps): React.ReactElement {
  const theme = useTheme() as SharedTheme;
  const primaryColor = props.color?.primary || theme.colors.icons.primary;
  const secondaryColor = props.color?.secondary || theme.colors.icons.secondary;

  return (
    <svg viewBox='0 0 48 48' fill='none' xmlns='http://www.w3.org/2000/svg' aria-hidden='true'>
      <circle cx='24' cy='24' r='19.5' fill='white' stroke='#F6F6F6' />
      <path
        d='M29.3213 7.42239H10.8483C9.71474 7.42239 8.79578 8.34135 8.79578 9.47495V36.1582C8.79578 37.2918 9.71474 38.2107 10.8483 38.2107H29.3213C30.4549 38.2107 31.3739 37.2918 31.3739 36.1582V9.47495C31.3739 8.34135 30.4549 7.42239 29.3213 7.42239Z'
        fill='white'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M10.8484 8.21819C10.1543 8.21819 9.59163 8.78085 9.59163 9.47493V36.1582C9.59163 36.8522 10.1543 37.4149 10.8484 37.4149H29.3214C30.0155 37.4149 30.5781 36.8522 30.5781 36.1582V9.47493C30.5781 8.78085 30.0155 8.21819 29.3214 8.21819H10.8484ZM8 9.47493C8 7.90182 9.27526 6.62656 10.8484 6.62656H29.3214C30.8945 6.62656 32.1698 7.90182 32.1698 9.47493V36.1582C32.1698 37.7313 30.8945 39.0065 29.3214 39.0065H10.8484C9.27526 39.0065 8 37.7313 8 36.1582V9.47493Z'
        fill={primaryColor}
      />
      <path
        d='M26.2426 11.5275H13.9273C13.3605 11.5275 12.901 11.987 12.901 12.5538V16.6589C12.901 17.2257 13.3605 17.6852 13.9273 17.6852H26.2426C26.8094 17.6852 27.2689 17.2257 27.2689 16.6589V12.5538C27.2689 11.987 26.8094 11.5275 26.2426 11.5275Z'
        fill={secondaryColor}
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M13.9271 12.2765C13.774 12.2765 13.6499 12.4006 13.6499 12.5538V16.6589C13.6499 16.812 13.774 16.9361 13.9271 16.9361H26.2425C26.3956 16.9361 26.5198 16.812 26.5198 16.6589V12.5538C26.5198 12.4006 26.3956 12.2765 26.2425 12.2765H13.9271ZM12.1519 12.5538C12.1519 11.5733 12.9467 10.7785 13.9271 10.7785H26.2425C27.2229 10.7785 28.0178 11.5733 28.0178 12.5538V16.6589C28.0178 17.6393 27.2229 18.4341 26.2425 18.4341H13.9271C12.9467 18.4341 12.1519 17.6393 12.1519 16.6589V12.5538Z'
        fill={primaryColor}
      />
      <path
        d='M14.5538 30.9692C14.8316 30.9692 15.1031 31.0516 15.334 31.2059C15.565 31.3602 15.745 31.5795 15.8513 31.8362C15.9576 32.0928 15.9854 32.3751 15.9312 32.6476C15.877 32.92 15.7433 33.1702 15.5468 33.3666C15.3504 33.563 15.1002 33.6968 14.8278 33.751C14.5554 33.8052 14.273 33.7774 14.0164 33.6711C13.7597 33.5648 13.5404 33.3848 13.3861 33.1538C13.2318 32.9229 13.1494 32.6513 13.1494 32.3736C13.1494 32.0011 13.2974 31.6439 13.5607 31.3805C13.8241 31.1172 14.1813 30.9692 14.5538 30.9692Z'
        fill={primaryColor}
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M25.7889 26.2879C25.4164 26.2879 25.0592 26.4359 24.7959 26.6993C24.5325 26.9626 24.3845 27.3199 24.3845 27.6923C24.3845 27.9701 24.4669 28.2416 24.6212 28.4725C24.7755 28.7035 24.9949 28.8835 25.2515 28.9898C25.5081 29.0961 25.7905 29.1239 26.0629 29.0697C26.3353 29.0155 26.5855 28.8818 26.782 28.6854C26.9784 28.489 27.1121 28.2387 27.1663 27.9663C27.2205 27.6939 27.1927 27.4115 27.0864 27.1549C26.9801 26.8983 26.8001 26.6789 26.5691 26.5246C26.3382 26.3703 26.0667 26.2879 25.7889 26.2879ZM25.7889 27.6923L25.7889 27.6923L25.7889 27.6923H25.7889H25.7889Z'
        fill={primaryColor}
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M20.1713 26.2879C19.7989 26.2879 19.4417 26.4359 19.1783 26.6993C18.9149 26.9626 18.767 27.3199 18.767 27.6923C18.767 27.9701 18.8493 28.2416 19.0036 28.4725C19.158 28.7035 19.3773 28.8835 19.6339 28.9898C19.8905 29.0961 20.1729 29.1239 20.4453 29.0697C20.7177 29.0155 20.968 28.8818 21.1644 28.6854C21.3608 28.489 21.4946 28.2387 21.5487 27.9663C21.6029 27.6939 21.5751 27.4115 21.4688 27.1549C21.3625 26.8983 21.1825 26.6789 20.9516 26.5246C20.7206 26.3703 20.4491 26.2879 20.1713 26.2879ZM20.1713 27.6923L20.1714 27.6923L20.1713 27.6923H20.1714H20.1713Z'
        fill={primaryColor}
      />
      <path
        d='M14.5538 26.2879C14.8316 26.2879 15.1031 26.3703 15.334 26.5246C15.565 26.6789 15.745 26.8983 15.8513 27.1549C15.9576 27.4115 15.9854 27.6939 15.9312 27.9663C15.877 28.2387 15.7433 28.489 15.5468 28.6854C15.3504 28.8818 15.1002 29.0155 14.8278 29.0697C14.5554 29.1239 14.273 29.0961 14.0164 28.9898C13.7597 28.8835 13.5404 28.7035 13.3861 28.4726C13.2318 28.2416 13.1494 27.9701 13.1494 27.6923C13.1494 27.3198 13.2974 26.9626 13.5607 26.6993C13.8241 26.4359 14.1813 26.2879 14.5538 26.2879Z'
        fill={primaryColor}
      />
      <path
        d='M25.7889 21.6067C26.0667 21.6067 26.3382 21.689 26.5691 21.8433C26.8001 21.9977 26.9801 22.217 27.0864 22.4736C27.1927 22.7302 27.2205 23.0126 27.1663 23.285C27.1121 23.5574 26.9784 23.8077 26.782 24.0041C26.5855 24.2005 26.3353 24.3342 26.0629 24.3884C25.7905 24.4426 25.5081 24.4148 25.2515 24.3085C24.9949 24.2022 24.7755 24.0222 24.6212 23.7913C24.4669 23.5603 24.3845 23.2888 24.3845 23.011C24.3845 22.6386 24.5325 22.2814 24.7959 22.018C25.0592 21.7546 25.4164 21.6067 25.7889 21.6067Z'
        fill={primaryColor}
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M20.1713 21.6067C19.7989 21.6067 19.4417 21.7546 19.1783 22.018C18.9149 22.2814 18.767 22.6386 18.767 23.011C18.767 23.2888 18.8493 23.5603 19.0036 23.7913C19.158 24.0222 19.3773 24.2022 19.6339 24.3085C19.8905 24.4148 20.1729 24.4426 20.4453 24.3884C20.7177 24.3342 20.968 24.2005 21.1644 24.0041C21.3608 23.8077 21.4946 23.5574 21.5487 23.285C21.6029 23.0126 21.5751 22.7302 21.4688 22.4736C21.3625 22.217 21.1825 21.9977 20.9516 21.8433C20.7206 21.689 20.4491 21.6067 20.1713 21.6067Z'
        fill={primaryColor}
      />
      <path
        d='M14.5538 21.6067C14.8316 21.6067 15.1031 21.689 15.334 21.8433C15.565 21.9977 15.745 22.217 15.8513 22.4736C15.9576 22.7302 15.9854 23.0126 15.9312 23.285C15.877 23.5574 15.7433 23.8077 15.5468 24.0041C15.3504 24.2005 15.1002 24.3342 14.8278 24.3884C14.5554 24.4426 14.273 24.4148 14.0164 24.3085C13.7597 24.2022 13.5404 24.0222 13.3861 23.7913C13.2318 23.5603 13.1494 23.2888 13.1494 23.011C13.1494 22.6386 13.2974 22.2814 13.5607 22.018C13.8241 21.7546 14.1813 21.6067 14.5538 21.6067Z'
        fill={primaryColor}
      />
      <path
        d='M20.0309 33.2858V39.2057'
        stroke={primaryColor}
        strokeWidth='1.7'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M24.7404 35.0425V39.9325C24.7391 40.169 24.8091 40.4003 24.9413 40.5963C25.0735 40.7923 25.2617 40.944 25.4813 41.0314L30.1908 42.9152C30.4714 43.0283 30.7847 43.0283 31.0652 42.9152L35.7748 41.0314C35.9943 40.9439 36.1823 40.7922 36.3142 40.5962C36.4461 40.4001 36.5158 40.1688 36.5142 39.9325V35.0425'
        fill={secondaryColor}
      />
      <path
        d='M24.7404 35.0425V39.9325C24.7391 40.169 24.8091 40.4003 24.9413 40.5963C25.0735 40.7923 25.2617 40.944 25.4813 41.0314L30.1908 42.9152C30.4714 43.0283 30.7847 43.0283 31.0652 42.9152L35.7748 41.0314C35.9943 40.9439 36.1823 40.7922 36.3142 40.5962C36.4461 40.4001 36.5158 40.1688 36.5142 39.9325V35.0425'
        stroke={primaryColor}
        strokeWidth='1.7'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M30.6273 28.9562L20.0309 33.3188L30.6273 37.1978L41.2237 33.3188L30.6273 28.9562Z'
        fill={secondaryColor}
        stroke={primaryColor}
        strokeWidth='1.7'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
}
