import Box from '@material-ui/core/Box';
import React from 'react';

import { Language } from '@breathelife/types';

import { DayOfTheWeek, weekdays } from '../../ScheduleTimeTable/Weekdays';
import { Label } from '../../Typography';
import { OpeningHoursType } from './types';

type Props = {
  label: string;
  language: Language;
  openingHours: OpeningHoursType;
};

export function OpeningHours({ label, openingHours, language }: Props): React.ReactElement {
  return (
    <Box my={4}>
      <Label>{label}</Label>
      {(Object.keys(openingHours) as DayOfTheWeek[]).map((day: DayOfTheWeek, index: number) => {
        const weekdayInfo = weekdays[day];
        return (
          <Box mt={1} key={index}>
            <strong>{weekdayInfo.long[language]}:</strong> {openingHours[day]}
          </Box>
        );
      })}
    </Box>
  );
}
