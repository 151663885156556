import React from 'react';
import { useTheme } from 'styled-components';

import { SVGIconProps } from 'Components/Icon/IconMap';

import { SharedTheme } from '../../Theme';

export function ProducerWrenchIcon(props: SVGIconProps): React.ReactElement {
  const theme = useTheme() as SharedTheme;
  const primaryColor = props.color?.primary || theme.colors.icons.primary;
  const secondaryColor = props.color?.secondary || theme.colors.icons.secondary;
  return (
    <svg viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M9.91075 7.97507L2.0125 15.8606C1.74854 16.113 1.58181 16.4502 1.54149 16.8131C1.50118 17.1761 1.58986 17.5417 1.792 17.8458C1.91828 18.0239 2.08191 18.1723 2.27147 18.2806C2.46102 18.3889 2.67193 18.4545 2.88947 18.4728C3.10701 18.4912 3.32594 18.4619 3.53096 18.3868C3.73598 18.3118 3.92216 18.193 4.0765 18.0386C4.0765 18.0386 12.0265 10.0886 12.0197 10.0833C12.8133 10.4654 13.7016 10.6061 14.5744 10.4882C15.4472 10.3702 16.2662 9.99876 16.9299 9.41977C17.5936 8.84079 18.0729 8.07976 18.3082 7.23102C18.5435 6.38229 18.5246 5.48315 18.2537 4.64507C18.234 4.58319 18.1988 4.52742 18.1513 4.48307C18.1039 4.43873 18.0458 4.4073 17.9828 4.3918C17.9197 4.3763 17.8537 4.37724 17.7911 4.39453C17.7285 4.41182 17.6714 4.44489 17.6252 4.49057L14.635 7.48007C14.5939 7.52128 14.5438 7.55236 14.4886 7.57085C14.4334 7.58933 14.3746 7.59471 14.317 7.58657L12.925 7.38782C12.8446 7.37627 12.7702 7.33896 12.7128 7.28148C12.6555 7.224 12.6184 7.14946 12.607 7.06907L12.4075 5.67707C12.3993 5.61949 12.4045 5.56079 12.4229 5.5056C12.4412 5.45041 12.4722 5.40024 12.5132 5.35907L15.5035 2.36882C15.5492 2.3226 15.5822 2.26544 15.5994 2.2028C15.6167 2.14016 15.6176 2.07414 15.602 2.01107C15.5864 1.94799 15.5549 1.88997 15.5105 1.84257C15.466 1.79516 15.4102 1.75995 15.3482 1.74032C14.5101 1.46959 13.611 1.45077 12.7622 1.68619C11.9135 1.92161 11.1525 2.40092 10.5736 3.06471C9.99472 3.7285 9.62333 4.54759 9.5055 5.42044C9.38766 6.29329 9.52857 7.18153 9.91075 7.97507Z'
        fill={secondaryColor}
      />
      <path
        d='M17.6252 4.49056L14.635 7.48006C14.5939 7.52128 14.5438 7.55236 14.4886 7.57084C14.4334 7.58933 14.3746 7.59471 14.317 7.58656L12.925 7.38781C12.8445 7.37642 12.7699 7.33917 12.7124 7.28168C12.6549 7.22419 12.6176 7.14957 12.6062 7.06906L12.4075 5.67706C12.3993 5.61943 12.4047 5.56069 12.4232 5.5055C12.4417 5.4503 12.4728 5.40017 12.514 5.35906L15.5035 2.36881C15.5492 2.3226 15.5822 2.26544 15.5994 2.2028C15.6167 2.14016 15.6176 2.07414 15.602 2.01106C15.5864 1.94799 15.5549 1.88997 15.5105 1.84256C15.466 1.79516 15.4102 1.75995 15.3482 1.74031C14.5609 1.48567 13.7185 1.45359 12.914 1.64762C12.1096 1.84165 11.3744 2.25423 10.7897 2.83981C10.7762 2.85331 10.7665 2.86906 10.753 2.88331C10.5088 3.80238 10.5396 4.77288 10.8415 5.67462C11.1434 6.57637 11.7032 7.36975 12.4516 7.95651C13.1999 8.54327 14.1039 8.89764 15.0516 8.97572C15.9994 9.05381 16.9492 8.85218 17.7835 8.39581C18.13 7.84261 18.3516 7.22045 18.4328 6.57276C18.514 5.92506 18.4529 5.26745 18.2537 4.64581C18.2341 4.58388 18.1989 4.52803 18.1515 4.4836C18.1041 4.43917 18.0461 4.40766 17.983 4.39208C17.9199 4.3765 17.8539 4.37737 17.7913 4.39461C17.7286 4.41186 17.6715 4.4449 17.6252 4.49056Z'
        fill='white'
      />
      <path
        d='M9.91075 7.97507L2.0125 15.8606C1.74854 16.113 1.58181 16.4502 1.54149 16.8131C1.50118 17.1761 1.58986 17.5417 1.792 17.8458C1.91828 18.0239 2.08191 18.1723 2.27147 18.2806C2.46102 18.3889 2.67193 18.4545 2.88947 18.4728C3.10701 18.4912 3.32594 18.4619 3.53096 18.3868C3.73598 18.3118 3.92216 18.193 4.0765 18.0386C4.0765 18.0386 12.0265 10.0886 12.0197 10.0833C12.8133 10.4654 13.7016 10.6061 14.5744 10.4882C15.4472 10.3702 16.2662 9.99876 16.9299 9.41977C17.5936 8.84079 18.0729 8.07976 18.3082 7.23102C18.5435 6.38229 18.5246 5.48315 18.2537 4.64507C18.234 4.58319 18.1988 4.52742 18.1513 4.48307C18.1039 4.43873 18.0458 4.4073 17.9828 4.3918C17.9197 4.3763 17.8537 4.37724 17.7911 4.39453C17.7285 4.41182 17.6714 4.44489 17.6252 4.49057L14.635 7.48007C14.5939 7.52128 14.5438 7.55236 14.4886 7.57085C14.4334 7.58933 14.3746 7.59471 14.317 7.58657L12.925 7.38782C12.8446 7.37627 12.7702 7.33896 12.7128 7.28148C12.6555 7.224 12.6184 7.14946 12.607 7.06907L12.4075 5.67707C12.3993 5.61949 12.4045 5.56079 12.4229 5.5056C12.4412 5.45041 12.4722 5.40024 12.5132 5.35907L15.5035 2.36882C15.5492 2.3226 15.5822 2.26544 15.5994 2.2028C15.6167 2.14016 15.6176 2.07414 15.602 2.01107C15.5864 1.94799 15.5549 1.88997 15.5105 1.84257C15.466 1.79516 15.4102 1.75995 15.3482 1.74032C14.5101 1.46959 13.611 1.45077 12.7622 1.68619C11.9135 1.92161 11.1525 2.40092 10.5736 3.06471C9.99472 3.7285 9.62333 4.54759 9.5055 5.42044C9.38766 6.29329 9.52857 7.18153 9.91075 7.97507V7.97507Z'
        stroke={primaryColor}
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M3.4135 16.5798C3.43978 16.606 3.4577 16.6394 3.46498 16.6758C3.47226 16.7122 3.46859 16.7499 3.45441 16.7842C3.44024 16.8185 3.41621 16.8478 3.38536 16.8685C3.35451 16.8891 3.31824 16.9001 3.28113 16.9001C3.24402 16.9001 3.20774 16.8891 3.1769 16.8685C3.14605 16.8478 3.12202 16.8185 3.10784 16.7842C3.09367 16.7499 3.08999 16.7122 3.09727 16.6758C3.10456 16.6394 3.12247 16.606 3.14875 16.5798C3.1839 16.5448 3.2315 16.5251 3.28113 16.5251C3.33075 16.5251 3.37836 16.5448 3.4135 16.5798'
        stroke={primaryColor}
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
}
