import { Elements } from '@stripe/react-stripe-js';
import { Stripe } from '@stripe/stripe-js';
// Prevent loading Stripe.js script globally as a side-effect
// https://github.com/stripe/stripe-js#importing-loadstripe-without-side-effects
import { loadStripe } from '@stripe/stripe-js/pure';
import React, { useEffect, useState } from 'react';

import { PaymentServiceProvider } from '@breathelife/types';

type StripeConfig =
  | {
      publicKey: string;
      connectedAccountId: string;
    }
  | { publicKey?: never; connectedAccountId?: never };

type StripeElementsProviderProps = {
  config: PaymentConfig;
  children: React.ReactNode;
};

type PaymentConfig = ({ enabled: true } & { serviceProvider: string; stripe?: StripeConfig }) | { enabled: false };

export function StripeElementsProvider(props: StripeElementsProviderProps): React.ReactElement {
  const { config, children } = props;

  const [stripePromise, setStripePromise] = useState<Promise<Stripe | null> | null>(null);

  useEffect(() => {
    if (!config.enabled || config.serviceProvider !== PaymentServiceProvider.STRIPE || !config.stripe) return;

    const { publicKey, connectedAccountId } = config.stripe;

    // We don't handle cases where public key and connected account ID is changed during runtime
    if (stripePromise !== null || !publicKey || !connectedAccountId) return;

    const loadStripePromise = loadStripe(publicKey, { stripeAccount: connectedAccountId });
    setStripePromise(loadStripePromise);
  }, [config, stripePromise]);

  // We wrap the children in the `Elements` provider component even when `enabled === false` so that `useStripe` can be
  // called properly.
  // If the feature is disabled, we pass in `null` to the `stripe` prop and `useStripe` will simply return `null`
  return <Elements stripe={stripePromise}>{children}</Elements>;
}
