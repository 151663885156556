import _ from 'lodash';

import { IsoCountryCode } from '@breathelife/types';

export interface Autocomplete {
  initialize(inputElement: HTMLInputElement): void;
  terminate(): void;
}

const RELEVANT_GOOGLE_ADDRESS_COMPONENTS = {
  number: {
    type: 'street_number',
    property: 'long_name',
  },
  street: {
    type: 'route',
    property: 'long_name',
  },
  city: {
    type: 'locality',
    property: 'long_name',
  },
  stateOrProvince: {
    type: 'administrative_area_level_1',
    property: 'short_name',
  },
  postalCode: {
    type: 'postal_code',
    property: 'long_name',
  },
};

export type AddressProperties = {
  city: string;
  number: string;
  postalCode: string;
  stateOrProvince: string;
  street: string;
};

export class AddressAutocomplete implements Autocomplete {
  countryCode: IsoCountryCode;
  onSelectedAddressChange: (addressProperties: AddressProperties) => void;
  useGeolocation: boolean;
  // @ts-ignore
  inputElement: HTMLInputElement;
  autocomplete: any;

  constructor(
    countryCode: IsoCountryCode,
    onSelectedAddressChange: (addressProperties: AddressProperties) => void,
    useGeolocation: boolean
  ) {
    this.countryCode = countryCode;
    this.onSelectedAddressChange = onSelectedAddressChange;
    this.useGeolocation = useGeolocation;
  }

  initialize(inputElement: HTMLInputElement): void {
    if (window.TESTCAFE_RUN) return;

    if (typeof google !== 'object' || typeof google.maps !== 'object') {
      console.error('Google Maps API not initialized');
      return;
    }
    this.inputElement = inputElement;

    this.autocomplete = new google.maps.places.Autocomplete(this.inputElement, {
      types: ['address'],
      componentRestrictions: { country: this.countryCode },
    });

    this.inputElement.addEventListener('focus', this.updateLocationBias);

    google.maps.event.addListener(this.autocomplete, 'place_changed', this.handlePlaceChange);
  }

  terminate(): void {
    if (!this.inputElement) return;
    this.inputElement.removeEventListener('focus', this.updateLocationBias);

    google.maps.event.clearListeners(this.autocomplete, 'place_changed');
  }

  handlePlaceChange: () => void = () => {
    if (typeof this.onSelectedAddressChange !== 'function') return;

    const place = this.autocomplete.getPlace();
    const allComponents = _.get(place, ['address_components']);
    if (allComponents == null) return;

    /* Match the address components we're interested in based on their component type (e.g. "street_number"),
     * and use the appropriate property (e.g. "long_name") as a value. */
    // @ts-ignore
    const relevantAddressComponentsValues = Object.keys(RELEVANT_GOOGLE_ADDRESS_COMPONENTS).reduce(
      (components, key) => {
        // @ts-ignore
        const relevantComponentData = RELEVANT_GOOGLE_ADDRESS_COMPONENTS[key];
        const matchedComponent = allComponents.find(
          (component: any) => component.types[0] === relevantComponentData.type
        );
        // @ts-ignore
        components[key] = matchedComponent && matchedComponent[relevantComponentData.property];
        return components;
      },
      {}
    );
    this.onSelectedAddressChange(relevantAddressComponentsValues as AddressProperties);

    // Some validation may be dependent on other field being set, which happens in onSelectedAddressChange. Forcing to blur and re-validate again.
    this.inputElement.focus();
    this.inputElement.blur();
  };

  updateLocationBias: () => void = () => {
    if (!this.useGeolocation || !navigator || !navigator.geolocation) return;

    navigator.geolocation.getCurrentPosition((position) => {
      const geolocation = {
        lat: position.coords.latitude,
        lng: position.coords.longitude,
      };

      const circle = new google.maps.Circle({
        center: geolocation,
        radius: position.coords.accuracy,
      });
      this.autocomplete.setBounds(circle.getBounds());
    });
  };
}
