import React, { Component } from 'react';

import { Loader } from '../Loader/Loader';

export class RootLoader extends Component {
  render(): React.ReactNode {
    const size = 65;
    const loaderSize = `${size}px`;
    const marginSize = `-${size / 2}px`;

    return (
      <div style={{ position: 'fixed', top: '50%', left: '50%', margin: marginSize }}>
        <Loader size={loaderSize} color='#000000' />
      </div>
    );
  }
}
