import React from 'react';
import { RadioProps } from 'semantic-ui-react';

import { InputBoxContainer } from '../CheckBox/Styles';
import { StyledRadio } from './Styles';

export function RadioButton({ className, ...props }: RadioProps): React.ReactElement {
  return (
    <InputBoxContainer className={className}>
      <StyledRadio aria-label={props.label} {...props} />
    </InputBoxContainer>
  );
}
