import React from 'react';
import { useTheme } from 'styled-components';

import { SVGIconProps } from 'Components/Icon/IconMap';

import { SharedTheme } from '../../Theme';

export function File(props: SVGIconProps): React.ReactElement {
  const theme = useTheme() as SharedTheme;
  return (
    <svg fill='none' height='48' viewBox='0 0 48 48' width='48' xmlns='http://www.w3.org/2000/svg'>
      <path d='m44 24c0 11.0457-8.9543 20-20 20s-20-8.9543-20-20 8.9543-20 20-20 20 8.9543 20 20z' fill='#fff' />
      <path
        clipRule='evenodd'
        d='m24 43c10.4934 0 19-8.5066 19-19s-8.5066-19-19-19-19 8.5066-19 19 8.5066 19 19 19zm0 1c11.0457 0 20-8.9543 20-20s-8.9543-20-20-20-20 8.9543-20 20 8.9543 20 20 20z'
        fill='#f6f6f6'
        fillRule='evenodd'
      />
      <path
        d='m33.4375 39.9375c4.6944 0 8.5-3.8056 8.5-8.5s-3.8056-8.5-8.5-8.5-8.5 3.8056-8.5 8.5 3.8056 8.5 8.5 8.5z'
        fill={props.color?.secondary || theme.colors.icons.secondary}
      />
      <g fill={props.color?.primary || theme.colors.icons.primary}>
        <path
          clipRule='evenodd'
          d='m33.4375 23.7875c-4.225 0-7.65 3.425-7.65 7.65 0 4.2249 3.425 7.65 7.65 7.65s7.65-3.4251 7.65-7.65c0-4.225-3.425-7.65-7.65-7.65zm-9.35 7.65c0-5.1639 4.1862-9.35 9.35-9.35 5.1639 0 9.35 4.1861 9.35 9.35 0 5.1638-4.1861 9.35-9.35 9.35-5.1638 0-9.35-4.1862-9.35-9.35z'
          fillRule='evenodd'
        />
        <path
          clipRule='evenodd'
          d='m33.4375 30.5875c.4695 0 .85.3805.85.85v4.25c0 .4694-.3805.85-.85.85-.4694 0-.85-.3806-.85-.85v-4.25c0-.4695.3806-.85.85-.85z'
          fillRule='evenodd'
        />
        <path d='m33.4375 27.2739c-.1051 0-.2078.0312-.2951.0896-.0874.0583-.1555.1413-.1957.2384-.0402.097-.0507.2039-.0302.3069.0205.1031.0711.1977.1453.272.0743.0743.169.1249.2721.1454.103.0205.2098.01.3069-.0302s.18-.1083.2384-.1957c.0584-.0873.0896-.1901.0896-.2951 0-.1409-.056-.276-.1556-.3757-.0997-.0996-.2348-.1556-.3757-.1556z' />
        <path
          clipRule='evenodd'
          d='m32.6701 26.6567c.2272-.1518.4942-.2328.7674-.2328.3664 0 .7177.1455.9767.4045.2591.2591.4046.6104.4046.9767 0 .2732-.081.5403-.2328.7674-.1518.2272-.3675.4042-.6199.5087-.2524.1046-.5301.1319-.798.0786-.268-.0532-.5141-.1848-.7073-.378-.1931-.1931-.3247-.4393-.378-.7072s-.0259-.5457.0786-.798c.1046-.2524.2816-.4682.5087-.6199zm.8894 1.4429c.0192-.0079.0377-.0178.0551-.0294.035-.0234.0648-.0533.088-.088.0115-.0173.0214-.0357.0294-.0551.0161-.0388.0243-.0803.0243-.122 0-.0207-.0021-.0416-.0061-.0621-.0123-.0619-.0427-.1187-.0873-.1633-.0446-.0445-.1014-.0749-.1632-.0872-.0206-.0041-.0414-.0061-.0622-.0061-.0416 0-.0831.0082-.122.0243-.0193.008-.0378.0179-.0551.0294-.0346.0232-.0645.053-.0879.088-.0117.0174-.0215.0359-.0295.0551-.0159.0384-.0242.0799-.0242.1219 0 .0211.0021.0419.0061.0622.0122.0614.0423.1183.0872.1632s.1019.0751.1632.0873c.0204.004.0412.0061.0622.0061.0421 0 .0835-.0083.122-.0243z'
          fillRule='evenodd'
        />
        <path
          clipRule='evenodd'
          d='m13.4625 16.5625c0-.4695.3806-.85.85-.85h14.875c.4695 0 .85.3805.85.85 0 .4694-.3805.85-.85.85h-14.875c-.4694 0-.85-.3806-.85-.85z'
          fillRule='evenodd'
        />
        <path
          clipRule='evenodd'
          d='m13.4625 22.9375c0-.4695.3806-.85.85-.85h8.5c.4695 0 .85.3805.85.85 0 .4694-.3805.85-.85.85h-8.5c-.4694 0-.85-.3806-.85-.85z'
          fillRule='evenodd'
        />
        <path
          clipRule='evenodd'
          d='m13.4625 29.3125c0-.4695.3806-.85.85-.85h5.3125c.4695 0 .85.3805.85.85 0 .4694-.3805.85-.85.85h-5.3125c-.4694 0-.85-.3806-.85-.85z'
          fillRule='evenodd'
        />
        <path
          clipRule='evenodd'
          d='m21.7 36.6c0 .9125-1.075.7875-1.075.7875h-7.4375c-1.0144 0-1.9873-.403-2.7046-1.1203-.71736-.7174-1.12035-1.6903-1.12035-2.7047v-22.375c0-1.0145.40299-1.98737 1.12035-2.7047.7173-.71732 1.6902-1.12031 2.7046-1.12031h15.0578c1.0137.00021 1.9863.40285 2.7034 1.11945l3.069 3.06906c.7166.717 1.1196 1.6896 1.1199 2.7034v3.3706s0 .875-.9376.875c-.7625 0-.7625-.875-.7625-.875v-3.3703c-.0001-.5632-.2238-1.1033-.6219-1.5016l-3.0686-3.0687c-.3984-.3981-.9385-.62179-1.5017-.62191h-15.0578c-.5635 0-1.104.22388-1.5026.6224-.3985.39851-.6224.93901-.6224 1.50261v22.375c0 .5636.2239 1.1041.6224 1.5026.3986.3985.9391.6224 1.5026.6224h7.4375s1.075 0 1.075.9125z'
          fillRule='evenodd'
        />
      </g>
    </svg>
  );
}
