import Hidden from '@material-ui/core/Hidden';
import React from 'react';

import { Container, Content, HeaderContainer, LeftPanelContainer, MainContainer } from './Styles';

export type TransactionFlowLayoutProps = {
  id?: string;
  leftPanel?: React.ReactNode;
  header: React.ReactNode;
  mainContent: React.ReactNode;
  popup?: React.ReactNode;
  hideHeaderOnDesktop?: boolean;
  onMainContentScroll?: (event: React.UIEvent<HTMLDivElement>) => void;
  isAgentSession?: boolean;
};

export function TransactionFlowLayout(props: TransactionFlowLayoutProps): React.ReactElement {
  return (
    <Container id={props.id} $isAgentSession={Boolean(props.isAgentSession)}>
      {props.popup}
      {props.leftPanel && <LeftPanelContainer>{props.leftPanel}</LeftPanelContainer>}
      <Content id='main-content' onScroll={props.onMainContentScroll}>
        {props.hideHeaderOnDesktop ? (
          <Hidden mdUp>
            <HeaderContainer role='banner' $isAgentSession={Boolean(props.isAgentSession)}>
              {props.header}
            </HeaderContainer>
          </Hidden>
        ) : (
          <HeaderContainer role='banner' $isAgentSession={Boolean(props.isAgentSession)}>
            {props.header}
          </HeaderContainer>
        )}
        <MainContainer $isAgentSession={Boolean(props.isAgentSession)}>{props.mainContent}</MainContainer>
      </Content>
    </Container>
  );
}
