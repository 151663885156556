import { Cloudinary } from 'cloudinary-core';
import React from 'react';
import { Helmet } from 'react-helmet';

import { isCloudinarySrc, retrieveCloudinaryPublicIdFromUrl } from '../../helpers/images';
import { CloudinaryProps } from '../Images/Image';
import { FaviconProps, FaviconTypes } from './types';

type Props = {
  compactLogoUrl: string;
  cloudinaryConfig: CloudinaryProps;
};

const faviconOptions: Omit<FaviconProps, 'href'>[] = [
  {
    type: FaviconTypes.icon,
    size: 32,
  },
  {
    type: FaviconTypes.icon,
    size: 192,
  },
  {
    type: FaviconTypes.icon,
    size: 196,
  },
  {
    type: FaviconTypes.appleTouchIcon,
    size: 120,
  },
  {
    type: FaviconTypes.appleTouchIcon,
    size: 152,
  },
  {
    type: FaviconTypes.appleTouchIcon,
    size: 167,
  },
  {
    type: FaviconTypes.appleTouchIcon,
    size: 180,
  },
];

function transformCloudinaryUrl(cloudinaryConfig: CloudinaryProps, publicId: string, size: number): string {
  const cloudinary = new Cloudinary({ cloud_name: cloudinaryConfig.cloudName });
  const transformedUrl = cloudinary.url(publicId, {
    width: size,
    height: size,
    crop: 'fill',
  });

  return transformedUrl;
}

function renderFaviconLinks(compactLogoUrl: string, cloudinaryConfig: CloudinaryProps): React.ReactElement[] | null {
  const publicId = retrieveCloudinaryPublicIdFromUrl(compactLogoUrl, cloudinaryConfig.folder || '');

  if (!isCloudinarySrc(compactLogoUrl) || !publicId) return null;

  const faviconLinks = faviconOptions.map((favicon: Omit<FaviconProps, 'href'>) => (
    <link
      key={`favicon-${favicon.size}x${favicon.size}`}
      rel={favicon.type}
      href={transformCloudinaryUrl(cloudinaryConfig, publicId, favicon.size)}
      {...(favicon.type === FaviconTypes.appleTouchIcon ? { sizes: `${favicon.size}x${favicon.size}` } : {})}
    />
  ));

  return faviconLinks;
}

export function FaviconLinks(props: Props): React.ReactElement {
  return <Helmet>{renderFaviconLinks(props.compactLogoUrl, props.cloudinaryConfig)}</Helmet>;
}
