import React from 'react';
import { useTheme } from 'styled-components';

import { SVGIconProps } from 'Components/Icon/IconMap';

import { SharedTheme } from '../../Theme';

export function WaitIcon(props: SVGIconProps): React.ReactElement {
  const theme = useTheme() as SharedTheme;
  const iconColor = props.color?.primary || theme.colors.icons.primary;

  return (
    <svg fill='none' viewBox='0 0 48 48' xmlns='http://www.w3.org/2000/svg'>
      <path d='m44 24c0 11.0457-8.9543 20-20 20s-20-8.9543-20-20 8.9543-20 20-20 20 8.9543 20 20z' fill='#fff' />
      <path
        clipRule='evenodd'
        d='m24 43c10.4934 0 19-8.5066 19-19s-8.5066-19-19-19-19 8.5066-19 19 8.5066 19 19 19zm0 1c11.0457 0 20-8.9543 20-20s-8.9543-20-20-20-20 8.9543-20 20 8.9543 20 20 20z'
        fill='#f6f6f6'
        fillRule='evenodd'
      />
      <path
        d='m16.9341 39.1419h14.1325l8.0757-8.0757v-14.1325l-8.0757-8.07567h-14.1325l-8.0757 8.07567v14.1325z'
        fill={iconColor}
        opacity='.1'
      />
      <path
        clipRule='evenodd'
        d='m16.327 8.25132c.1609-.16092.3792-.25132.6068-.25132h14.1324c.2276 0 .4459.0904.6068.25132l8.0757 8.07568c.1609.1609.2513.3792.2513.6068v14.1324c0 .2276-.0904.4459-.2513.6068l-8.0757 8.0757c-.1609.1609-.3792.2513-.6068.2513h-14.1324c-.2276 0-.4459-.0904-.6068-.2513l-8.07568-8.0757c-.16092-.1609-.25132-.3792-.25132-.6068v-14.1324c0-.2276.0904-.4459.25132-.6068zm.9622 1.46477-7.57311 7.57311v13.4216l7.57311 7.5731h13.4216l7.5731-7.5731v-13.4216l-7.5731-7.57311z'
        fill={iconColor}
        fillRule='evenodd'
      />
      <path
        d='m19.5229 23.8706h-.85.85v-.0002-.0006-.0024-.0096-.0376-.1433-.5164-1.6126-2.8249c-.0001-.5317.1288-1.0566.3768-1.5315.2482-.4749.6089-.8871 1.0538-1.2012.445-.3142.9611-.5211 1.506-.6024.5448-.0812 1.1015-.0344 1.6236.1365.2666.0872.8942.2921 1.6012.5229 1.0184.3325 2.2017.7188 2.7084.8848.691.2263 1.2889.6589 1.711 1.2334.4218.5742.6473 1.2617.6471 1.9652-.0002.8862-.0001 6.6746 0 8.0783.0001 1.1725-.4743 2.3007-1.325 3.136-.8533.8346-2.0134 1.3062-3.2263 1.3058-.8571-.0003-1.4469-.0002-2.0177-.0001-.3832.0001-.7579.0002-1.199.0001-.9626-.0001-1.9095-.2331-2.7565-.6773-.8322-.4364-1.473-1.069-2.3637-1.9808-.6301-.6449-.8848-.982-1.3988-1.6621-.1261-.1668-.2678-.3542-.4344-.5719-.1198-.1565-.2625-.3281-.403-.4968l-.0096-.0116c-.1414-.1699-.2867-.3444-.4322-.5286-.2999-.3795-.5753-.7688-.7679-1.1571-.1935-.3899-.277-.7264-.2585-1.0141.0168-.2627.1224-.5609.4617-.8942.3747-.368.6918-.4252 1.0024-.3879.3806.0456.8002.2419 1.3179.5264.2721.1495.5842.3428.8352.5036.124.0794.23.149.3048.1985.0374.0248.0668.0444.0867.0578l.0224.015.0054.0037.0012.0008h.0001v.0001c.2605.1765.5971.1947.8751.0473.2779-.1474.4518-.4363.4518-.751z'
        fill='#fff'
        stroke={iconColor}
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeWidth='2'
      />
    </svg>
  );
}
