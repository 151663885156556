import React from 'react';
import { useTheme } from 'styled-components';

import { SVGIconProps } from 'Components/Icon/IconMap';

import { SharedTheme } from '../../Theme';

export function PlaneIcon(props: SVGIconProps): React.ReactElement {
  const theme = useTheme() as SharedTheme;
  const primaryColor = props.color?.primary || theme.colors.icons.primary;
  const secondaryColor = props.color?.secondary || theme.colors.icons.secondary;
  const tertiaryColor = props.color?.tertiary || theme.colors.icons.tertiary;
  return (
    <svg viewBox='0 0 48 48' fill='none' xmlns='http://www.w3.org/2000/svg' aria-hidden='true'>
      <circle cx='25' cy='24' r='19.5' fill='white' stroke='#F6F6F6' />
      <path
        d='M14.6546 29.3153C10.6785 28.7433 5.08022 26.0398 5.00114 24.4986C4.90735 22.7552 10.6086 18.2696 16.6924 18.3909H34.6844L38.7029 15.1541C39.0521 14.8052 39.4776 14.5422 39.9458 14.3858C40.414 14.2295 40.9121 14.184 41.4009 14.2529H45L38.2836 27.2665C37.8468 27.9469 37.2419 28.5031 36.5273 28.8814C35.8127 29.2596 35.0126 29.4471 34.2044 29.4256H28.3873'
        fill={secondaryColor}
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M41.2821 15.0946C40.9236 15.044 40.5584 15.0774 40.215 15.192C39.8717 15.3067 39.5597 15.4996 39.3036 15.7554C39.2822 15.7768 39.2596 15.7971 39.2361 15.8161L35.2176 19.0529C35.0665 19.1746 34.8784 19.2409 34.6844 19.2409H16.6924L16.6754 19.2408C13.8363 19.1841 11.0481 20.2071 8.97229 21.4714C7.93693 22.102 7.10804 22.7752 6.55566 23.3701C6.27884 23.6682 6.08751 23.9295 5.97212 24.1397C5.87447 24.3176 5.85507 24.4151 5.85103 24.4441C5.85812 24.4625 5.88195 24.5174 5.95332 24.6117C6.06469 24.7588 6.24798 24.9436 6.51512 25.1576C7.04786 25.5844 7.82026 26.0534 8.74236 26.508C10.5883 27.4182 12.8956 28.2035 14.7756 28.4739C15.2403 28.5408 15.5628 28.9716 15.496 29.4363C15.4291 29.9009 14.9982 30.2234 14.5336 30.1566C12.4375 29.8551 9.95757 29.0026 7.99056 28.0328C7.00619 27.5474 6.11568 27.0158 5.45226 26.4844C5.12131 26.2192 4.8232 25.9355 4.59775 25.6376C4.38014 25.35 4.17481 24.9764 4.15228 24.5432L4.15223 24.5422L5.00111 24.4986L4.15234 24.5443L4.15228 24.5432C4.12877 24.1008 4.2872 23.6763 4.4819 23.3216C4.68411 22.9532 4.97034 22.579 5.30988 22.2133C5.99022 21.4806 6.94929 20.7131 8.08798 20.0195C10.3583 18.6367 13.4591 17.4784 16.7007 17.5409H16.6924L16.7093 17.5411C16.7064 17.541 16.7036 17.541 16.7007 17.5409H34.3846L38.1363 14.519C38.1249 14.5302 38.1134 14.5414 38.1021 14.5528L38.1363 14.519C38.5721 14.0938 39.0986 13.7726 39.6764 13.5796C40.2495 13.3882 40.858 13.3279 41.457 13.4029H45C45.2965 13.4029 45.5716 13.5575 45.7259 13.8107C45.8801 14.0639 45.8913 14.3793 45.7553 14.6428L39.0389 27.6563C39.0266 27.68 39.0133 27.7032 38.9989 27.7256C38.4831 28.5291 37.7688 29.186 36.9249 29.6326C36.0846 30.0775 35.144 30.2988 34.1936 30.2756H28.3873C27.9178 30.2756 27.5373 29.8951 27.5373 29.4256C27.5373 28.9562 27.9178 28.5756 28.3873 28.5756H34.2044L34.2269 28.5759C34.889 28.5935 35.5443 28.4399 36.1297 28.1301C36.7038 27.8262 37.1914 27.3821 37.5475 26.8393L43.6047 15.1029H41.4008C41.3611 15.1029 41.3214 15.1001 41.2821 15.0946Z'
        fill={primaryColor}
      />
      <path
        d='M23.8503 32.1843H15.5743C15.4526 32.1843 15.3321 32.1602 15.2198 32.1133C15.1075 32.0664 15.0057 31.9976 14.9201 31.9111C14.8346 31.8245 14.7671 31.7218 14.7215 31.609C14.676 31.4962 14.6533 31.3754 14.6547 31.2537V27.535C14.6533 27.4133 14.676 27.2926 14.7215 27.1797C14.7671 27.0669 14.8346 26.9642 14.9201 26.8777C15.0057 26.7911 15.1075 26.7224 15.2198 26.6755C15.3321 26.6286 15.4526 26.6044 15.5743 26.6044H23.8503C25.9671 26.6044 26.5446 27.4688 28.4481 29.3944C25.763 32.2009 25.1377 32.1843 23.8503 32.1843Z'
        fill={tertiaryColor}
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M15.5742 27.4544C15.565 27.4544 15.5559 27.4563 15.5474 27.4598C15.5389 27.4633 15.5312 27.4685 15.5247 27.4751C15.5182 27.4816 15.5131 27.4894 15.5097 27.4979C15.5062 27.5065 15.5045 27.5156 15.5046 27.5248L15.5048 27.535L15.5047 31.2639C15.5046 31.2731 15.5062 31.2822 15.5097 31.2908C15.5131 31.2993 15.5182 31.3071 15.5247 31.3136C15.5312 31.3202 15.5389 31.3254 15.5474 31.3289C15.5559 31.3325 15.565 31.3343 15.5742 31.3343H23.8503C24.4868 31.3343 24.7754 31.3305 25.2264 31.091C25.6679 30.8566 26.2709 30.3852 27.2595 29.3953C27.2436 29.379 27.2279 29.3628 27.2124 29.3468C26.5878 28.7046 26.173 28.2782 25.7389 27.9717C25.2578 27.632 24.7627 27.4544 23.8503 27.4544H15.5742ZM15.5742 25.7544C15.3401 25.7544 15.1083 25.8009 14.8922 25.8911C14.6761 25.9814 14.4801 26.1136 14.3155 26.2802C14.1509 26.4468 14.021 26.6444 13.9333 26.8615C13.8464 27.0768 13.8027 27.3071 13.8047 27.5393V31.2494C13.8027 31.4816 13.8464 31.7119 13.9333 31.9272C14.021 32.1444 14.1509 32.3419 14.3155 32.5085C14.4801 32.6751 14.6761 32.8073 14.8922 32.8976C15.1083 32.9878 15.3401 33.0343 15.5742 33.0343C15.5742 33.0343 15.5743 33.0343 15.5742 33.0343H23.8503C23.8667 33.0343 23.8831 33.0343 23.8996 33.0343C24.5349 33.0346 25.1904 33.0349 26.0236 32.5925C26.8081 32.1759 27.717 31.388 29.0622 29.982C29.3804 29.6494 29.3761 29.1241 29.0525 28.7968C28.8638 28.6059 28.6834 28.4201 28.5095 28.2408C27.8568 27.5683 27.2944 26.989 26.7194 26.583C25.9067 26.009 25.0546 25.7544 23.8503 25.7544H15.5742C15.5743 25.7544 15.5742 25.7544 15.5742 25.7544Z'
        fill={primaryColor}
      />
      <path
        d='M20.7494 22.5C20.7494 23.3284 20.0778 24 19.2494 24C18.421 24 17.7494 23.3284 17.7494 22.5C17.7494 21.6716 18.421 21 19.2494 21C20.0778 21 20.7494 21.6716 20.7494 22.5Z'
        fill={primaryColor}
      />
      <path
        d='M24.7494 22.5C24.7494 23.3284 24.0778 24 23.2494 24C22.421 24 21.7494 23.3284 21.7494 22.5C21.7494 21.6716 22.421 21 23.2494 21C24.0778 21 24.7494 21.6716 24.7494 22.5Z'
        fill={primaryColor}
      />
      <path
        d='M28.7494 22.5C28.7494 23.3284 28.0778 24 27.2494 24C26.421 24 25.7494 23.3284 25.7494 22.5C25.7494 21.6716 26.421 21 27.2494 21C28.0778 21 28.7494 21.6716 28.7494 22.5Z'
        fill={primaryColor}
      />
      <path
        d='M32.7494 22.5C32.7494 23.3284 32.0778 24 31.2494 24C30.421 24 29.7494 23.3284 29.7494 22.5C29.7494 21.6716 30.421 21 31.2494 21C32.0778 21 32.7494 21.6716 32.7494 22.5Z'
        fill={primaryColor}
      />
      <path
        d='M12.2494 23H6.74939C6.74939 23 7.56253 22.1992 9.74939 21C11.9362 19.8008 13.2494 19.5 13.2494 19.5L13.7494 20.5L12.2494 23Z'
        fill='#F8F9FD'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M13.4538 22.7572C12.7722 23.5424 11.9134 23.85 11.2494 23.85H5.74939V22.15H11.2494C11.4188 22.15 11.8099 22.0576 12.17 21.6428C12.5227 21.2365 12.8994 20.4537 12.8994 19H14.5994C14.5994 20.7463 14.1427 21.9635 13.4538 22.7572Z'
        fill={primaryColor}
      />
    </svg>
  );
}
