import React from 'react';

import { Language } from '@breathelife/types';

import { BaseMaskedDateFieldProps, DateMaskMap, MaskedDateInputField } from './MaskedDateInput';

type Props = BaseMaskedDateFieldProps;

// TODO: figure out how to have different date formats in different languages, a lot of conditions in the engine expect the format to be YYYY-MM-DD
const localeDateMaskMap: DateMaskMap = {
  [Language.en]: '9999-99',
  [Language.fr]: '9999-99',
};

const localeDatePlaceholderMap: DateMaskMap = {
  [Language.en]: 'YYYY-MM',
  [Language.fr]: 'AAAA-MM',
};

export function YearMonthField(props: Props): React.ReactElement {
  return (
    <MaskedDateInputField
      name={props.name}
      value={props.value}
      locale={props.locale}
      title={props.title}
      subtitle={props.subtitle}
      validationError={props.validationError}
      inputVariant={props.inputVariant}
      disabled={props.disabled}
      label={props.label}
      onAnswerChange={props.onAnswerChange}
      onAnswerComplete={props.onAnswerComplete}
      localeDateMaskMap={localeDateMaskMap}
      localeDatePlaceholderMap={localeDatePlaceholderMap}
      data-testid={props['data-testid']}
      required={props.required}
    />
  );
}
