import React, { useEffect, useState } from 'react';

import { ButtonContainer } from './Styles';

type Props = {
  ariaLabel: string;
  children?: React.ReactNode;
  onChange?: (isChecked: boolean) => void;
  isChecked?: boolean;
  ['data-testid']?: string;
};

export function TimeButton(props: Props): React.ReactElement {
  const [isChecked, setIsChecked] = useState<boolean>(false);

  useEffect(() => {
    setIsChecked(!!props.isChecked);
  }, [props.isChecked]);

  return (
    <td>
      <ButtonContainer
        type='button'
        isChecked={isChecked}
        aria-label={props.ariaLabel}
        data-testid={props['data-testid']}
        onClick={() => {
          setIsChecked(!isChecked);
          props.onChange && props.onChange(!isChecked);
        }}
      >
        {props.children}
      </ButtonContainer>
    </td>
  );
}
