import { Button as SemanticButton } from 'semantic-ui-react';
import styled from 'styled-components';

export const HeaderButton = styled(SemanticButton)`
  &&& {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 9px 15px;

    border-style: solid;
    border-width: ${(props) => props.theme.metrics.borders.default};
    border-color: ${(props) => props.theme.colors.button.scheduleCall.border.default};
    border-radius: ${(props) => props.theme.metrics.button.borderRadius};
    background-color: ${(props) => props.theme.colors.button.scheduleCall.background.default};

    &:hover {
      box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
      border-width: ${(props) => props.theme.metrics.borders.default};
      border-color: ${(props) => props.theme.colors.button.scheduleCall.border.hover};
      background-color: ${(props) => props.theme.colors.button.scheduleCall.background.hover};
    }
    &:focus {
      box-shadow: 0 0 0 3px ${(props) => props.theme.colors.hyperlink};
      border-width: ${(props) => props.theme.metrics.borders.focus};
      border-color: ${(props) => (props) => props.theme.colors.hyperlink};
      background-color: ${(props) => props.theme.colors.button.scheduleCall.background.hover};
    }
    &:active {
      box-shadow: none;
      border-width: ${(props) => props.theme.metrics.borders.default};
      border-color: ${(props) => props.theme.colors.button.scheduleCall.border.active};
      background-color: ${(props) => props.theme.colors.button.scheduleCall.background.active};
    }

    @media (max-width: ${(props) => props.theme.breakpoints.tablet.min}) {
      height: 40px;
      width: 40px;
      border-radius: 999px;
      padding: 0px;
    }
  }
`;
