import React from 'react';
import { useTheme } from 'styled-components';

import { SVGIconProps } from 'Components/Icon/IconMap';

import { SharedTheme } from '../../Theme';

export function MibIcon(props: SVGIconProps): React.ReactElement {
  const theme = useTheme() as SharedTheme;
  const primaryColor = props.color?.primary || theme.colors.icons.primary;
  const secondaryColor = props.color?.secondary || theme.colors.icons.secondary;
  const tertiaryColor = props.color?.tertiary || theme.colors.icons.tertiary;
  return (
    <svg viewBox='0 0 48 48' fill='none' xmlns='http://www.w3.org/2000/svg' aria-hidden='true'>
      <circle cx='24' cy='24' r='19.5' fill='white' stroke='#F6F6F6' />
      <path
        d='M40.4377 35.084L40.0052 18.6463H14.4835L14.051 35.9491L12.3207 38.1119H34.8143L39.14 37.2468L40.4377 35.084Z'
        fill={tertiaryColor}
      />
      <path
        d='M32.6514 27.0814C32.6514 26.7946 32.5375 26.5196 32.3347 26.3168C32.1319 26.1139 31.8568 26 31.57 26H29.4071V23.8372C29.4071 23.5504 29.2932 23.2753 29.0904 23.0725C28.8876 22.8697 28.6125 22.7557 28.3257 22.7557H26.1629C25.876 22.7557 25.601 22.8697 25.3982 23.0725C25.1954 23.2753 25.0814 23.5504 25.0814 23.8372V26H22.9186C22.6318 26 22.3567 26.1139 22.1539 26.3168C21.9511 26.5196 21.8372 26.7946 21.8372 27.0814V29.2443C21.8372 29.5311 21.9511 29.8062 22.1539 30.009C22.3567 30.2118 22.6318 30.3257 22.9186 30.3257H25.0814V32.4886C25.0814 32.7754 25.1954 33.0504 25.3982 33.2532C25.601 33.4561 25.876 33.57 26.1629 33.57H28.3257C28.6125 33.57 28.8876 33.4561 29.0904 33.2532C29.2932 33.0504 29.4071 32.7754 29.4071 32.4886V30.3257H31.57C31.8568 30.3257 32.1319 30.2118 32.3347 30.009C32.5375 29.8062 32.6514 29.5311 32.6514 29.2443V27.0814Z'
        fill='white'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M24.8475 22.5219C25.1964 22.1731 25.6695 21.9771 26.1628 21.9771H28.3256C28.819 21.9771 29.2921 22.1731 29.6409 22.5219C29.9897 22.8707 30.1857 23.3438 30.1857 23.8372V25.2214H31.5699C32.0632 25.2214 32.5363 25.4174 32.8852 25.7662C33.234 26.115 33.43 26.5881 33.43 27.0814V29.2443C33.43 29.7376 33.234 30.2107 32.8852 30.5595C32.5363 30.9084 32.0632 31.1043 31.5699 31.1043H30.1857V32.4886C30.1857 32.9819 29.9897 33.455 29.6409 33.8038C29.2921 34.1526 28.819 34.3486 28.3256 34.3486H26.1628C25.6695 34.3486 25.1964 34.1526 24.8475 33.8038C24.4987 33.455 24.3027 32.9819 24.3027 32.4886V31.1043H22.9185C22.4252 31.1043 21.9521 30.9084 21.6033 30.5595C21.2544 30.2107 21.0585 29.7376 21.0585 29.2443V27.0814C21.0585 26.5881 21.2544 26.115 21.6033 25.7662C21.9521 25.4174 22.4252 25.2214 22.9185 25.2214H24.3027V23.8372C24.3027 23.3438 24.4987 22.8707 24.8475 22.5219ZM26.1628 23.5344C26.0825 23.5344 26.0055 23.5663 25.9487 23.6231C25.8919 23.6798 25.86 23.7569 25.86 23.8372V26C25.86 26.43 25.5114 26.7786 25.0814 26.7786H22.9185C22.8382 26.7786 22.7612 26.8105 22.7044 26.8673C22.6476 26.9241 22.6157 27.0011 22.6157 27.0814V29.2443C22.6157 29.3246 22.6476 29.4016 22.7044 29.4584C22.7612 29.5152 22.8382 29.5471 22.9185 29.5471H25.0814C25.5114 29.5471 25.86 29.8957 25.86 30.3257V32.4886C25.86 32.5689 25.8919 32.6459 25.9487 32.7027C26.0055 32.7595 26.0825 32.7914 26.1628 32.7914H28.3256C28.406 32.7914 28.483 32.7595 28.5398 32.7027C28.5965 32.6459 28.6284 32.5689 28.6284 32.4886V30.3257C28.6284 29.8957 28.977 29.5471 29.4071 29.5471H31.5699C31.6502 29.5471 31.7272 29.5152 31.784 29.4584C31.8408 29.4016 31.8727 29.3246 31.8727 29.2443V27.0814C31.8727 27.0011 31.8408 26.9241 31.784 26.8673C31.7272 26.8105 31.6502 26.7786 31.5699 26.7786H29.4071C28.977 26.7786 28.6284 26.43 28.6284 26V23.8372C28.6284 23.7569 28.5965 23.6798 28.5398 23.6231C28.483 23.5663 28.406 23.5344 28.3256 23.5344H26.1628Z'
        fill={primaryColor}
      />
      <path
        d='M7.99487 35.5166V10.8601L14.0509 9.56238L20.5394 10.4275V13.0229L21.4045 13.8881H32.6514L35.2468 15.1858L36.1119 18.6463H14.0509V35.5166L12.3206 37.6794H9.72515L7.99487 35.5166Z'
        fill={secondaryColor}
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M9.94148 10.5573C9.57436 10.5573 9.22227 10.7031 8.96268 10.9627C8.70309 11.2223 8.55725 11.5744 8.55725 11.9415V34.6514C8.55725 35.3053 8.81702 35.9325 9.27942 36.3949C9.74182 36.8573 10.369 37.117 11.0229 37.117C11.6768 37.117 12.304 36.8573 12.7664 36.3949C13.2288 35.9325 13.4885 35.3053 13.4885 34.6514V18.43C13.4885 18 13.8372 17.6514 14.2672 17.6514H35.3001C35.1693 16.2033 34.6799 15.5466 34.3158 15.2432C34.0911 15.056 33.8711 14.9659 33.7137 14.9221C33.6348 14.9002 33.5727 14.8903 33.536 14.8859C33.5207 14.8841 33.51 14.8833 33.5048 14.883H21.8371C21.3438 14.883 20.8707 14.687 20.5219 14.3382C20.1731 13.9893 19.9771 13.5162 19.9771 13.0229V11.9415C19.9771 11.5744 19.8313 11.2223 19.5717 10.9627C19.3121 10.7031 18.96 10.5573 18.5929 10.5573H9.94148ZM33.5165 14.1043V13.3257H21.8371C21.7568 13.3257 21.6798 13.2938 21.623 13.237C21.5663 13.1802 21.5343 13.1032 21.5343 13.0229V11.9415C21.5343 11.1613 21.2244 10.4132 20.6728 9.86154C20.1212 9.3099 19.373 9 18.5929 9H9.94148C9.16135 9 8.41317 9.3099 7.86154 9.86154C7.3099 10.4132 7 11.1613 7 11.9415V34.6514C7 35.7183 7.42384 36.7416 8.17828 37.496C8.93272 38.2505 9.95596 38.6743 11.0229 38.6743H36.9771C38.044 38.6743 39.0673 38.2505 39.8217 37.496C40.5762 36.7416 41 35.7183 41 34.6514V18.43C41 18 40.6514 17.6514 40.2214 17.6514H36.8623C36.7228 15.8016 36.0839 14.6896 35.3127 14.0469C34.8885 13.6934 34.4596 13.5131 34.1305 13.4217C33.9661 13.376 33.8253 13.3521 33.7201 13.3396C33.6674 13.3333 33.6232 13.3299 33.5889 13.328C33.5717 13.327 33.557 13.3264 33.5449 13.3261L33.5287 13.3258L33.5221 13.3257L33.5192 13.3257L33.5178 13.3257C33.5172 13.3257 33.5165 13.3257 33.5165 14.1043ZM15.0458 19.2087V34.6514C15.0458 35.5489 14.7459 36.4154 14.2016 37.117H36.9771C37.631 37.117 38.2582 36.8573 38.7206 36.3949C39.183 35.9325 39.4427 35.3053 39.4427 34.6514V19.2087H15.0458Z'
        fill={primaryColor}
      />
    </svg>
  );
}
