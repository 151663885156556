import React from 'react';
import { useTheme } from 'styled-components';

import { SVGIconProps } from 'Components/Icon/IconMap';

import { SharedTheme } from '../../Theme';

export function Close2(props: SVGIconProps): React.ReactElement {
  const theme = useTheme() as SharedTheme;
  return (
    <svg width='20' height='20' viewBox='0 0 30 30' fill='none' strokeWidth='1' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M7.76854 22.7315L22.5 8'
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeWidth={2}
        stroke={props.color?.primary || theme.colors.icons.primary}
      />
      <path
        d='M22.5 22.7315L7.76854 8'
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeWidth={2}
        stroke={props.color?.primary || theme.colors.icons.primary}
      />
    </svg>
  );
}
