import { Checkbox as SemanticCheckbox } from 'semantic-ui-react';
import styled, { css } from 'styled-components';

import { colors, fontSize, animation } from '../../Theme/Theme';
import { showAnimation } from '../Animations';

type Props = {
  checked: boolean;
  animate?: boolean;
};

const selectedStyle = css`
  background-color: ${(props) => props.theme.colors.base.softPrimary} !important;

  &:hover {
    background-color: ${(props) => props.theme.colors.button.option.background.selected};
    border-color: ${(props) => props.theme.colors.button.option.border.selected};
  }

  &:active {
    background-color: ${(props) => props.theme.colors.button.option.background.selected};
    border-color: ${(props) => props.theme.colors.button.option.border.selected};
  }
`;

export const Button = styled.button<Props>`
  border: 0;
  outline: none;
  padding: 10px;
  border-bottom: 1px solid ${colors.border.bottom};
  display: flex;
  align-items: center;
  width: 100%;
  transition: all ${animation.speed.medium} ${animation.transition};

  background-color: ${(props) => props.theme.colors.button.option.background.default};

  ${(props) =>
    props.animate &&
    css`
      animation: ${showAnimation} ${animation.speed.slow};
    `}

  &:focus {
    transition: border 0s;
    background-color: ${(props) => props.theme.colors.button.option.background.focus};
    border-color: ${(props) => props.theme.colors.button.option.border.focus} !important;
    border-width: ${(props) => props.theme.metrics.borders.button};
    border-style: solid;
  }

  &:hover {
    background-color: ${(props) => props.theme.colors.button.option.background.hover};
    border-color: ${(props) => props.theme.colors.button.option.border.hover};
  }

  &:active {
    background-color: ${(props) => props.theme.colors.button.option.background.active};
    border-color: ${(props) => props.theme.colors.button.option.border.active};
  }

  ${(props) => props.checked && selectedStyle};
`;

export const Content = styled.div`
  flex: 1;
  margin: 5px 20px;
  text-align: left;
  transition: all ${animation.speed.medium} ${animation.transition};
`;

export const Img = styled.img`
  width: 50px;
  height: auto;
`;

export const Icon = styled.img``;

export const Title = styled.label<Props>`
  font-size: ${fontSize.option.title};
  font-weight: 600;
  margin-bottom: 0;
  color: ${(props) =>
    props.checked ? props.theme.colors.button.option.text.selected : props.theme.colors.button.option.text.title};
  transition: all ${animation.speed.medium} ${animation.transition};
`;

export const Description = styled.p`
  color: ${(props) => props.theme.colors.button.option.text.default};
  font-size: ${fontSize.option.description};
  line-height: 1.4;
  min-height: 30px;
  letter-spacing: 0.7px;
  margin-top: 3px;
`;

export const Select = styled.div`
  position: relative;
`;

export const Checkbox = styled(SemanticCheckbox)`
  &&& {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    input,
    label {
      display: none;
    }
  }
`;
