import { StyleRules } from '@material-ui/core/styles';

import { coreGreys } from '../../Theme/CoreColors';

export const styles = (): StyleRules => ({
  root: {
    alignItems: 'start',
    backgroundColor: `${coreGreys[0]} !important`,
    border: `1px solid ${coreGreys[30]}`,
    borderRadius: '8px',
    boxShadow: '0px 8px 25px rgba(0, 0, 0, 0.05)',
    flexWrap: 'nowrap' as const, // Fix for TS2345 (Reference: https://github.com/cssinjs/jss/issues/1344)
    padding: '8px 16px !important',
    minWidth: '280px',
    maxWidth: '400px',
    flexGrow: 1,
  },
  action: {
    paddingLeft: 0,
  },
});
