import React from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';

import { ScreenType, viewedScreen } from '@breathelife/react-tracking';

export type ContextProps = {
  popup: React.ReactElement | null;
  setPopup: (popup: React.ReactElement | null, screenType?: ScreenType) => void;
};

export const PopupContext = React.createContext({
  popup: null,
  setPopup: () => {},
} as ContextProps);

type Props = {
  children: React.ReactNode;
  viewedScreen: (screen: ScreenType) => void;
};

class PopupProviderView extends React.Component<Props> {
  setPopup = (popup: React.ReactElement | null, screenType?: ScreenType) => {
    popup && screenType && this.props.viewedScreen(screenType);
    this.setState({ popup });
  };

  state = {
    popup: null,
    setPopup: this.setPopup,
  };

  render() {
    return <PopupContext.Provider value={this.state}>{this.props.children}</PopupContext.Provider>;
  }
}

const mapDispatchToProps = (dispatch: Dispatch) => ({
  viewedScreen: (screen: ScreenType) => dispatch(viewedScreen(screen)),
});

export const PopupProvider = connect(null, mapDispatchToProps)(PopupProviderView);
