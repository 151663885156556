import _ from 'lodash';
import React from 'react';
import { Sticky } from 'react-sticky';

import { LegalLink, LegalMenu } from '../LegalMenu/LegalMenu';
import { ProgressBar } from '../ProgressBar/ProgressBar';
import {
  ChildrenContainer,
  Content,
  DesktopHeaderContainer,
  ProgressBarContainer,
  ProgressText,
} from './TransactionFlowStyles';

type Props = {
  children: React.ReactNode;
  displayChildrenOnly?: boolean;
  progress?: number;
  progressText?: string;
  legalLinks: LegalLink[];
};

export function TransactionFlowDesktopHeader(props: Props): React.ReactElement {
  const displayChildrenOnly = props.displayChildrenOnly ?? false;

  if (displayChildrenOnly) {
    return <Sticky>{({ style }) => <ChildrenContainer style={style}>{props.children}</ChildrenContainer>}</Sticky>;
  }

  return (
    <Sticky>
      {({ style }) => (
        <DesktopHeaderContainer style={style}>
          {!_.isNil(props.progress) && (
            <ProgressBarContainer>
              <ProgressBar progress={props.progress} />
            </ProgressBarContainer>
          )}
          <Content>
            {props.progressText && <ProgressText>{props.progressText}</ProgressText>}
            <ChildrenContainer>{props.children}</ChildrenContainer>
            <LegalMenu links={props.legalLinks} />
          </Content>
        </DesktopHeaderContainer>
      )}
    </Sticky>
  );
}
