import React from 'react';
import { useTheme } from 'styled-components';

import { SVGIconProps } from 'Components/Icon/IconMap';

import { SharedTheme } from '../../Theme';

export function Search(props: SVGIconProps): React.ReactElement {
  const theme = useTheme() as SharedTheme;
  const color = props.color?.primary || theme.colors.icons.primary;
  return (
    <svg viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <g clipPath='url(#clip0_15434_188058)'>
        <path
          d='M0.981358 8.90465C1.60812 10.3794 2.79507 11.5448 4.28109 12.1445C5.76711 12.7441 7.43046 12.7289 8.90524 12.1021C10.38 11.4753 11.5454 10.2884 12.145 8.80236C12.7447 7.31634 12.7294 5.65299 12.1027 4.17821C11.4759 2.70343 10.289 1.53803 8.80295 0.938398C7.31693 0.338763 5.65357 0.354004 4.17879 0.98077C2.70401 1.60754 1.53862 2.79448 0.938985 4.2805C0.33935 5.76652 0.354592 7.42988 0.981358 8.90465Z'
          fill='white'
          stroke={color}
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path d='M10.814 10.8134L15.5 15.5' stroke={color} strokeLinecap='round' strokeLinejoin='round' />
      </g>
      <defs>
        <clipPath id='clip0_15434_188058'>
          <rect width='16' height='16' fill='white' />
        </clipPath>
      </defs>
    </svg>
  );
}
