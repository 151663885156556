import _ from 'lodash';
import React, { ChangeEvent } from 'react';
import { SelectProps } from 'semantic-ui-react';

import { Container, StyledMobileSelect, StyledSelect, SelectWrapper, SelectLabel, LabelSpacer } from './Styles';

export type DropdownOptionValue = number | string | null;

function isMobileOrTabletDevice(): boolean {
  return /Android|webOS|iPhone|iPad|iPod|BlackBerry|Windows Phone|Opera Mini|IEMobile|Mobile/i.test(
    navigator.userAgent
  );
}

type Props = Omit<SelectProps, 'onChange'> & {
  onChange: (value: DropdownOptionValue) => void;
  value?: DropdownOptionValue;
  searchable?: boolean;
};

type SelectLabelContainerProps = {
  label: string;
};

function SelectLabelContainer(props: SelectLabelContainerProps): React.ReactElement {
  return (
    <React.Fragment>
      <SelectLabel>{props.label}</SelectLabel>
      <LabelSpacer />
    </React.Fragment>
  );
}

export function Select({ onChange, searchable, ...props }: Props): React.ReactElement {
  if (isMobileOrTabletDevice()) {
    return (
      <Container>
        <SelectLabelContainer label={props.label} />
        <SelectWrapper className={props.className}>
          <StyledMobileSelect
            value={_.isNil(props.value) ? '' : props.value}
            onChange={(event: ChangeEvent<HTMLSelectElement>) => onChange(event.target.value)}
            disabled={props.disabled}
            aria-label={props['aria-label']}
          >
            <option disabled hidden value=''>
              {props.placeholder}
            </option>

            {_.map(props.options, (option, index) => (
              <option key={index} value={option.value as number}>
                {option.text}
              </option>
            ))}
          </StyledMobileSelect>
        </SelectWrapper>
      </Container>
    );
  }
  if (searchable) {
    return (
      <>
        <SelectLabelContainer label={props.label} />
        <StyledSelect
          {...props}
          placeholder={props.placeholder}
          onChange={(e: MouseEvent, data: { value: DropdownOptionValue }) => onChange(data.value)}
          selectOnBlur={false}
          searchInput={{ 'aria-label': props['aria-label'] }}
          search
        />
      </>
    );
  }
  return (
    <>
      <SelectLabelContainer label={props.label} />
      <StyledSelect
        {...props}
        placeholder={props.placeholder}
        onChange={(e: MouseEvent, data: { value: DropdownOptionValue }) => onChange(data.value)}
        aria-label={props['aria-label']}
      />
    </>
  );
}
