import { Checkbox } from 'semantic-ui-react';
import styled from 'styled-components';

export const StyledToggle = styled(Checkbox)`
  &&&&& {
    label {
      padding-left: 54px;
      color: ${(props) => props.theme.colors.grey.darkest};
    }
    input:checked ~ label {
      color: ${(props) => props.theme.colors.grey.darkest} !important;
    }

    label:before {
      width: 43px;
      height: 22px;
    }
    input:checked ~ label:before {
      background-color: ${(props) => props.theme.colors.base.secondary} !important;
    }
    input:not(:checked) ~ label:before {
      background-color: ${(props) => props.theme.colors.grey.dark} !important;
    }

    label:after {
      width: 18px;
      height: 18px;
      top: 0.13rem;
      box-shadow: none;
    }
    input:checked ~ label:after {
      left: 1.65rem;
      width: 18px;
      height: 18px;
    }
    input:not(:checked) ~ label:after {
      left: 0.165rem;
    }
  }
`;
