import React from 'react';
import styled, { css } from 'styled-components';

import { OptionWidth } from '@breathelife/questionnaire-engine';

import { Aside } from '../Aside';
import { Bullet, CheckIcon, Container, Content, Input, RadioLabel } from '../RadioCard/Styles';

type Props = {
  optionId: string;
  children: React.ReactNode;
  checked: boolean;
  ariaLabel?: string;
  groupName: string;
  suffix?: React.ReactNode;
  marginless?: boolean;
  showErrorBorder?: boolean;
  disabled?: boolean;
  onChange: (optionId: string, checked: boolean) => void;
};

const CheckboxBullet = styled(Bullet)`
  border-radius: 2px;
`;

export function CheckboxCard(props: Props): React.ReactElement {
  const { optionId, children, checked, ariaLabel, groupName, onChange, suffix } = props;

  return (
    <Container marginless={props.marginless}>
      <Input
        type='checkbox'
        id={`${groupName}_${optionId}`}
        value={optionId}
        aria-label={ariaLabel}
        name={groupName}
        checked={checked}
        disabled={props.disabled}
        onChange={(e) => onChange(optionId, e.target.checked)}
      />

      <RadioLabel
        htmlFor={`${groupName}_${optionId}`}
        data-testid={`checkbox-${groupName}-${optionId}`}
        aria-checked={checked}
        $showErrorBorder={props.showErrorBorder}
      >
        <Aside>
          <div>
            <CheckboxBullet>{checked && <CheckIcon />}</CheckboxBullet>
          </div>
          <Content>{children}</Content>
          {suffix && <div>{suffix}</div>}
        </Aside>
      </RadioLabel>
    </Container>
  );
}

export const CheckboxCardContainer = styled.div.attrs({
  role: 'group',
})<{ optionWidth?: OptionWidth }>`
  ${(props) => {
    if (props.optionWidth) {
      let columns = '1fr';

      switch (props.optionWidth) {
        case 'half':
          columns = '1fr 1fr';
          break;
        case 'oneThird':
          columns = '1fr 1fr 1fr';
          break;
      }

      return css`
        display: grid;
        grid-auto-rows: auto;
        grid-column-gap: 10px;
        grid-row-gap: 10px;
        grid-template-columns: ${columns};
        ${Container} {
          max-width: 100%;
        }
      `;
    }
  }}

  padding: 0;
  border: none;
`;
