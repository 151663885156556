import React from 'react';

import { Language } from '@breathelife/types';

import { toCurrency } from '../../helpers/localizer';
import { InfoSupplement } from '../InfoSupplement/InfoSupplement';
import { Statistic } from '../Statistic';
import { InfoCardContainer, StyledCard, StyledCardContent } from './Styles';

type Props = {
  title: string;
  subtitle: string;
  info?: {
    title?: string;
    text: string;
  };
  locale?: Language;
  isCurrency?: boolean;
  ['data-testid']?: string;
};

export function StatCard(props: Props): React.ReactElement {
  const { title, subtitle, info } = props;
  let formattedSubtitle = subtitle;
  if (props.isCurrency) {
    formattedSubtitle = toCurrency(props.locale || Language.en, parseFloat(subtitle), { precision: 0 });
  }
  return (
    <InfoCardContainer data-testid={props['data-testid']}>
      <StyledCard variant={'outlined'}>
        <StyledCardContent>
          <Statistic
            title={title}
            value={formattedSubtitle}
            suffix={info && <InfoSupplement title={info.title} text={info.text} />}
            suffixAlign='center'
          />
        </StyledCardContent>
      </StyledCard>
    </InfoCardContainer>
  );
}
