import _ from 'lodash';

export const isCloudinarySrc = (src: string): boolean => {
  try {
    const url = new URL(src);
    return url.hostname.includes('cloudinary');
  } catch (e) {
    return false;
  }
};

export const retrieveCloudinaryPublicIdFromUrl = (src: string, folder: string): string => {
  // Saved cloudinary picture url example in auth0:
  // "https://res.cloudinary.com/breathelife/image/upload/v1582923345/px/breathelife/filename.jpg"
  // First, splitting on '.' and getting the two last item in the array. Then we retrieve the first position of that two last item.
  // We end up having 'com/breathelife/image/upload/v1582923345/px/breathelife/filename' , so we split on the '/' and retrieve the last position
  // to get the filename
  const fileName = _.last(src.split('.').slice(-2)[0].split('/'));

  return `${folder}/${fileName}`;
};
