import React from 'react';
import { useTheme } from 'styled-components';

import { SVGIconProps } from 'Components/Icon/IconMap';

import { SharedTheme } from '../../Theme';

export function HandShake(props: SVGIconProps): React.ReactElement {
  const theme = useTheme() as SharedTheme;
  const primaryColor = props.color?.primary || theme.colors.icons.primary;
  const secondaryColor = props.color?.secondary || theme.colors.icons.secondary;
  const tertiaryColor = props.color?.tertiary || theme.colors.icons.tertiary;
  return (
    <svg fill='none' height='48' viewBox='0 0 48 48' width='48' xmlns='http://www.w3.org/2000/svg'>
      <circle cx='24' cy='24' fill='#fff' r='19.5' stroke='#f6f6f6' />
      <path
        clipRule='evenodd'
        d='m18.6311 14.9773c-.5791-.0002-1.1524.1135-1.6887.3343zm-1.6887.3343c-.0003.0002-.0007.0003-.0011.0005zm-.0011.0005-7.91073 3.234c-.40898.1671-.60498.6342-.43779 1.0432s.63426.605 1.04324.4378l7.91288-3.2349.0022-.0008c.3436-.1417.7101-.2142 1.0797-.2141h.0002c.6146.0001 1.2152.1988 1.7158.5701l.6186.5212c.338.2846.8427.2414 1.1273-.0965s.2415-.8426-.0965-1.1272l-.6364-.5362c-.0112-.0094-.0226-.0185-.0343-.0273-.7787-.5862-1.7227-.9039-2.6941-.9041'
        fill={primaryColor}
        fillRule='evenodd'
      />
      <path
        d='m4 16.1935h3.55556c.45143-.0192.89194.1451 1.22512.4571.33317.312.53189.7462.55265 1.2076v11.6617c-.02077.4616-.21942.896-.55254 1.2082-.33313.3123-.77363.4771-1.22523.4583h-3.55556'
        fill={secondaryColor}
      />
      <path
        clipRule='evenodd'
        d='m8.23382 17.2345c-.17813-.1668-.40995-.2517-.64426-.2417-.01135.0004-.0227.0007-.03405.0007h-3.55556c-.44183 0-.8-.3582-.8-.8s.35817-.8.8-.8h3.54018c.66197-.0232 1.30362.2202 1.7873.6731.48761.4566.77497 1.0884.80507 1.7556.0005.012.0008.024.0008.036v11.6617c0 .012-.0003.024-.0008.036-.0301.6672-.31728 1.299-.80464 1.7559-.48355.4533-1.12517.6972-1.78734.6746h-3.54057c-.44183 0-.8-.3582-.8-.8s.35817-.8.8-.8h3.55556c.01106 0 .02211.0003.03316.0007.23463.0097.46668-.0755.64495-.2426.1753-.1644.28453-.3952.29966-.6452v-11.6205c-.01512-.2497-.12429-.4802-.29946-.6443z'
        fill={primaryColor}
        fillRule='evenodd'
      />
      <path
        d='m44 31.1864h-3.5556c-.4516.0188-.8921-.146-1.2252-.4583-.3331-.3122-.5318-.7466-.5526-1.2082v-11.6617c.0208-.4614.2195-.8956.5527-1.2076s.7737-.4763 1.2251-.4571h3.5556'
        fill={tertiaryColor}
      />
      <g clipRule='evenodd' fill={primaryColor} fillRule='evenodd'>
        <path d='m29.5718 15.009c-.4308-.1613-.8889-.2304-1.3462-.2033-.4572.0272-.9048.15-1.3151.3615l-5.9702 3.0688-.0009.0005c-.2917.1513-.5195.4154-.6297.7412-.1104.3264-.0921.6844.0502.9966l.0012.0026c.131.2901.3595.5162.6377.6399.2777.1234.5887.138.8755.0415l6.1647-2.0824c.4186-.1414.8726.0833 1.014.5019s-.0833.8725-.5019 1.0139l-6.1653 2.0827c-.6674.2246-1.3934.1905-2.0369-.0955-.6426-.2857-1.1554-.8006-1.4455-1.4423-.3108-.6827-.3497-1.4608-.1093-2.1716.2407-.7116.7444-1.3049 1.4104-1.6498l.0022-.0011 5.9707-3.0691c.6075-.313 1.2728-.4961 1.9535-.5364.6808-.0404 1.3622.0625 2.0017.3019l8.1361 3.0422c.113-.1768.2482-.3404.4038-.4861.4837-.4529 1.1253-.6963 1.7873-.6731h3.5402c.4418 0 .8.3582.8.8s-.3582.8-.8.8h-3.5556c-.0113 0-.0227-.0003-.034-.0007-.2343-.01-.4662.075-.6443.2418-.1752.164-.2843.3945-.2995.6442v11.6205c.0152.25.1244.4808.2997.6452.1783.1671.4103.2524.6449.2426.0056-.0002.0111-.0004.0166-.0005s.0111-.0002.0166-.0002h3.5556c.4418 0 .8.3582.8.8 0 .4419-.3582.8-.8.8h-3.5406c-.6622.0226-1.3038-.2213-1.7873-.6746-.1377-.129-.2594-.272-.3638-.4258l-8.5905 1.678c-.4336.0847-.8538-.1981-.9385-.6318-.0847-.4336.1981-.8538.6317-.9385l8.4556-1.6517v-11.2314z' />
        <path d='m25.2389 19.5666c.3512-.2681.8532-.2008 1.1214.1504l5.5804 7.3093.0095.0127c.3774.5151.606 1.1257.662 1.7633.056.6375-.0625 1.2791-.3434 1.8533-.2809.5743-.7138 1.0599-1.2525 1.4005-.5389.3407-1.1615.5223-1.798.5227h-.1536c-.0718.3397-.1892.6692-.3501.979-.285.5488-.6982 1.0197-1.205 1.3702-.5068.3507-1.0913.5699-1.7026.6372-.3367.0371-.6755.0273-1.0071-.0279v.0252c-.0003.6567-.197 1.2995-.5659 1.8415-.369.5422-.8934.959-1.505 1.1912-.6117.2323-1.2792.268-1.912.1018-.6303-.1656-1.1934-.5229-1.6158-1.0211l-6.0004-6.8744h-3.98308c-.44182 0-.8-.3582-.8-.8s.35818-.8.8-.8h4.34668c.231 0 .4508.0999.6027.2739l6.2522 7.163c.2131.2523.4941.4296.8043.5111.31.0814.6367.0641.9374-.0501.3009-.1142.5634-.321.7503-.5956.187-.2748.2885-.6034.2886-.9417v-.791l-1.5105-1.5441c-.3089-.3159-.3034-.8224.0125-1.1313.3158-.309.8223-.3034 1.1313.0124l1.6228 1.6589.1578.0646c.3235.1322.673.1812 1.0182.1432.3453-.038.6774-.162.9673-.3625.29-.2007.5293-.4721.6954-.792.1193-.2298.1981-.4791.233-.7363l-2.361-2.4135c-.3089-.3159-.3034-.8224.0125-1.1313.3158-.309.8223-.3034 1.1313.0124l2.3744 2.4272h.5323c.3322-.0002.659-.0948.944-.2751.2852-.1803.5181-.4397.6704-.7511.1523-.3115.2174-.6615.1867-1.0102-.0304-.3464-.1538-.6757-.3546-.952l-5.5752-7.3025c-.2681-.3511-.2008-.8532.1504-1.1213z' />
      </g>
    </svg>
  );
}
