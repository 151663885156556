import React from 'react';
import { useTheme } from 'styled-components';

import { SVGIconProps } from 'Components/Icon/IconMap';

import { SharedTheme } from '../../Theme';

export function Checkmark(props: SVGIconProps): React.ReactElement {
  const theme = useTheme() as SharedTheme;
  return (
    <svg width='14' height='12' viewBox='0 0 14 12' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M13.4873 0.252427C13.2198 0.0486841 12.8848 -0.0368557 12.5563 0.0146416C12.2277 0.0661388 11.9324 0.250451 11.7354 0.526995L5.79489 8.8573L2.01739 5.73447C1.47563 5.30568 0.701501 5.40301 0.27508 5.95354C-0.151341 6.50407 -0.073607 7.30581 0.450034 7.75799L5.24636 11.7221C5.5141 11.9385 5.85478 12.0346 6.19179 11.9888C6.5288 11.943 6.83385 11.7592 7.03834 11.4787L13.757 2.06354C13.9538 1.78647 14.036 1.43992 13.9854 1.10022C13.9348 0.760526 13.7556 0.455538 13.4873 0.252427Z'
        fill={props.color?.primary || theme.colors.icons.primary}
      />
    </svg>
  );
}
