import _ from 'lodash';
import React from 'react';

import {
  ItemCount,
  ListItemActionsContainer,
  ListItemActionsLabel,
  Container,
  ListItemContainer,
  BaseActionButton,
} from './Styles';

type Props = {
  items: React.ReactNode[];
  actionLabel: string;
  removeItemAriaLabel: string;
  addItemAriaLabel: string;
  minListLength?: number;
  maxListLength?: number;
  onChange: (index: number) => void;
  className?: string;
};

export const itemCountIsValid = (
  currentCount: number,
  minCount: number = 0,
  maxCount: number = Number.MAX_SAFE_INTEGER
) => _.inRange(currentCount, minCount, maxCount + 1);

const renderAddItemSvg = () => (
  <svg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'>
    <g fill='none' fillRule='evenodd'>
      <circle cx='15' cy='15' r='14.25' fill='#FFF' stroke='#000' strokeWidth='1.5' />
      <path fill='#000' fillRule='nonzero' d='M10.5 15.75h9a.75.75 0 1 0 0-1.5h-9a.75.75 0 1 0 0 1.5z' />
    </g>
  </svg>
);

const renderRemoveItemSvg = () => (
  <svg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'>
    <g fill='none' fillRule='evenodd'>
      <circle cx='15' cy='15' r='14.25' fill='#FFF' stroke='#000' strokeWidth='1.5' />
      <path
        fill='#000'
        fillRule='nonzero'
        d='M14.25 14.25V10.5a.75.75 0 1 1 1.5 0v3.75h3.75a.75.75 0 1 1 0 1.5h-3.75v3.75a.75.75 0 1 1-1.5 0v-3.75H10.5a.75.75 0 1 1 0-1.5h3.75z'
      />
    </g>
  </svg>
);

export function ListItemController({ minListLength = 0, ...props }: Props): React.ReactElement {
  const onChange = (e: React.MouseEvent, index: number): void => {
    if (!itemCountIsValid(index, minListLength, props.maxListLength)) return;

    props.onChange(index);
  };

  const disableAddItemButton = (): boolean => (props.maxListLength ? props.items.length >= props.maxListLength : false);

  return (
    <Container className={props.className}>
      <ListItemContainer>
        <ListItemActionsLabel>{props.actionLabel}</ListItemActionsLabel>
        <ListItemActionsContainer>
          <BaseActionButton
            type='button'
            onClick={(e: React.MouseEvent) => onChange(e, props.items.length - 1)}
            disabled={props.items.length <= minListLength}
            data-testid='item-minus'
            aria-label={props.removeItemAriaLabel}
          >
            {renderAddItemSvg()}
          </BaseActionButton>
          <ItemCount>{props.items.length}</ItemCount>
          <BaseActionButton
            type='button'
            onClick={(e: React.MouseEvent) => onChange(e, props.items.length + 1)}
            disabled={disableAddItemButton()}
            data-testid='item-plus'
            aria-label={props.addItemAriaLabel}
          >
            {renderRemoveItemSvg()}
          </BaseActionButton>
        </ListItemActionsContainer>
      </ListItemContainer>
      {props.items}
    </Container>
  );
}
