import styled from 'styled-components';

import { HeaderButton } from '../Button/HeaderButton';
import { Image } from '../Images/Image';

export const AssigneeContactPrompt = styled.span`
  display: none;
  font-size: 15px !important;
  font-weight: 400;
  line-height: 20px;
  color: ${(props) => props.theme.colors.grey[80]} !important;
  @media (min-width: ${(props) => props.theme.breakpoints.tablet.min}) {
    display: block;
  }
`;

export const ApplicationAssigneeImage = styled(Image)`
  margin-right: 12px;
`;

export const IconContainer = styled.div`
  svg {
    fill: ${(props) => props.theme.colors.grey[80]};
  }
`;

export const AssigneeContactButton = styled(HeaderButton)`
  &&& {
    justify-content: space-between;
    margin: 0 0 0 auto;
    padding: 0 12px 0 2px;
    width: 80px;
    height: 36px;
    border-radius: 100px;

    &:hover,
    &:focus,
    &:active {
      ${AssigneeContactPrompt}, ${IconContainer} {
        color: ${(props) => props.theme.colors.grey[90]};
        svg {
          fill: ${(props) => props.theme.colors.grey[90]};
        }
      }
    }

    @media (min-width: ${(props) => props.theme.breakpoints.tablet.min}) {
      padding-right: 16px;
      width: max-content;
    }
  }
`;
