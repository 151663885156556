import Box from '@material-ui/core/Box';
import FormHelperText from '@material-ui/core/FormHelperText';
import InputAdornment from '@material-ui/core/InputAdornment';
import { TextFieldProps } from '@material-ui/core/TextField';
import Tooltip from '@material-ui/core/Tooltip';
import ReactHtmlParser from 'html-react-parser';
import _ from 'lodash';
import React, { useState } from 'react';

import { FieldSymbol, ValidationError } from '@breathelife/questionnaire-engine';
import { Language } from '@breathelife/types';

import { FieldProps, getFieldLabel, InputVariant } from './Helpers/FieldHelper';
import { Container, SubtitleText, TitleText, WhiteSpaceContainer } from './Styles/CommonStyles';
import { StyledFormControlLabel, StyledFormHelperText, StyledTextField } from './Styles/FieldStyles';

type Props<T extends string | number> = TextFieldProps &
  Omit<FieldProps, 'onAnswerChange'> & {
    symbol?: FieldSymbol;
    locale?: Language;
    value?: T;
    onAnswerChange?: (answer: T) => void;
    onAnswerComplete?: (fieldId: string, answer: T, previousAnswer?: T) => void;
    inputVariant?: InputVariant;
    allowTextWrap?: boolean;
  };

type State = {
  value?: string | number;
};

export class TextInput<T extends string | number> extends React.Component<Props<T>, State> {
  state = {
    value: this.props.value,
  };

  debouncedParentOnAnswerChange: Function = this.props.onAnswerChange
    ? _.debounce(this.props.onAnswerChange, 200)
    : () => null;

  onAnswerChange = (answer: string | number): void => {
    this.setState({ value: answer }, () => {
      this.debouncedParentOnAnswerChange(this.state.value);
    });
  };

  onBlur = (): void => {
    if (!this.props.onAnswerChange || typeof this.state.value === 'undefined') return;

    const trimmedValue = typeof this.state.value === 'string' ? (this.state.value.trim() as T) : this.state.value;

    this.setState({ value: trimmedValue }, () => {
      if (this.props.onAnswerChange) this.props.onAnswerChange(trimmedValue);
    });
  };

  render(): React.ReactElement {
    const inputProps: any = _.cloneDeep(this.props);

    if (this.props.symbol) {
      const adornment = `${_.get(this.props, ['symbol', 'position'], 'start')}Adornment`;
      inputProps[adornment] = (
        <InputAdornment position={this.props.symbol.position || 'start'}>{this.props.symbol.name}</InputAdornment>
      );
    }

    inputProps.onAnswerChange = this.onAnswerChange;

    // Read only means there is no local state to handle. Always use the value coming from props
    if (!inputProps.isReadOnly) {
      inputProps.value = this.state.value;
    }

    return <TextFieldMUI onBlur={this.onBlur} {...inputProps} />;
  }
}

export function TextFieldMUI<T extends string | number>(props: Props<T>): React.ReactElement {
  const {
    name,
    type,
    value,
    label,
    title,
    disabled = false,
    placeholder,
    validationError,
    onAnswerChange,
    onAnswerComplete,
    onBlur,
    onKeyDown,
    InputLabelProps,
    inputVariant,
    inputRef,
    subtitle,
    allowTextWrap,
    multiline = false,
    rows = 1,
    rowsMax = 1,
    isReadOnly,
    required,
  } = props;
  const showError = !!validationError;
  const onChangeHandler =
    props.onChange ||
    ((event: any) => {
      onAnswerChange && onAnswerChange(event.target.value);
    });

  const [valueOnPreviousBlur, setValueOnPreviousBlur] = useState(value);
  const textFieldProps = {
    name,
    type,
    disabled,
    placeholder,
    error: showError,
    value,
    InputLabelProps,
    label,
    title,
    subtitle,
    inputRef,
    'data-testid': props['data-testid'],
    onChange: onChangeHandler,
    onBlur: (event: React.FocusEvent<HTMLInputElement>) => {
      onBlur && onBlur(event);

      if (typeof value !== 'undefined') {
        onAnswerComplete && onAnswerComplete(name, value, valueOnPreviousBlur);
        setValueOnPreviousBlur(value);
      }
    },
    onKeyDown,
    multiline,
    rows,
    rowsMax,
    allowTextWrap,
    isReadOnly,
    required,
  };

  return <Input {...textFieldProps} inputVariant={inputVariant} validationError={validationError} />;
}

export function Input(
  props: Omit<TextFieldProps, 'variant'> & {
    highlighted?: boolean;
    inputVariant?: InputVariant;
    validationError?: ValidationError;
    labelTooltip?: {
      icon: React.ReactElement;
      text: string;
    };
    inputTooltip?: {
      icon: React.ReactElement;
      text: string;
    };
    allowTextWrap?: boolean;
    title?: string;
    subtitle?: string;
    isReadOnly?: boolean;
  }
): React.ReactElement {
  const {
    inputVariant,
    validationError,
    inputTooltip,
    label,
    title,
    labelTooltip,
    placeholder,
    subtitle,
    allowTextWrap,
    isReadOnly,
    ...textFieldProps
  } = props;
  const labelValue = getFieldLabel({ label: label as string, title });
  const error = textFieldProps.error ?? !!validationError;
  const wrapText = allowTextWrap ?? false;

  if (inputVariant === 'outlined') {
    const requiredSuffix = textFieldProps.required ? ' *' : '';
    const labelText = labelValue ? ReactHtmlParser(`${labelValue}${requiredSuffix}`) : null;
    const controlInput = inputTooltip ? (
      <Box display='flex' alignItems='center'>
        <StyledTextField {...textFieldProps} error={!!error} placeholder={placeholder} variant={inputVariant} />
        <Tooltip title={inputTooltip.text} placement='top'>
          <Box lineHeight='0' ml={1}>
            {inputTooltip.icon}
          </Box>
        </Tooltip>
      </Box>
    ) : (
      <StyledTextField {...textFieldProps} error={!!error} placeholder={placeholder} variant={inputVariant} />
    );

    return (
      <Container error={!!error}>
        <StyledFormControlLabel
          showError={!!error}
          control={controlInput}
          label={
            <WhiteSpaceContainer $wrapContent={wrapText}>
              <Box display='flex' alignItems='center'>
                {/* Read only field title should always appears as not disabled even if the field input is disabled*/}
                {isReadOnly ? <TitleText>{labelText}</TitleText> : <span>{labelText} </span>}
                {labelTooltip && (
                  <Tooltip title={ReactHtmlParser(labelTooltip.text)} placement='top'>
                    <Box lineHeight='0' ml={1}>
                      {labelTooltip.icon}
                    </Box>
                  </Tooltip>
                )}
              </Box>
              {subtitle ? <SubtitleText>{ReactHtmlParser(subtitle)}</SubtitleText> : null}
            </WhiteSpaceContainer>
          }
          labelPlacement='top'
        />
        {validationError && <StyledFormHelperText>{validationError.message}</StyledFormHelperText>}
      </Container>
    );
  }

  return (
    <Container error={!!error}>
      <StyledTextField
        {...textFieldProps}
        label={<WhiteSpaceContainer $wrapContent={wrapText}>{labelValue}</WhiteSpaceContainer>}
      />
      {validationError && <FormHelperText>{validationError.message}</FormHelperText>}
    </Container>
  );
}
