import React from 'react';

import { Spacer, TitleAndDescriptionContainer, ActionsContainer, Title, Description, Form } from './Styles';

type Props = {
  title: string;
  description?: string;
  children?: React.ReactNode;
  actions?: React.ReactNode;
  onSubmit: () => void;
  omitFormLabel?: boolean;
};

export const STEP_ARIA_LABELLED_BY = {
  title: 'question-title',
  description: 'question-description',
};

export function StepLayout(props: Props): React.ReactElement {
  let labelledby = '';

  if (!props.omitFormLabel) {
    labelledby = props.description
      ? `${STEP_ARIA_LABELLED_BY.title} ${STEP_ARIA_LABELLED_BY.description}`
      : STEP_ARIA_LABELLED_BY.title;
  }

  return (
    <Form onSubmit={props.onSubmit} role='form' name='question' aria-labelledby={labelledby}>
      <TitleAndDescriptionContainer>
        <Title id={STEP_ARIA_LABELLED_BY.title}>{props.title}</Title>
        {props.description && <Description id={STEP_ARIA_LABELLED_BY.description}>{props.description}</Description>}
      </TitleAndDescriptionContainer>
      <Spacer>{props.children}</Spacer>
      {props.actions && <ActionsContainer>{props.actions}</ActionsContainer>}
    </Form>
  );
}
