import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import { SelectProps } from '@material-ui/core/Select';
import ReactHtmlParser from 'html-react-parser';
import React from 'react';

import { RenderingFieldOption } from '@breathelife/questionnaire-engine';

import { Text } from '../Typography';
import { FieldProps, getFieldLabel, InputVariant } from './Helpers/FieldHelper';
import { WarningText } from './Styles/CommonStyles';
import { StyledFormControlLabel, StyledSelect } from './Styles/FieldStyles';

type Props = FieldProps &
  Omit<SelectProps, 'onAnswerChange'> & {
    options: RenderingFieldOption[];
    required?: boolean;
    value?: string;
    onAnswerChange?: (answer: string) => void;
    onAnswerComplete?: (field: string, answer: string, previousAnswer?: string) => void;
    inputVariant?: InputVariant;
  };

const MUI_NONE = '__none__';

export function SelectMUI(props: Props): React.ReactElement {
  const showError = !!props.validationError;

  const {
    label,
    title,
    disabled,
    name,
    options,
    required,
    onAnswerChange,
    onAnswerComplete,
    value,
    inputVariant,
    placeholder,
  } = props;
  const labelValue = getFieldLabel({ label, title });
  const requiredSuffix = required ? ' *' : '';
  const labelText = labelValue ? ReactHtmlParser(`${labelValue}${requiredSuffix}`) : null;
  const selectFieldProps = {
    name,
    disabled,
    value: value === '' ? MUI_NONE : value,
    onChange: (event: any) => {
      const updatedValue = event.target.value === MUI_NONE ? '' : event.target.value;
      onAnswerChange && onAnswerChange(updatedValue);
      onAnswerComplete && onAnswerComplete(name, updatedValue, value);
    },
  };

  const menuOptions = options.map((option) => {
    // Force undefined disabled value for the option if the radio group
    // is disabled otherwise the UI won't show as disabled
    const itemDisabled = disabled ? undefined : option.disabled;
    return (
      <MenuItem
        key={option.id}
        value={option.id === '' ? MUI_NONE : option.id}
        disabled={itemDisabled}
        data-testid={`${props.id}_${option.id}`}
      >
        {ReactHtmlParser(option.text)}
      </MenuItem>
    );
  });

  menuOptions.unshift(
    <MenuItem key={MUI_NONE} value={MUI_NONE}>
      <Text grey={70} component='span'>
        {`- ${placeholder} -`}
      </Text>
    </MenuItem>
  );

  if (inputVariant === 'outlined') {
    return (
      <FormControl fullWidth error={showError} required={required}>
        <StyledFormControlLabel
          showError={showError}
          control={
            <StyledSelect {...selectFieldProps} variant={inputVariant}>
              {menuOptions}
            </StyledSelect>
          }
          label={labelText}
          labelPlacement='top'
        />
        {props.validationError && <WarningText>{props.validationError.message}</WarningText>}
      </FormControl>
    );
  }

  return (
    <FormControl fullWidth error={showError} required={required}>
      <InputLabel>{labelText}</InputLabel>
      <StyledSelect {...selectFieldProps}>{menuOptions}</StyledSelect>
      {props.validationError && <WarningText>{props.validationError.message}</WarningText>}
    </FormControl>
  );
}
