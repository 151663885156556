import { TextField } from '@material-ui/core';
import { CardCvcElement, CardExpiryElement, CardNumberElement } from '@stripe/react-stripe-js';
import styled, { css } from 'styled-components';

const FieldCommonStyle = css`
  border: 1px solid ${(props) => props.theme.colors.grey[50]};
  border-radius: 4px;
  padding: 16px 12px;

  &:hover {
    border-color: ${(props) => props.theme.colors.grey[60]};
  }
`;

const FocusedStyle = css`
  border-color: ${(props) => props.theme.colors.grey[60]};
`;

const ErrorStyle = css`
  &&,
  &&:hover {
    border-color: ${(props) => props.theme.colors.error};
  }
`;

export const StyledCardNumberElement = styled(CardNumberElement)<{ $error?: boolean }>`
  ${FieldCommonStyle};
  ${(props) => (props.$error ? ErrorStyle : undefined)}

  input {
    caret-color: ${(props) => props.theme.colors.primary[40]};
  }

  &.StripeElement--invalid {
    ${ErrorStyle}
  }

  &.StripeElement--focus {
    ${FocusedStyle}
    ${(props) => (props.$error ? ErrorStyle : undefined)}
  }
`;

export const StyledCardExpiryElement = styled(CardExpiryElement)<{ $error?: boolean }>`
  ${FieldCommonStyle};
  ${(props) => (props.$error ? ErrorStyle : undefined)}

  &.StripeElement--invalid {
    ${ErrorStyle}
  }

  &.StripeElement--focus {
    ${FocusedStyle}
    ${(props) => (props.$error ? ErrorStyle : undefined)}
  }
`;

export const StyledCardCvcElement = styled(CardCvcElement)<{ $error?: boolean }>`
  ${FieldCommonStyle};
  ${(props) => (props.$error ? ErrorStyle : undefined)}

  &.StripeElement--invalid {
    ${ErrorStyle}
  }

  &.StripeElement--focus {
    ${FocusedStyle}
    ${(props) => (props.$error ? ErrorStyle : undefined)}
  }
`;

export const StripeTextField = styled(TextField)`
  ${FieldCommonStyle};

  &&&&& {
    input {
      padding: 16px 12px;
    }

    .Mui-error {
      ${ErrorStyle}
    }

    .Mui-focused {
      input {
        caret-color: ${(props) => props.theme.colors.primary[40]};
      }

      .MuiOutlinedInput-notchedOutline {
        ${FocusedStyle};
        border-width: 1px;
      }
    }
  }
`;
