import React from 'react';

export function BulletCheckmark(): React.ReactElement {
  return (
    <svg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <rect y='2' width='12' height='12' rx='2' fill='#27AE60' />
      <path
        transform='translate(-1 1)'
        fillRule='evenodd'
        clipRule='evenodd'
        d='M5.25838 8.99668L3.42259 7.23433C3.09715 6.92191 2.56951 6.92191 2.24408 7.23433C1.91864 7.54675 1.91864 8.05328 2.24408 8.36569L4.74406 10.7657C5.09734 11.1048 5.67992 11.0714 5.98858 10.6942L11.8219 4.29429C12.1062 3.94687 12.0434 3.44395 11.6815 3.17098C11.3196 2.89801 10.7957 2.95836 10.5114 3.30578L5.25838 8.99668Z'
        fill='white'
      />
    </svg>
  );
}
