import Box from '@material-ui/core/Box';
import React, { useState } from 'react';
import styled from 'styled-components';

import { HeaderButton } from '../Button/HeaderButton';
import { TextButton } from '../Button/TextButton';
import { DraggableToolbar } from './DraggableToolbar';

type Props = {
  children?: React.ReactNode;
  onCreateNewApplication?: () => void;
  onLoadPrefillSteps?: () => void;
  onLoadNavigateSteps?: () => void;
};

const Container = styled(Box)`
  display: flex;
  flex-direction: column;
  background-color: rgba(170, 105, 255, 0.25);
  border: 3px solid rgba(170, 105, 255, 0.75);
  border-radius: 4px;
  width: 210px;
  align-items: center;
`;

const StyledButton = styled(HeaderButton)`
  &&& {
    display: flex;
    width: 100%;
    margin: 5px 0;
    justify-content: center;
  }
`;

export function DebugToolbar(props: Props): React.ReactElement {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const onHackThePlanet = (): void => {
    const url = 'https://youtu.be/u3CKgkyc7Qo?t=18';
    window.open(url, '_blank');
  };

  return (
    <DraggableToolbar>
      <Container p={1}>
        <TextButton
          className={'debug-toolbar-handle'}
          onClick={() => {
            setIsMenuOpen(!isMenuOpen);
          }}
        >
          <strong>
            <span role='img' aria-label='laptop'>
              💻
            </span>
            {isMenuOpen ? 'Hide debug tools' : 'Show debug tools'}
          </strong>
        </TextButton>
        {isMenuOpen && (
          <Box my={1}>
            {props.onCreateNewApplication && (
              <StyledButton onClick={props.onCreateNewApplication}>
                <span role='img' aria-label='gem'>
                  💎
                </span>
                Create new application
              </StyledButton>
            )}
            {props.onLoadPrefillSteps && (
              <StyledButton onClick={props.onLoadPrefillSteps}>
                <span role='img' aria-label='wand'>
                  🪄
                </span>
                Pre-fill application
              </StyledButton>
            )}
            {props.onLoadNavigateSteps && (
              <StyledButton onClick={props.onLoadNavigateSteps}>
                <span role='img' aria-label='helicopter'>
                  🚁
                </span>
                Skip to step
              </StyledButton>
            )}
            <StyledButton onClick={onHackThePlanet}>
              <span role='img' aria-label='planet'>
                🌎
              </span>
              Hack the planet
            </StyledButton>
            {props.children}
          </Box>
        )}
      </Container>
    </DraggableToolbar>
  );
}
