import React from 'react';
import { useTheme } from 'styled-components';

import { IconName } from '@breathelife/types';

import { SharedTheme } from 'Theme/Types';

import { Icon } from '../../Icon/Icon';
import {
  CloseButton,
  Container,
  CustomerContent,
  CustomerName,
  CustomerNameContainer,
  HeaderColors,
  RefNo,
} from './Styles';

export type HeaderVariant = 'default' | 'primary' | 'secondary' | 'light';

type AgentHeaderProps = {
  customerName?: string;
  isAuthenticated: boolean;
  refNo?: string;
  variant?: HeaderVariant;
  text: (key: string, params?: { [key: string]: any }) => string;
  onClose: () => void;
};

function handleVariant(theme: SharedTheme, variant: HeaderVariant | undefined): HeaderColors {
  let backgroundColor = theme.colors.primary.default;
  let customerNameColor = theme.colors.grey[0];
  let referenceNumberColor = theme.colors.grey[30];
  let closeIconColor = theme.colors.grey[30];

  switch (variant) {
    case 'primary':
    case 'secondary':
      backgroundColor = theme.colors[variant][50];
      customerNameColor = theme.colors.grey[0];
      referenceNumberColor = theme.colors.grey[30];
      closeIconColor = theme.colors.grey[30];
      break;
    case 'light':
      backgroundColor = theme.colors.grey[0];
      customerNameColor = theme.colors.grey[90];
      referenceNumberColor = theme.colors.grey[50];
      closeIconColor = theme.colors.grey[50];
      break;
    default:
      break;
  }

  return {
    backgroundColor,
    closeIconColor,
    customerNameColor,
    referenceNumberColor,
  };
}

export function AgentHeader(props: AgentHeaderProps): React.ReactElement | null {
  const { customerName, isAuthenticated, onClose, refNo, text, variant } = props;

  const theme = useTheme() as SharedTheme;
  const headerColors = handleVariant(theme, variant);

  if (!isAuthenticated || !customerName) {
    return null;
  }

  return (
    <Container {...headerColors}>
      <CustomerContent>
        <CustomerNameContainer>
          {text('agent.header.client')}
          <CustomerName>&nbsp;{customerName}</CustomerName>
        </CustomerNameContainer>
        {refNo && (
          <RefNo>
            {text('agent.header.refNo')}: {refNo}
          </RefNo>
        )}
      </CustomerContent>
      <CloseButton onClick={onClose}>
        <Icon name={IconName.close2} color={{ primary: headerColors.closeIconColor }} size='20px' />
      </CloseButton>
    </Container>
  );
}
