import { Box } from '@material-ui/core';
import Hidden from '@material-ui/core/Hidden';
import _ from 'lodash';
import React from 'react';

import { ProgressBar } from '../ProgressBar/ProgressBar';
import { ChildrenWrapper, Header, HorizontalLayout, VerticalLayout } from './CenteredLayoutStyles';
import { FlexWrapper } from './SharedStyles';

type BaseProps = {
  leftContent?: React.ReactNode;
  rightContent?: React.ReactNode;
  progress?: number;
  progressText?: string;
  productSummary?: React.ReactNode;
};

export function CenteredLayoutHeader(props: BaseProps): React.ReactElement {
  const ChildrenContent = (
    <ChildrenWrapper>
      {!_.isNil(props.progress) && props.progressText ? (
        <span id='progress_completed'>{props.progressText}</span>
      ) : (
        <>
          <span id='progress_completed'></span>
          <FlexWrapper />
        </>
      )}
    </ChildrenWrapper>
  );

  const productSummaryLeftContent = (
    <Box display='flex'>
      {props.leftContent}
      <Box ml={3}>{props.productSummary}</Box>
    </Box>
  );

  const desktopLeftContent = props.productSummary ? (
    productSummaryLeftContent
  ) : (
    <FlexWrapper>{props.leftContent}</FlexWrapper>
  );

  const mobileContent = (
    <Hidden lgUp>
      <VerticalLayout>
        <HorizontalLayout>
          {(props.leftContent || props.rightContent) && <FlexWrapper>{props.leftContent}</FlexWrapper>}
          {(props.leftContent || props.rightContent) && <FlexWrapper>{props.rightContent}</FlexWrapper>}
        </HorizontalLayout>
        {
          <Box mx={3} mb={1}>
            {props.productSummary}
          </Box>
        }
        {!props.productSummary && ChildrenContent}
      </VerticalLayout>
    </Hidden>
  );

  const desktopContent = (
    <Hidden mdDown>
      <HorizontalLayout>
        {(props.leftContent || props.rightContent) && desktopLeftContent}
        {!props.productSummary && ChildrenContent}
        {(props.leftContent || props.rightContent) && <FlexWrapper>{props.rightContent}</FlexWrapper>}
      </HorizontalLayout>
    </Hidden>
  );

  return (
    <Header>
      {mobileContent}
      {desktopContent}
      <ProgressBar progress={props.progress} aria-label='progress_completed' />
    </Header>
  );
}
