import { List as MuiList, ListItem, Collapse, Box } from '@material-ui/core';
import React, { useCallback, useState, useMemo, useEffect } from 'react';
import styled, { useTheme } from 'styled-components';

import { SharedTheme } from 'Theme';

import { Icon, IconProps } from '../Icon/Icon';
import { ProducerTypography, TypographyComponents, TypographyVariants } from '../ProducerTypography/ProducerTypography';

const ICON_SIZE = 18;
const SPACE_BETWEEN_ICON_AND_TEXT = 8;
const BASE_ITEM_LEFT_PADDING = 24;
const SELECTED_LEFT_BAR_WIDTH = 4;

export type ProducerNavigationItemData = {
  identifier: string;
  text?: string;
  icon?: IconProps;
  children?: ProducerNavigationItemData[];
  onClick?: () => void;
};

const StyledListItem = styled(ListItem)`
  padding: 8px;
  &.MuiListItem-root {
    transition: background-color 0.25s;
    &:hover {
      background-color: ${(props) => props.theme.colors.grey[30]};
    }
    &:active {
      background-color: ${(props) => props.theme.colors.grey[40]};
    }
    &.Mui-selected {
      background-color: ${(props) => props.theme.colors.grey[30]};
      &:hover {
        background-color: ${(props) => props.theme.colors.grey[30]};
      }
      &:active {
        background-color: ${(props) => props.theme.colors.grey[40]};
      }
    }
  }
`;

const ListItemIconWithoutMinWidth = styled(Box)`
  min-width: 0;
  margin-right: ${SPACE_BETWEEN_ICON_AND_TEXT}px;
  display: inline-flex;
  flex-shrink: 0;
`;

const ListItemContentWithLeftPadding = styled(Box)<{ level: number }>`
  padding-left: ${({ level }) => `${level * (SPACE_BETWEEN_ICON_AND_TEXT + ICON_SIZE) + BASE_ITEM_LEFT_PADDING}px`};
  display: flex;
  align-items: center;
  width: 100%;
  padding-right: 16px;
`;

const SelectedListItemLeftBar = styled(Box)<{ visible: boolean }>`
  align-self: stretch;
  width: ${SELECTED_LEFT_BAR_WIDTH}px;
  transition: background-color 0.25s;
  background-color: ${(props) => (props.visible ? props.theme.colors.primary.default : 'transparent')};
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  border-top-right-radius: 2px;
  border-bottom-right-radius: 2px;
`;

type ListItemProps = {
  level: number;
  selected?: boolean;
  childIsSelected?: boolean;
  onClick?: (identifier: string) => void;
};

function ProducerNavigationListItem({
  text,
  icon,
  selected,
  childIsSelected,
  level,
  onClick,
  identifier,
}: Omit<ProducerNavigationItemData, 'children'> & ListItemProps): React.ReactElement {
  const theme = useTheme() as SharedTheme;
  return (
    <StyledListItem
      button
      onClick={() => onClick?.(identifier)}
      disableGutters
      selected={selected}
      disableRipple
      disableTouchRipple
    >
      <SelectedListItemLeftBar visible={Boolean(selected)} />
      <ListItemContentWithLeftPadding level={level}>
        {icon && (
          <ListItemIconWithoutMinWidth>
            <Icon
              {...icon}
              size={icon.size || `${ICON_SIZE}px`}
              color={{
                primary: selected || childIsSelected ? undefined : theme.colors.grey[70],
                secondary: selected || childIsSelected ? undefined : theme.colors.grey[30],
              }}
            />
          </ListItemIconWithoutMinWidth>
        )}

        <ProducerTypography
          variant={TypographyVariants.body3}
          component={TypographyComponents.span}
          color={selected ? theme.colors.primary.default : theme.colors.grey[level === 0 ? 80 : 70]}
        >
          {text}
        </ProducerTypography>
      </ListItemContentWithLeftPadding>
    </StyledListItem>
  );
}

function ProducerNavigationListItemAndChildren({
  children,
  selectedItemKey,
  onClick,
  automaticallyOpenParentOfSelected,
  ...itemData
}: ProducerNavigationItemData &
  ListItemProps & { selectedItemKey?: string; automaticallyOpenParentOfSelected?: boolean }): React.ReactElement {
  const childIsSelected = useMemo(
    () =>
      children?.some((child) => {
        return child.identifier === selectedItemKey;
      }),
    [children, selectedItemKey]
  );

  const [isOpen, setIsOpen] = useState<boolean>(automaticallyOpenParentOfSelected ? Boolean(childIsSelected) : false);

  useEffect(() => {
    if (automaticallyOpenParentOfSelected) {
      setIsOpen(Boolean(childIsSelected));
    }
  }, [automaticallyOpenParentOfSelected, childIsSelected]);

  const handleClick = useCallback(() => {
    onClick?.(itemData.identifier);
    setIsOpen((prev) => !prev);
  }, [setIsOpen, onClick, itemData.identifier]);

  return (
    <React.Fragment>
      <ProducerNavigationListItem
        {...itemData}
        onClick={handleClick}
        selected={selectedItemKey === itemData.identifier}
        childIsSelected={childIsSelected}
      />
      <Collapse in={isOpen} timeout='auto' unmountOnExit>
        <MuiList component='div' disablePadding>
          {children?.map((item) => {
            return (
              <ProducerNavigationListItem
                {...item}
                onClick={item.onClick}
                key={item.identifier}
                level={itemData.level + 1}
                selected={selectedItemKey === item.identifier}
              />
            );
          })}
        </MuiList>
      </Collapse>
    </React.Fragment>
  );
}

type ProducerNavigationProps = {
  data: ProducerNavigationItemData[];
  selectedItemKey?: string;
  automaticallyOpenParentOfSelected?: boolean;
};

export function ProducerNavigation({
  data,
  selectedItemKey,
  automaticallyOpenParentOfSelected,
}: ProducerNavigationProps): React.ReactElement {
  return (
    <MuiList component='nav'>
      {data.map((item) => (
        <ProducerNavigationListItemAndChildren
          {...item}
          onClick={item.onClick}
          key={item.identifier}
          level={0}
          selectedItemKey={selectedItemKey}
          automaticallyOpenParentOfSelected={automaticallyOpenParentOfSelected}
        />
      ))}
    </MuiList>
  );
}
