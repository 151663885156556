import React, { useCallback, useRef, useState } from 'react';
import Draggable from 'react-draggable';
import styled from 'styled-components';

type DebugToolbarContainerProps = {
  children: React.ReactNode;
};

const Container = styled.div`
  position: absolute;
  z-index: 1500;
`;

export function DraggableToolbar(props: DebugToolbarContainerProps): React.ReactElement | null {
  const { children } = props;

  const dragRef = useRef(null);
  const [isDragging, setIsDragging] = useState(false);

  const onDrag = useCallback(() => {
    setIsDragging(true);
  }, []);

  const onStop = useCallback(() => {
    if (isDragging) {
      setIsDragging(false);
      return;
    }
  }, [isDragging]);

  if (window.TESTCAFE_RUN) {
    return null;
  }

  return (
    <Draggable nodeRef={dragRef} onDrag={onDrag} onStop={onStop} defaultPosition={{ x: 5, y: 5 }}>
      <Container ref={dragRef}>{children}</Container>
    </Draggable>
  );
}
