import React from 'react';

import {
  ContactInfoContainer,
  ContactInfoIcon,
  ContactInfoLeftSection,
  ContactInfoRightSection,
  ContactInfoHighlight,
} from './Styles';

type ContactDetailProps = {
  icon: React.ReactElement;
  label: string;
  content: string;
  link: string;
};

export function ContactDetail(props: ContactDetailProps): React.ReactElement {
  return (
    <a href={props.link} title={props.link}>
      <ContactInfoContainer>
        <ContactInfoLeftSection>
          <ContactInfoIcon>{props.icon}</ContactInfoIcon>
        </ContactInfoLeftSection>
        <ContactInfoRightSection>
          <span>{props.label}</span>
          <ContactInfoHighlight>{props.content}</ContactInfoHighlight>
        </ContactInfoRightSection>
      </ContactInfoContainer>
    </a>
  );
}
