import { ButtonProps, Button } from 'semantic-ui-react';
import styled, { css } from 'styled-components';

const disabledButtonStyle = css`
  color: ${(props) => props.theme.colors.white};
  cursor: not-allowed;
  opacity: 0.3;
  box-shadow: none;
  transform: translateY(5px);
`;

export const buttonStyle = css`
  display: flex;
  align-items: center;
  justify-content: center;
  border-style: solid;
  border-width: ${(props) => props.theme.metrics.borders.button};
  border-color: ${(props) => props.theme.colors.button.base.border.default};
  border-radius: ${(props) => props.theme.metrics.button.borderRadius};
  font-size: ${(props) => props.theme.fonts.size.button.submit};
  font-family: ${(props) => props.theme.fonts.family.button};
  font-weight: ${(props) => props.theme.fonts.weight.button};
  text-align: center;
  padding: 10px 20px;
  background-color: ${(props) => props.theme.colors.button.base.background.default};
  color: ${(props) => props.theme.colors.button.base.text.default};
  transition: all ${(props) => props.theme.animation.speed.medium} ${(props) => props.theme.animation.transition};
  box-shadow: 0 8px 16px -4px rgba(9, 30, 66, 0.25);
  min-width: 180px;
  min-height: 54px;
  line-height: 1.5em;
  text-transform: uppercase;
  margin: 0 auto;
  span {
    /*
      The text is not centered otherwise since
      the Eina01 font we are using might have too much whitespace
      at the bottom, making the text seem elevated
     */
    transform: translateY(2px);
  }
  @media (min-width: ${(props) => props.theme.breakpoints.tablet.min}) {
    margin: 0;
  }
  &:not(:disabled) {
    ${(props: ButtonProps) =>
      !props.disabled &&
      css`
        &:focus {
          outline: 0;
          border-color: ${(props) => props.theme.colors.button.base.border.focus} !important;
          background-color: ${(props) => props.theme.colors.button.base.background.focus};
        }
        &:hover {
          outline: 0;
          &:not(:focus) {
            border-color: ${(props) => props.theme.colors.button.base.border.hover};
          }
          background-color: ${(props) => props.theme.colors.button.base.background.hover};
        }
        &:active {
          outline: 0;
          &:not(:focus) {
            border-color: ${(props) => props.theme.colors.button.base.border.active};
          }
          background-color: ${(props) => props.theme.colors.button.base.background.active};
        }
      `}
  }
  ${(props: ButtonProps) => props.disabled && disabledButtonStyle}
  &:disabled {
    ${disabledButtonStyle}
  }
`;

export const StyledButton = styled(Button)`
  &&&& {
    ${buttonStyle}
  }
`;
