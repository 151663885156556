import Grid from '@material-ui/core/Grid';
import styled from 'styled-components';

export const ZebraGrid = styled(Grid)`
  > div:nth-child(2n) {
    background-color: ${(props) => props.theme.colors.grey[20]};
  }
  background-color: ${(props) => props.theme.colors.grey[0]};
  row-gap: 8px;

  color: ${(props) => props.theme.colors.grey[90]};
`;
