import styled, { css } from 'styled-components';

const sharedStyle = css`
  display: flex;
  align-items: center;
  justify-content: center;

  border: none;
  outline: none;
  box-sizing: border-box;

  padding: 16px;
  line-height: 20px;
  font-size: 16px;
  text-align: center;
  font-weight: bold;
  font-family: ${(props) => props.theme.fonts.family.button};

  color: ${(props) => props.theme.colors.primary.default};
  background: transparent;
  mix-blend-mode: normal;

  svg {
    margin-right: 8px;
  }

  &:not(:disabled) {
    cursor: pointer;

    &:hover {
      background-color: ${(props) => props.theme.colors.grey[20]};
      color: ${(props) => props.theme.colors.primary[60]};
    }

    &:focus {
      outline: 3px solid ${(props) => props.theme.colors.hyperlink};
    }

    &:active {
      background-color: ${(props) => props.theme.colors.grey[30]};
      color: ${(props) => props.theme.colors.primary[70]};
    }
  }

  &:disabled {
    cursor: not-allowed;
    color: ${(props) => props.theme.colors.grey[60]};
  }
`;

export const GhostButton = styled.button.attrs({
  type: 'button',
  role: 'button',
})`
  ${sharedStyle}
`;

export const GhostLinkButton = styled.a`
  ${sharedStyle}
`;
