import React, { useLayoutEffect, useRef } from 'react';

import { CloverEvent, CloverItem, Elements } from '@breathelife/types';

import { FieldContainer, styles } from './Style';

type Props = {
  elementType: string;
  elements: Elements;
  fieldContainerId: string;
  onChange: (event: CloverEvent) => void;
  updateElementsRenderedCount: () => void;
};

export function CloverElement(props: Props): React.ReactElement {
  const { elements, elementType, fieldContainerId, onChange, updateElementsRenderedCount } = props;
  const cloverElementRef = useRef<CloverItem>();
  const fieldDomNodeRef = useRef<HTMLInputElement | null>(null);

  useLayoutEffect(() => {
    if (!cloverElementRef.current && fieldDomNodeRef.current) {
      const createdCloverElement = elements.create(elementType, styles);
      cloverElementRef.current = createdCloverElement;
      createdCloverElement.mountNode(fieldDomNodeRef.current);
      createdCloverElement.addEventListener('change', onChange);
      createdCloverElement.mountedIFrame.onload = updateElementsRenderedCount;
    }

    return () => {
      if (cloverElementRef.current) {
        cloverElementRef.current = undefined;
      }
    };
  }, [elementType, elements, updateElementsRenderedCount, onChange]);

  return <FieldContainer id={fieldContainerId} ref={fieldDomNodeRef} />;
}
