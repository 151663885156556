import styled from 'styled-components';

export const styles = {
  body: {
    fontFamily: 'Roboto, Open Sans, sans-serif',
    fontSize: '16px',
  },
  input: {
    fontSize: '13px',
    border: '1px solid #aaa',
    height: '50px',
    borderRadius: '10px',
    padding: '8px',
  },
  img: {
    right: '10px !important',
    top: '10px !important',
  },
};

export const FieldContainer = styled.div`
  height: 50px;
  padding: 0;
  margin: 0 5px;
  width: 100%;
`;
