import React from 'react';
import { CheckboxProps } from 'semantic-ui-react';

import { InputBoxContainer, StyledSimpleCheckbox } from './Styles';

export function CheckBox({ className, ...props }: CheckboxProps): React.ReactElement {
  return (
    <InputBoxContainer className={className}>
      <StyledSimpleCheckbox id={props.value} {...props} />
    </InputBoxContainer>
  );
}
