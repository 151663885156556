import React from 'react';
import { Helmet } from 'react-helmet';

type Props = {
  googleMapsApiKey: string;
};

function GoogleMapsHelmetComponent(props: Props): React.ReactElement | null {
  if (window.google?.maps || window.TESTCAFE_RUN) return null;

  return (
    <Helmet>
      <script
        src={`https://maps.googleapis.com/maps/api/js?key=${props.googleMapsApiKey}&libraries=places`}
        type='text/javascript'
      />
    </Helmet>
  );
}

export const GoogleMapsHelmet = React.memo(GoogleMapsHelmetComponent);
