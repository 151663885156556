import { StandardTextFieldProps } from '@material-ui/core/TextField';
import _ from 'lodash';
import React, { useCallback, useState } from 'react';

import { FieldProps, getFieldLabel, InputVariant } from './Helpers/FieldHelper';
import { Input } from './TextInput';

type DatePickerProps = Omit<FieldProps, 'onAnswerChange'> & {
  inputVariant?: InputVariant;
  onAnswerChange?: (answer: string) => void;
  onAnswerComplete?: (fieldId: string, answer: string | number, previousAnswer?: string | number) => void;
  required?: boolean;
  value?: string;
};

type Props = StandardTextFieldProps & DatePickerProps;

export function DatePicker(props: Props): React.ReactElement {
  const {
    disabled,
    inputVariant,
    label,
    name,
    onAnswerChange,
    onAnswerComplete,
    required,
    subtitle,
    title,
    validationError,
    value: defaultValue,
  } = props;
  const [value, setValue] = useState(defaultValue);
  const [valueOnPreviousBlur, setValueOnPreviousBlur] = useState(defaultValue);
  const labelValue = getFieldLabel({ label, title });
  const requiredSuffix = required ? ' *' : '';
  const labelText = labelValue ? `${labelValue}${requiredSuffix}` : null;

  const onChange = useCallback(
    (answer: string): void => {
      if (!onAnswerChange) return;
      setValue(answer);
      _.debounce(() => onAnswerChange(answer), 200);
    },
    [onAnswerChange]
  );

  const onBlur = (): void => {
    if (onAnswerChange && typeof value !== 'undefined') {
      onAnswerChange(value);
    }
    if (onAnswerComplete && typeof value !== 'undefined') {
      onAnswerComplete(name, value, valueOnPreviousBlur);
    }
    setValueOnPreviousBlur(value);
  };

  return (
    <Input
      data-testid={props['data-testid']}
      name={name}
      error={!!validationError}
      label={labelText}
      subtitle={labelText ? subtitle : `${subtitle}${requiredSuffix}`}
      validationError={validationError}
      inputVariant={inputVariant}
      disabled={disabled}
      type='date'
      value={value}
      onBlur={onBlur}
      inputProps={{ max: '9999-12-31' }}
      onChange={(event: React.ChangeEvent<HTMLInputElement>) => onChange(event.target.value)}
    />
  );
}
