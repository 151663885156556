import React from 'react';
import { useTheme } from 'styled-components';

import { SVGIconProps } from 'Components/Icon/IconMap';

import { SharedTheme } from '../../Theme';

export function SuccessOutlined(props: SVGIconProps): React.ReactElement {
  const theme = useTheme() as SharedTheme;
  const color = props.color?.primary || theme.colors.icons.primary;
  return (
    <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <g clipPath='url(#clip0)'>
        <path
          d='M0.75 11.999C0.75 14.9827 1.93526 17.8442 4.04505 19.9539C6.15483 22.0637 9.01631 23.249 12 23.249C14.9837 23.249 17.8452 22.0637 19.955 19.9539C22.0647 17.8442 23.25 14.9827 23.25 11.999C23.25 9.01531 22.0647 6.15383 19.955 4.04404C17.8452 1.93426 14.9837 0.748993 12 0.748993C9.01631 0.748993 6.15483 1.93426 4.04505 4.04404C1.93526 6.15383 0.75 9.01531 0.75 11.999Z'
          stroke={color}
          strokeWidth='1.5'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M7 11.5L9.95 15.2C10.0429 15.3388 10.1677 15.4534 10.3138 15.5343C10.46 15.6152 10.6234 15.66 10.7904 15.665C10.9573 15.67 11.1231 15.635 11.2738 15.563C11.4246 15.491 11.5559 15.384 11.657 15.251L17 8'
          stroke={color}
          strokeWidth='1.5'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
      </g>
      <defs>
        <clipPath id='clip0'>
          <rect width='24' height='24' fill='white' />
        </clipPath>
      </defs>
    </svg>
  );
}
