import { Radio as SemanticRadio } from 'semantic-ui-react';
import styled from 'styled-components';

export const StyledRadio = styled(SemanticRadio)`
  &&&&& {
    label:before {
      top: -3px;
      width: 20px;
      height: 20px;
      border-color: ${(props) => props.theme.colors.input.border.default} !important;
    }
    label {
      font-size: 15px;
      margin-right: 20px;
      color: ${(props) => props.theme.colors.input.text};
    }
    label:after {
      left: 1px !important;
      top: -2px !important;
      width: 18px !important;
      height: 18px !important;
    }

    input:checked ~ label:before {
      background-color: ${(props) => props.theme.colors.base.primary} !important;
      border: none !important;
    }
    input:checked ~ label:after {
      background-color: ${(props) => props.theme.colors.white} !important;
    }
    input:not(:checked) ~ label:after {
      background-color: white;
    }

    input:hover + label:before {
      border-color: ${(props) => props.theme.colors.input.border.hover} !important;
    }

    input:active ~ label:before,
    input:focus ~ label:before {
      border-color: ${(props) => props.theme.colors.input.border.focus} !important;
      border-width: ${(props) => props.theme.metrics.borders.focus};
    }
  }
`;
