import React from 'react';
import { useTheme } from 'styled-components';

import { SVGIconProps } from 'Components/Icon/IconMap';

import { SharedTheme } from '../../Theme';

export function ThreeDots(props: SVGIconProps): React.ReactElement {
  const theme = useTheme() as SharedTheme;
  return (
    <svg
      fill={props.color?.primary || theme.colors.icons.primary}
      viewBox='0 0 16 4'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path d='M2.011 3.494a1.5 1.5 0 100-3 1.5 1.5 0 000 3zM8.017 3.523a1.5 1.5 0 100-3 1.5 1.5 0 000 3zM14.02 3.542a1.5 1.5 0 100-3 1.5 1.5 0 000 3z' />
    </svg>
  );
}
