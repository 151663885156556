import React from 'react';
import { useTheme } from 'styled-components';

import { SVGIconProps } from 'Components/Icon/IconMap';

import { SharedTheme } from '../../Theme';

export function LeftArrowIcon(props: SVGIconProps): React.ReactElement {
  const theme = useTheme() as SharedTheme;
  const color = props.color?.primary || theme.colors.icons.primary;
  return (
    <svg fill='none' stroke={color} viewBox='0 0 16 16' xmlns='http://www.w3.org/2000/svg'>
      <g strokeLinecap='round' strokeLinejoin='round'>
        <path d='m15.5 8h-15' />
        <path d='m7.5 1-7 7 7 7' />
      </g>
    </svg>
  );
}
