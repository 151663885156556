import { Language } from '@breathelife/types';

const localizedStrings: Record<Language, Record<string, any>> = {
  en: {
    cardNumber: 'Card Number',
    expiry: 'Expiry',
    cvc: 'CVC',
    postalCode: 'Postal Code',
  },
  fr: {
    cardNumber: 'Numéro de carte',
    expiry: "Date d'expiration",
    cvc: 'CVC',
    postalCode: 'Code Postal',
  },
};

export default localizedStrings;
