import React from 'react';
import { useTheme } from 'styled-components';

import { SVGIconProps } from 'Components/Icon/IconMap';

import { SharedTheme } from '../../Theme';

export function ProducerUmbrellaIcon(props: SVGIconProps): React.ReactElement {
  const theme = useTheme() as SharedTheme;
  const primaryColor = props.color?.primary || theme.colors.icons.primary;
  const secondaryColor = props.color?.secondary || theme.colors.icons.secondary;
  return (
    <svg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M10 11.875C10 11.0463 11.0072 10.375 12.25 10.375C13.4928 10.375 14.5 11.0463 14.5 11.875C14.555 11.4297 14.7819 11.0236 15.1322 10.7434C15.4825 10.4631 15.9285 10.3309 16.375 10.375C16.8215 10.3309 17.2675 10.4631 17.6178 10.7434C17.9681 11.0236 18.195 11.4297 18.25 11.875V11.5C18.25 6.73675 14.7633 2.875 10 2.875C5.23675 2.875 1.75 6.73675 1.75 11.5V11.875C1.80498 11.4297 2.03186 11.0236 2.38219 10.7434C2.73252 10.4631 3.17853 10.3309 3.625 10.375C4.07147 10.3309 4.51748 10.4631 4.86781 10.7434C5.21814 11.0236 5.44502 11.4297 5.5 11.875C5.5 11.0463 6.50725 10.375 7.75 10.375C8.99275 10.375 10 11.05 10 11.875Z'
        fill={secondaryColor}
      />
      <path
        d='M10 6.14875C11.5757 6.13407 13.1329 6.48827 14.5472 7.18305C15.9615 7.87783 17.1936 8.89389 18.145 10.15C17.919 8.14804 16.9636 6.29945 15.4612 4.95715C13.9588 3.61484 12.0147 2.87286 10 2.87286C7.98533 2.87286 6.04122 3.61484 4.53884 4.95715C3.03645 6.29945 2.08104 8.14804 1.85501 10.15C2.8064 8.89389 4.03851 7.87783 5.4528 7.18305C6.8671 6.48827 8.42434 6.13407 10 6.14875Z'
        fill='white'
      />
      <path
        d='M10 11.875C10 11.0462 11.0073 10.375 12.25 10.375C13.4927 10.375 14.5 11.0462 14.5 11.875C14.555 11.4297 14.7819 11.0236 15.1322 10.7434C15.4825 10.4631 15.9285 10.3309 16.375 10.375C16.8215 10.3309 17.2675 10.4631 17.6178 10.7434C17.9681 11.0236 18.195 11.4297 18.25 11.875V11.5C18.25 6.73675 14.7632 2.875 10 2.875C5.23675 2.875 1.75 6.73675 1.75 11.5V11.875C1.80498 11.4297 2.03186 11.0236 2.38219 10.7434C2.73252 10.4631 3.17853 10.3309 3.625 10.375C4.07147 10.3309 4.51748 10.4631 4.86781 10.7434C5.21814 11.0236 5.44502 11.4297 5.5 11.875C5.5 11.0462 6.50725 10.375 7.75 10.375C8.99275 10.375 10 11.05 10 11.875Z'
        stroke={primaryColor}
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path d='M10 2.875V1.375' stroke={primaryColor} strokeLinecap='round' strokeLinejoin='round' />
      <path d='M10 11.875V17.5' stroke={primaryColor} strokeLinecap='round' strokeLinejoin='round' />
      <path
        d='M10 17.5C10 17.7984 10.1185 18.0845 10.3295 18.2955C10.5405 18.5065 10.8266 18.625 11.125 18.625C11.4234 18.625 11.7095 18.5065 11.9205 18.2955C12.1315 18.0845 12.25 17.7984 12.25 17.5V17.125'
        stroke={primaryColor}
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
}
