import React from 'react';
import { useTheme } from 'styled-components';

import { SVGIconProps } from 'Components/Icon/IconMap';

import { SharedTheme } from '../../Theme';

export function PaymentIcon(props: SVGIconProps): React.ReactElement {
  const theme = useTheme() as SharedTheme;
  const primaryColor = props.color?.primary || theme.colors.icons.primary;
  const secondaryColor = props.color?.secondary || theme.colors.icons.secondary;
  return (
    <svg viewBox='0 0 48 48' fill='none' xmlns='http://www.w3.org/2000/svg' aria-hidden='true'>
      <circle cx='24' cy='24' r='19.5' fill='white' stroke='#F6F6F6' />
      <rect x='13.5714' y='8.80951' width='25.2381' height='5.71429' fill={theme.colors.icons.secondary} />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M12.8957 8.83219C13.4285 8.29935 14.1512 8 14.9047 8H37.254C38.0075 8 38.7302 8.29935 39.263 8.83219C39.7959 9.36503 40.0952 10.0877 40.0952 10.8413V26.0794C40.0952 26.8329 39.7959 27.5556 39.263 28.0884C38.7302 28.6213 38.0075 28.9206 37.254 28.9206H26.0793C25.6323 28.9206 25.2698 28.5582 25.2698 28.1111C25.2698 27.664 25.6323 27.3016 26.0793 27.3016H37.254C37.5781 27.3016 37.889 27.1728 38.1182 26.9436C38.3474 26.7144 38.4762 26.4035 38.4762 26.0794V10.8413C38.4762 10.5171 38.3474 10.2062 38.1182 9.97703C37.889 9.74782 37.5781 9.61905 37.254 9.61905H14.9047C14.5806 9.61905 14.2697 9.74782 14.0405 9.97703C13.8113 10.2062 13.6825 10.5171 13.6825 10.8413V24.0476C13.6825 24.4947 13.3201 24.8571 12.873 24.8571C12.4259 24.8571 12.0635 24.4947 12.0635 24.0476V10.8413C12.0635 10.0877 12.3628 9.36503 12.8957 8.83219Z'
        fill={primaryColor}
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M12.0635 14.9047C12.0635 14.4577 12.4259 14.0952 12.873 14.0952H39.2857C39.7328 14.0952 40.0952 14.4577 40.0952 14.9047C40.0952 15.3518 39.7328 15.7143 39.2857 15.7143H12.873C12.4259 15.7143 12.0635 15.3518 12.0635 14.9047Z'
        fill={primaryColor}
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M16.2222 21C16.2222 20.6055 16.542 20.2857 16.9365 20.2857H19.9841C20.3786 20.2857 20.6984 20.6055 20.6984 21C20.6984 21.3945 20.3786 21.7143 19.9841 21.7143H16.9365C16.542 21.7143 16.2222 21.3945 16.2222 21Z'
        fill={primaryColor}
      />
      <path
        d='M30.6507 19.9841C30.3308 19.5577 29.8849 19.2427 29.3762 19.0838C28.8674 18.9248 28.3215 18.93 27.8158 19.0986C27.3101 19.2672 26.8703 19.5905 26.5586 20.023C26.2469 20.4554 26.0792 20.9749 26.0792 21.5079C26.0792 22.041 26.2469 22.5605 26.5586 22.9929C26.8703 23.4253 27.3101 23.7487 27.8158 23.9173C28.3215 24.0859 28.8674 24.091 29.3762 23.9321C29.8849 23.7732 30.3308 23.4582 30.6507 23.0318C30.9705 23.4582 31.4164 23.7732 31.9251 23.9321C32.4339 24.091 32.9798 24.0859 33.4855 23.9173C33.9912 23.7487 34.431 23.4253 34.7427 22.9929C35.0544 22.5605 35.2221 22.041 35.2221 21.5079C35.2221 20.9749 35.0544 20.4554 34.7427 20.023C34.431 19.5905 33.9912 19.2672 33.4855 19.0986C32.9798 18.93 32.4339 18.9248 31.9251 19.0838C31.4164 19.2427 30.9705 19.5577 30.6507 19.9841Z'
        fill={secondaryColor}
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M27.59 18.421C28.2379 18.205 28.9373 18.1983 29.5892 18.402C29.9773 18.5232 30.3368 18.7153 30.6507 18.9662C30.9646 18.7153 31.3241 18.5232 31.7122 18.402C32.3641 18.1983 33.0635 18.205 33.7114 18.421C34.3594 18.6369 34.9229 19.0513 35.3222 19.6053C35.7215 20.1593 35.9364 20.825 35.9364 21.5079C35.9364 22.1909 35.7215 22.8565 35.3222 23.4106C34.9229 23.9646 34.3594 24.379 33.7114 24.5949C33.0635 24.8109 32.3641 24.8175 31.7122 24.6139C31.3241 24.4927 30.9646 24.3006 30.6507 24.0497C30.3368 24.3006 29.9773 24.4927 29.5892 24.6139C28.9373 24.8175 28.2379 24.8109 27.59 24.5949C26.9421 24.379 26.3785 23.9646 25.9792 23.4106C25.5799 22.8565 25.365 22.1909 25.365 21.5079C25.365 20.825 25.5799 20.1593 25.9792 19.6053C26.3785 19.0513 26.9421 18.6369 27.59 18.421ZM29.1632 19.7656C28.7975 19.6513 28.4052 19.6551 28.0417 19.7762C27.6783 19.8974 27.3621 20.1298 27.1381 20.4406C26.9141 20.7514 26.7936 21.1248 26.7936 21.5079C26.7936 21.8911 26.9141 22.2645 27.1381 22.5753C27.3621 22.8861 27.6783 23.1185 28.0417 23.2397C28.4052 23.3608 28.7975 23.3645 29.1632 23.2503C29.5289 23.1361 29.8494 22.9097 30.0793 22.6032C30.2142 22.4233 30.4259 22.3175 30.6507 22.3175C30.8755 22.3175 31.0872 22.4233 31.2221 22.6032C31.452 22.9097 31.7725 23.1361 32.1382 23.2503C32.5039 23.3645 32.8962 23.3608 33.2597 23.2397C33.6232 23.1185 33.9393 22.8861 34.1633 22.5753C34.3873 22.2645 34.5078 21.8911 34.5078 21.5079C34.5078 21.1248 34.3873 20.7514 34.1633 20.4406C33.9393 20.1298 33.6232 19.8974 33.2597 19.7762C32.8962 19.6551 32.5039 19.6513 32.1382 19.7656C31.7725 19.8798 31.452 20.1062 31.2221 20.4127C31.0872 20.5926 30.8755 20.6984 30.6507 20.6984C30.4259 20.6984 30.2142 20.5926 30.0793 20.4127C29.8494 20.1062 29.5289 19.8798 29.1632 19.7656Z'
        fill={primaryColor}
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M8.8095 26.3809C9.20399 26.3809 9.52379 26.7007 9.52379 27.0952V39.2857C9.52379 39.6802 9.20399 40 8.8095 40C8.41501 40 8.09521 39.6802 8.09521 39.2857V27.0952C8.09521 26.7007 8.41501 26.3809 8.8095 26.3809Z'
        fill={primaryColor}
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M8 29.127C8 28.6799 8.36244 28.3174 8.80952 28.3174H17.9524C19.2448 28.3174 20.4843 28.8308 21.3981 29.7447C22.1189 30.4655 22.5906 31.3888 22.7577 32.3809H27.0952C28.3876 32.3809 29.6271 32.8943 30.541 33.8082C31.4548 34.7221 31.9683 35.9615 31.9683 37.254C31.9683 37.4686 31.883 37.6746 31.7311 37.8264C31.5793 37.9782 31.3734 38.0635 31.1587 38.0635H8.80952C8.36244 38.0635 8 37.701 8 37.254C8 36.8069 8.36244 36.4444 8.80952 36.4444H30.2469C30.1032 35.8848 29.8116 35.3685 29.3961 34.953C28.7859 34.3428 27.9582 34 27.0952 34H22.0159C21.5688 34 21.2063 33.6375 21.2063 33.1905C21.2063 32.3275 20.8635 31.4998 20.2533 30.8896C19.643 30.2793 18.8154 29.9365 17.9524 29.9365H8.80952C8.36244 29.9365 8 29.5741 8 29.127Z'
        fill={primaryColor}
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M16.127 33.1904C16.127 32.7434 16.4894 32.3809 16.9365 32.3809H22.0158C22.4629 32.3809 22.8254 32.7434 22.8254 33.1904C22.8254 33.6375 22.4629 34 22.0158 34H16.9365C16.4894 34 16.127 33.6375 16.127 33.1904Z'
        fill={primaryColor}
      />
    </svg>
  );
}
