export enum FaviconTypes {
  icon = 'shortcut icon',
  appleTouchIcon = 'apple-touch-icon',
}

export type FaviconProps = {
  type: string;
  size: number;
  href: string;
};
