import React, { useState, useEffect } from 'react';
import { Icon as SemanticIcon } from 'semantic-ui-react';

import { Button, Checkbox, Content, Description, Img, Select, Title, Icon } from './Styles';

type CheckboxAltProps = {
  selected: string;
  unselected: string;
};

type CheckboxImageProps = {
  selected: string;
  unselected: string;
};

type Props = {
  id: string;
  title: string;
  checked: boolean;
  onChange: (value: boolean) => void;
  checkboxAlt: CheckboxAltProps;
  checkboxImage?: CheckboxImageProps;
  logo?: string;
  description?: string;
  className?: string;
  animate?: boolean;
};

export function Option(props: Props): React.ReactElement {
  const [checked, setChecked] = useState<boolean>(props.checked);

  useEffect(() => {
    setChecked(props.checked);
  }, [props.checked]);

  const toggleCheckbox = (e: React.MouseEvent) => {
    const checkedValue = !checked;
    setChecked(checkedValue);
    props.onChange(checkedValue);

    e.preventDefault();
  };

  const { id, title, description, checkboxAlt, checkboxImage } = props;
  const optionImage = props.logo ?? 'https://via.placeholder.com/22x22?text=BL';

  const selectedImage = checkboxImage ? (
    <Icon src={checkboxImage.selected} alt={checkboxAlt.selected} />
  ) : (
    <SemanticIcon name='check circle' alt={checkboxAlt.selected} />
  );
  const unselectedImage = checkboxImage ? (
    <Icon src={checkboxImage.unselected} alt={checkboxAlt.unselected} />
  ) : (
    <SemanticIcon name='plus circle' alt={checkboxAlt.unselected} />
  );

  const renderIcon = (checked: boolean) => {
    return checked ? selectedImage : unselectedImage;
  };

  return (
    <Button className={props.className} checked={checked} onClick={toggleCheckbox} animate={props.animate}>
      <Img src={optionImage} alt='' />

      <Content>
        <Title checked={checked} htmlFor={id}>
          {title}
        </Title>
        <Description>{description}</Description>
      </Content>

      <Select>
        <Checkbox id={id} checked={checked} tabIndex='-1' label={title} radio value={id} name='optionGroup' />
        {renderIcon(checked)}
      </Select>
    </Button>
  );
}
