import { TranslateOptions } from 'i18n-js';
import React from 'react';

import { BaseModal } from '../BaseModal';

type Props = {
  width: string;
  isMobile?: boolean;
  onClose: Function;
  text: (path: string, options?: TranslateOptions) => string;
  children: React.ReactNode;
  contextProps: {
    setPopup: (popup: React.ReactElement | null) => void;
  };
};

export function VideoModal(props: Props): React.ReactElement {
  return (
    <BaseModal
      isVideoModal
      hideHeader
      width={props.width}
      isMobile={props.isMobile || false}
      closeButtonTitle={props.text('modal.close')}
      onClose={() => {
        props.onClose();
        props.contextProps.setPopup(null);
      }}
    >
      {props.children}
    </BaseModal>
  );
}
