import { IconName } from '@breathelife/types';

import { FourOhFourIcon } from '../../Images/icons/404';
import { Advisor } from '../../Images/icons/Advisor';
import { AfterDeathIcon } from '../../Images/icons/AfterDeath';
import { AlcoholIcon } from '../../Images/icons/Alcohol';
import { Briefcase } from '../../Images/icons/Briefcase';
import { Bullet } from '../../Images/icons/Bullet';
import { BulletCheckmark } from '../../Images/icons/BulletCheckmark';
import { BulletEx } from '../../Images/icons/BulletEx';
import { CalculatorCoverageIcon } from '../../Images/icons/CalculatorCoverage';
import { CalculatorEducationIcon } from '../../Images/icons/CalculatorEducation';
import { CarIcon } from '../../Images/icons/Car';
import { Check } from '../../Images/icons/Check';
import { ChecklistIcon } from '../../Images/icons/Checklist';
import { Checkmark } from '../../Images/icons/Checkmark';
import { Close } from '../../Images/icons/Close';
import { Close2 } from '../../Images/icons/Close2';
import { ConfirmationIcon } from '../../Images/icons/Confirmation';
import { ConfirmationOutlinedIcon } from '../../Images/icons/ConfirmationOutlined';
import { ContactIcon } from '../../Images/icons/Contact';
import { CopyIcon } from '../../Images/icons/Copy';
import { CoverageIcon } from '../../Images/icons/Coverage';
import { Day } from '../../Images/icons/Day';
import { DeathIcon } from '../../Images/icons/Death';
import { DebtsSavingsIcon } from '../../Images/icons/DebtsSavings';
import { DragHandle } from '../../Images/icons/DragHandle';
import { DrugsIcon } from '../../Images/icons/Drugs';
import { Edit } from '../../Images/icons/Edit';
import { EducationIcon } from '../../Images/icons/Education';
import { Error } from '../../Images/icons/Error';
import { ErrorOutlined } from '../../Images/icons/ErrorOutlined';
import { FamilyIcon } from '../../Images/icons/Family';
import { FamilyIncomeIcon } from '../../Images/icons/FamilyIncome';
import { File } from '../../Images/icons/File';
import { FileDownloadIcon } from '../../Images/icons/FileDownload';
import { FileMenuIcon } from '../../Images/icons/FileMenu';
import { AverageFinancialHealth } from '../../Images/icons/FinancialHealthAverage';
import { ExcellentFinancialHealth } from '../../Images/icons/FinancialHealthExcellent';
import { GoodFinancialHealth } from '../../Images/icons/FinancialHealthGood';
import { PoorFinancialHealth } from '../../Images/icons/FinancialHealthPoor';
import { FolderIcon } from '../../Images/icons/Folder';
import { FutureIcon } from '../../Images/icons/Future';
import { HandShake } from '../../Images/icons/HandShake';
import { HealthIcon } from '../../Images/icons/Health';
import { HealthExcellent } from '../../Images/icons/HealthExcellent';
import { HealthGood } from '../../Images/icons/HealthGood';
import { HealthGreat } from '../../Images/icons/HealthGreat';
import { HealthPoor } from '../../Images/icons/HealthPoor';
import { HospitalIcon } from '../../Images/icons/Hospital';
import { Information } from '../../Images/icons/Information';
import { InformationOutlined } from '../../Images/icons/InformationOutlined';
import { LeftArrowIcon } from '../../Images/icons/LeftArrow';
import { ListIcon } from '../../Images/icons/List';
import { LoadingIcon } from '../../Images/icons/Loading';
import { LoadingCalculatorIcon } from '../../Images/icons/LoadingCalculator';
import { Mail } from '../../Images/icons/Mail';
import { MarijuanaIcon } from '../../Images/icons/Marijuana';
import { MibIcon } from '../../Images/icons/Mib';
import { Morning } from '../../Images/icons/Morning';
import { Night } from '../../Images/icons/Night';
import { PaymentIcon } from '../../Images/icons/Payment';
import { PersonalInformationIcon } from '../../Images/icons/PersonalInformation';
import { Phone } from '../../Images/icons/Phone';
import { PlaneIcon } from '../../Images/icons/Plane';
import { PoliceIcon } from '../../Images/icons/Police';
import { PreferenceIcon } from '../../Images/icons/Preference';
import { ProducerCogIcon } from '../../Images/icons/ProducerCog';
import { ProducerCogAndSearchIcon } from '../../Images/icons/ProducerCogAndSearch';
import { ProducerUmbrellaIcon } from '../../Images/icons/ProducerUmbrella';
import { ProducerWrenchIcon } from '../../Images/icons/ProducerWrench';
import { QuestionIcon } from '../../Images/icons/Question';
import { ScubaIcon } from '../../Images/icons/Scuba';
import { Search } from '../../Images/icons/Search';
import { SmokingIcon } from '../../Images/icons/Smoking';
import { Success } from '../../Images/icons/Success';
import { SuccessOutlined } from '../../Images/icons/SuccessOutlined';
import { SummaryIcon } from '../../Images/icons/Summary';
import { ThreeDots } from '../../Images/icons/ThreeDots';
import { Triangle } from '../../Images/icons/Triangle';
import { Unfortunately } from '../../Images/icons/Unfortunately';
import { Update } from '../../Images/icons/Update';
import { WaitIcon } from '../../Images/icons/Wait';
import { Warning } from '../../Images/icons/Warning';
import { WarningOutlined } from '../../Images/icons/WarningOutlined';
import { WatchIcon } from '../../Images/icons/Watch';
import { WeightIcon } from '../../Images/icons/Weight';
import { WorkIcon } from '../../Images/icons/Work';

export type SVGIconProps = {
  color?: {
    primary?: string;
    secondary?: string;
    tertiary?: string;
  };
};

export type IconMap = Record<IconName, React.ComponentType<SVGIconProps>>;

export const iconMap: IconMap = {
  error404: FourOhFourIcon,
  advisor: Advisor,
  alcohol: AlcoholIcon,
  averageFinancialHealth: AverageFinancialHealth,
  afterDeath: AfterDeathIcon,
  calculatorCoverage: CalculatorCoverageIcon,
  car: CarIcon,
  check: Check,
  checklist: ChecklistIcon,
  briefcase: Briefcase,
  bullet: Bullet,
  bulletCheckmark: BulletCheckmark,
  bulletEx: BulletEx,
  close: Close,
  confirmation: ConfirmationIcon,
  confirmationOutlined: ConfirmationOutlinedIcon,
  contact: ContactIcon,
  copy: CopyIcon,
  coverage: CoverageIcon,
  day: Day,
  death: DeathIcon,
  debtsSavings: DebtsSavingsIcon,
  drugs: DrugsIcon,
  edit: Edit,
  education: EducationIcon,
  educationCoverageCalculator: CalculatorEducationIcon,
  excellentHealth: HealthExcellent,
  excellentFinancialHealth: ExcellentFinancialHealth,
  extremeSports: ScubaIcon,
  family: FamilyIcon,
  familyIncome: FamilyIncomeIcon,
  file: File,
  fileDownload: FileDownloadIcon,
  future: FutureIcon,
  goodHealth: HealthGood,
  goodFinancialHealth: GoodFinancialHealth,
  greatHealth: HealthGreat,
  handshake: HandShake,
  health: HealthIcon,
  hospital: HospitalIcon,
  loading: LoadingIcon,
  loadingCalculator: LoadingCalculatorIcon,
  checkmark: Checkmark,
  marijuana: MarijuanaIcon,
  mib: MibIcon,
  morning: Morning,
  night: Night,
  payment: PaymentIcon,
  personalInformation: PersonalInformationIcon,
  mail: Mail,
  plane: PlaneIcon,
  police: PoliceIcon,
  poorHealth: HealthPoor,
  poorFinancialHealth: PoorFinancialHealth,
  preference: PreferenceIcon,
  smoking: SmokingIcon,
  summary: SummaryIcon,
  unfortunately: Unfortunately,
  update: Update,
  watch: WatchIcon,
  weight: WeightIcon,
  work: WorkIcon,
  wait: WaitIcon,
  phone: Phone,
  success: Success,
  successOutlined: SuccessOutlined,
  warning: Warning,
  warningOutlined: WarningOutlined,
  information: Information,
  informationOutlined: InformationOutlined,
  error: Error,
  errorOutlined: ErrorOutlined,
  close2: Close2,
  triangle: Triangle,
  dragHandle: DragHandle,
  folder: FolderIcon,
  fileMenu: FileMenuIcon,
  list: ListIcon,
  question: QuestionIcon,
  threeDots: ThreeDots,
  search: Search,
  producerCog: ProducerCogIcon,
  producerUmbrella: ProducerUmbrellaIcon,
  producerWrench: ProducerWrenchIcon,
  producerCogAndSearch: ProducerCogAndSearchIcon,
  leftArrow: LeftArrowIcon,
};
