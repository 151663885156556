import React from 'react';

export function ListIcon(): React.ReactElement {
  return (
    <svg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <rect x='3.5' y='6.5' width='9' height='3' rx='0.5' fill='white' stroke='#757575' strokeLinejoin='round' />
      <rect x='3.5' y='11.5' width='9' height='3' rx='0.5' fill='white' stroke='#757575' strokeLinejoin='round' />
      <rect x='3.5' y='1.5' width='9' height='3' rx='0.5' fill='white' stroke='#757575' strokeLinejoin='round' />
    </svg>
  );
}
