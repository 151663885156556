import React from 'react';
import { useTheme } from 'styled-components';

import { SVGIconProps } from 'Components/Icon/IconMap';

import { SharedTheme } from '../../Theme';

export function Bullet(props: SVGIconProps): React.ReactElement {
  const theme = useTheme() as SharedTheme;
  return (
    <svg width='7' height='6' viewBox='0 0 7 6' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M3.4043 5.3584C4.84375 5.3584 6.01465 4.1875 6.01465 2.74805C6.01465 1.30859 4.84375 0.137695 3.4043 0.137695C1.96484 0.137695 0.793945 1.30859 0.793945 2.74805C0.793945 4.1875 1.96484 5.3584 3.4043 5.3584Z'
        fill={props.color?.primary || theme.colors.icons.primary}
      />
    </svg>
  );
}
