import FormControl from '@material-ui/core/FormControl';
import MenuItem from '@material-ui/core/MenuItem';
import MuiSelect, { SelectProps } from '@material-ui/core/Select';
import { withStyles } from '@material-ui/core/styles';
import React, { ChangeEvent, useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { StyledFormControlLabel } from '../Fields/Styles/FieldStyles';

type Options<T extends string | number> = {
  value: T;
  label: string | number | React.ReactElement;
};

export type SelectMuiProps<T extends string | number = string> = {
  classes?: { [key: string]: unknown };
  id: string;
  label?: string;
  required?: boolean;
  value: T;
  onChange: (value: T, event: ChangeEvent<{ value: unknown }>) => void;
  options: Options<T>[];
  className?: string;
  ['data-testid']?: string;
  displayEmpty?: boolean;
  disabled?: boolean;
};

type DropdownProps = SelectProps & {
  ['data-testid']?: string;
};

// This is the only way to ensure the label is centered when reducing the height of the select
const styles = {
  shrink: {
    top: '0 !important',
  },
  root: {
    top: '-8px',
  },
};

// TODO: Move this component to the lead-platform since it is very specific to the lead-platform
// and only used in there at the moment.
function Select<T extends string | number>(props: SelectMuiProps<T>): React.ReactElement {
  const { onChange: onChangeProp } = props;
  const [labelWidth, setLabelWidth] = useState(0);
  const inputLabel = useRef<HTMLLabelElement>(null);

  useEffect(() => {
    setLabelWidth(inputLabel.current?.offsetWidth ?? 0);
  }, []);

  const onChange = useCallback(
    (value: T, event: ChangeEvent<{ value: unknown }>) => {
      onChangeProp(value, event);
    },
    [onChangeProp]
  );

  const labelId = useMemo(() => `${props.id}-label`, []);

  const menuOptions = props.options.map((option) => (
    <MenuItem key={option.value} value={option.value} data-testid={`${props['data-testid']}_${option.value}`}>
      {option.label}
    </MenuItem>
  ));

  const selectProps: DropdownProps = {
    margin: 'dense',
    labelWidth,
    ['data-testid']: props['data-testid'],
    id: props.id,
    labelId,
    value: props.value,
    onChange: (event: ChangeEvent<{ value: unknown }>) => onChange(event.target.value as T, event),
    MenuProps: {
      getContentAnchorEl: null,
      anchorOrigin: {
        vertical: 'bottom',
        horizontal: 'left',
      },
    },
    displayEmpty: props.displayEmpty,
    disabled: props.disabled,
  };

  const labelText = props.label ? `${props.label}${props.required ? ' *' : ''}` : null;
  return (
    <FormControl fullWidth variant='outlined' className={props.className}>
      <StyledFormControlLabel
        showError={false}
        control={<MuiSelect {...selectProps}>{menuOptions}</MuiSelect>}
        label={labelText}
        labelPlacement='top'
      />
    </FormControl>
  );
}

export const SelectMui = withStyles(styles)(Select) as <T extends string | number = string>(
  props: SelectMuiProps<T>
) => React.ReactElement;
