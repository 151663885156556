import Box from '@material-ui/core/Box';
import styled from 'styled-components';

import { Text } from '../../Components/Typography';

export const HeaderWrapper = styled.div`
  position: sticky;
  left: 0;
  top: 0;
  width: 100%;
  z-index: 1001;
`;

export const Container = styled.div`
  min-width: 100vw;
  display: flex;
  flex-direction: column;
  min-height: 100%;
`;

export const Content = styled(Box)`
  position: relative;
  display: flex;
  flex-direction: column;
  margin: 0 auto;

  @media (min-width: ${(props) => props.theme.breakpoints.tablet.min}) {
    max-width: 470px;
    flex-direction: row;
  }
`;

export const OffsetContent = styled(Box)`
  img {
    width: 48px;
    height: 48px;
  }

  @media (min-width: ${(props) => props.theme.breakpoints.tablet.min}) {
    position: absolute;
    left: calc(-55px);
    transform: translateX(-100%);
  }
`;

export const Subtitle = styled(Text)`
  & {
    color: ${(props) => props.theme.colors.grey[90]};
  }
`;
