import Box from '@material-ui/core/Box';
import React from 'react';

import { AdvisorInfoPanel } from '../../AdvisorInfoPanel/AdvisorInfoPanel';
import { OnSubmitScheduleDataType } from '../../ContactForm';
import { Title } from '../../Typography';
import { ContactAdvisorTabs } from './ContactAdvisorTabs';
import { BaseContactAdvisorModalProps } from './types';

type Props = BaseContactAdvisorModalProps & {
  onSubmitContactForm: (onSubmitContactArgs: OnSubmitScheduleDataType) => void;
};

export function ContactAdvisorModalMobile(props: Props): React.ReactElement {
  const { language, localizationStrings, contactPersonInfo, cloudinaryProps } = props;
  const hasAssignee = contactPersonInfo?.name || contactPersonInfo?.imgUrl;
  const hasContactPersonInfo = !hasAssignee;

  const textStrings = localizationStrings[language];
  return (
    <Box p={3}>
      <Box my={3}>
        {contactPersonInfo && hasAssignee && (
          <AdvisorInfoPanel advisorInfo={contactPersonInfo} cloudinaryProps={cloudinaryProps} />
        )}
      </Box>
      <Box mb={2}>
        <Title level={props.useMobileStylingOnDesktop ? 2 : 1}>{textStrings.title}</Title>
      </Box>
      <ContactAdvisorTabs {...props} hasContactPersonInfo={hasContactPersonInfo} />
    </Box>
  );
}
