import MoreVertIcon from '@material-ui/icons/MoreVert';
import React, { useState } from 'react';

import { Link, StyledHeaderButton, StyledMenu, StyledMenuItem } from './Styles';

export enum MenuItemTypes {
  link = 'link',
  button = 'button',
}

type MenuItemProps = {
  text: string;
  itemType: MenuItemTypes;
  href?: string;
  onClick?: () => void;
};

type Props = {
  items: MenuItemProps[];
};

function buildMenuItem(itemProps: MenuItemProps, key: number): React.ReactElement | null {
  switch (itemProps.itemType) {
    case MenuItemTypes.link:
      return (
        <Link key={key} href={itemProps.href} target='_blank' rel='noopener noreferrer'>
          <StyledMenuItem>{itemProps.text}</StyledMenuItem>
        </Link>
      );
    case MenuItemTypes.button:
      return (
        <StyledMenuItem key={key} onClick={itemProps.onClick}>
          {itemProps.text}
        </StyledMenuItem>
      );
    default:
      return null;
  }
}

export function HeaderMenu(props: Props): React.ReactElement {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [menuOpen, setMenuOpen] = useState(false);

  return (
    <>
      <StyledHeaderButton
        aria-haspopup='true'
        aria-label='Header Menu'
        onClick={(event: React.MouseEvent<HTMLElement>) => {
          setAnchorEl(event.currentTarget);
          setMenuOpen(true);
        }}
      >
        <MoreVertIcon />
      </StyledHeaderButton>
      <StyledMenu
        anchorEl={anchorEl}
        getContentAnchorEl={null}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        open={menuOpen}
        onClose={() => setMenuOpen(false)}
        PaperProps={{
          style: {
            boxShadow: 'none',
          },
        }}
      >
        {props.items.map(buildMenuItem)}
      </StyledMenu>
    </>
  );
}
