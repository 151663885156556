import Box from '@material-ui/core/Box';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormLabel from '@material-ui/core/FormLabel';
import MuiRadio from '@material-ui/core/Radio';
import MuiRadioGroup from '@material-ui/core/RadioGroup';
import TypographyMui from '@material-ui/core/Typography';
import ReactHtmlParser from 'html-react-parser';
import React from 'react';
import styled from 'styled-components';

type RadioGroupProps<T = string> = {
  name: string;
  label?: string;
  subtitle?: string;
  value?: T;
  onChange: (value: T) => void;
  children: React.ReactNode;
};

type RadioProps<T = string> = {
  label: string | React.ReactElement | null;
  value: T;
  disabled?: boolean;
  className?: string;
  hasError?: boolean;
};

const RadioLabel = styled.span<{ grey: number; fontSize: string; paddingTop?: string }>`
  &&& {
    color: ${(props) => props.theme.colors.grey[props.grey]};
    font-size: ${(props) => props.fontSize};
    font-weight: 400;
    padding-top: ${(props) => props.paddingTop ?? 0};
    display: block;
    line-height: 1.2;
  }
`;

const StyledFormControl = styled(FormControl)`
  width: 100%;
`;

const StyledFormControlLabel = styled(FormControlLabel)<{ $hasError?: boolean }>`
  background-color: ${(props) => props.theme.colors.grey[20]};
  border-radius: 4px;
  margin: unset !important;
  padding-right: 8px;
  padding-top: 4px;
  padding-bottom: 4px;
  width: 100%;
  border: ${(props) => props.$hasError && `1px solid ${props.theme.colors.error}`};
`;

const StyledRadio = styled(MuiRadio)`
  &&.Mui-checked {
    color: ${(props) => props.theme.colors.primary.default};
  }
`;

export function SimpleRadio<T extends string>(props: RadioProps<T>): React.ReactElement {
  const isLabelAnElement = React.isValidElement(props.label);

  return (
    <StyledFormControlLabel
      value={props.value}
      control={<StyledRadio color='primary' />}
      label={
        <TypographyMui className={props.className}>
          <RadioLabel className={props.className} grey={props.disabled ? 60 : 90} fontSize={'14px'}>
            {isLabelAnElement ? props.label : null}
            {!isLabelAnElement && props.label ? ReactHtmlParser(props.label as string) : null}
          </RadioLabel>
        </TypographyMui>
      }
      disabled={props.disabled}
      className={props.className}
      $hasError={!!props.hasError}
    />
  );
}

export function SimpleRadioGroup<T extends string>(props: RadioGroupProps<T>): React.ReactElement {
  return (
    // @ts-ignore seems like styled component is not getting the proper typings from material
    <StyledFormControl component='fieldset'>
      {props.label && (
        <Box mb={2}>
          <FormLabel component='legend'>
            <TypographyMui>
              <RadioLabel grey={90} fontSize={'15px'}>
                {ReactHtmlParser(props.label)}
              </RadioLabel>
            </TypographyMui>

            {props.subtitle && (
              <React.Fragment>
                <br />
                <RadioLabel grey={60} fontSize={'15px'}>
                  {ReactHtmlParser(props.subtitle)}
                </RadioLabel>
              </React.Fragment>
            )}
          </FormLabel>
        </Box>
      )}
      <MuiRadioGroup
        onChange={(event) => props.onChange(event.target.value as T)}
        // We want to have a controlled radio group, but we might have an undefined value at start
        // to force a user to select a value. Without this empty string, a warning will be displayed in the console
        // saying that we are trying to change an uncontrolled radio group to a controlled radio group.
        value={props.value || ''}
        aria-label={props.label}
        name={props.name}
      >
        {props.children}
      </MuiRadioGroup>
    </StyledFormControl>
  );
}
