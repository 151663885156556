import React from 'react';

import { CloudinaryProps } from 'Components/Images/Image';

import { ApplicationAssigneeImage, AssigneeContactButton, AssigneeContactPrompt, IconContainer } from './Styles';

const PhoneIcon = (): React.ReactElement => (
  <svg height='16' viewBox='0 0 16 16' width='16' xmlns='http://www.w3.org/2000/svg'>
    <path d='m3.90048 6.90452c1.64343 2.80057 2.73907 3.92088 5.47815 5.60118l2.19127-2.2405c1.119.2923 2.5317.6936 3.6655.6888.378-.003.7544.3834.7646.7525l-.0255 3.4953c-.0015.3866-.381.7746-.7661.7982-9.44163.017-14.899746-5.5638-15.20658359-15.236133-.03133851-.373731.34816659-.76176386.72610759-.76336386h3.744086c.4134-.01632824.78982.36854386.87954.60361586-.09585 1.310851.44777 2.909831.7402 4.060651z' />
  </svg>
);

type Props = {
  assigneeImageUrl?: string;
  onClick: () => void;
  buttonText: string;
  cloudinary: CloudinaryProps;
};

export function ScheduleCallButton(props: Props): React.ReactElement {
  return (
    <AssigneeContactButton onClick={props.onClick}>
      {props.assigneeImageUrl && (
        <ApplicationAssigneeImage src={props.assigneeImageUrl} width={28} height={28} cloudinary={props.cloudinary} />
      )}
      <IconContainer>
        <PhoneIcon />
      </IconContainer>
      <AssigneeContactPrompt>{props.buttonText}</AssigneeContactPrompt>
    </AssigneeContactButton>
  );
}
