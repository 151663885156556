import { StandardTextFieldProps } from '@material-ui/core/TextField';
import _ from 'lodash';
import React from 'react';
import InputMask from 'react-input-mask';

import { Language } from '@breathelife/types';

import { FieldProps, getFieldLabel, InputVariant } from './Helpers/FieldHelper';
import { Input } from './TextInput';

export type DateMaskMap = Record<Language, string>;

export type BaseMaskedDateFieldProps = Omit<FieldProps, 'onAnswerChange'> & {
  value?: string;
  onAnswerChange?: (answer: string) => void;
  onAnswerComplete?: (fieldId: string, answer: string | number, previousAnswer?: string | number) => void;
  inputVariant?: InputVariant;
  locale?: Language;
  required?: boolean;
  placeholder?: string;
};

type Props = StandardTextFieldProps &
  BaseMaskedDateFieldProps & {
    localeDateMaskMap: DateMaskMap;
    localeDatePlaceholderMap: DateMaskMap;
  };

type State = {
  value?: string;
  valueOnPreviousBlur?: string;
};

export class MaskedDateInputField extends React.Component<Props, State> {
  state = {
    value: this.props.value,
    valueOnPreviousBlur: this.props.value,
  };

  debouncedParentOnAnswerChange: Function = this.props.onAnswerChange
    ? _.debounce(this.props.onAnswerChange, 200)
    : () => null;

  onAnswerChange = (answer: string): void => {
    this.setState({ value: answer }, () => {
      this.debouncedParentOnAnswerChange(this.state.value);
    });
  };

  onBlur = (): void => {
    if (this.props.onAnswerChange && typeof this.state.value !== 'undefined') {
      this.props.onAnswerChange(this.state.value);
    }
    if (this.props.onAnswerComplete && typeof this.state.value !== 'undefined') {
      this.props.onAnswerComplete(this.props.name, this.state.value, this.state.valueOnPreviousBlur);
    }
    this.setState({ valueOnPreviousBlur: this.state.value });
  };

  render(): React.ReactElement {
    const { locale: localeProps, label, title, required, subtitle } = this.props;
    const locale = localeProps ?? Language.en;
    const labelValue = getFieldLabel({ label, title });
    const requiredSuffix = required ? ' *' : '';
    const labelText = labelValue ? `${labelValue}${requiredSuffix}` : null;

    return (
      <InputMask
        mask={this.props.localeDateMaskMap[locale]}
        value={this.state.value}
        onBlur={this.onBlur}
        disabled={this.props.disabled}
        onChange={(event: React.ChangeEvent<HTMLInputElement>) => this.onAnswerChange(event.target.value)}
      >
        {() => {
          return (
            <Input
              data-testid={this.props['data-testid']}
              name={this.props.name}
              error={!!this.props.validationError}
              label={labelText}
              subtitle={labelText ? subtitle : `${subtitle}${requiredSuffix}`}
              placeholder={this.props.localeDatePlaceholderMap[locale]}
              validationError={this.props.validationError}
              inputVariant={this.props.inputVariant}
              disabled={this.props.disabled}
            />
          );
        }}
      </InputMask>
    );
  }
}
