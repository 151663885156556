import { Form as BaseForm } from 'semantic-ui-react';
import styled from 'styled-components';

export const Form = styled(BaseForm)`
  &&& {
    max-width: 400px;
    margin: 0 auto;

    @media (min-width: ${(props) => props.theme.breakpoints.tablet.min}) {
      max-width: unset;
      margin: unset;
    }
  }
`;

export const TitleAndDescriptionContainer = styled.div`
  text-align: center;

  @media (min-width: ${(props) => props.theme.breakpoints.tablet.min}) {
    text-align: left;
  }
`;

export const Title = styled.h1`
  font-size: ${(props) => props.theme.fonts.size.title.mobile};
  font-weight: ${(props) => props.theme.fonts.weight.title};

  @media (min-width: ${(props) => props.theme.breakpoints.tablet.min}) {
    font-size: ${(props) => props.theme.fonts.size.title.desktop};
  }
`;

export const Description = styled.p`
  font-size: ${(props) => props.theme.fonts.size.description.mobile};
  font-weight: ${(props) => props.theme.fonts.weight.regular};
  color: ${(props) => props.theme.colors.grey.darker};

  @media (min-width: ${(props) => props.theme.breakpoints.tablet.min}) {
    font-size: ${(props) => props.theme.fonts.size.description.desktop};
  }
`;

export const Spacer = styled.div`
  margin-top: 24px;
`;

export const ActionsContainer = styled(Spacer)`
  text-align: center;

  @media (min-width: ${(props) => props.theme.breakpoints.tablet.min}) {
    text-align: left;
  }
`;
