import React from 'react';
import styled from 'styled-components';

type Props = {
  label: string;
  value: string;
  ['data-testid']?: string;
};

const Label = styled.label`
  font-size: 14px;
  line-height: 17px;
  letter-spacing: -0.42px;
  color: #999999;
`;

const Answer = styled.p`
  font-size: 16px;
  line-height: 19px;
  letter-spacing: -0.42px;
  color: #333333;
  margin-top: 5px;
`;

export function TextAnswer(props: Props): React.ReactElement {
  return (
    <React.Fragment>
      <Label>{props.label}</Label>
      <Answer data-testid={props['data-testid']}>{props.value}</Answer>
    </React.Fragment>
  );
}
